import ReduxActions from '../../constants/redux-actions';
import { ErrorCodes } from '../../models/error-code';
import { BaseAction } from '../model/action';

const defaultState = {
  isAuthenticated: undefined,
  error: ErrorCodes.none,
  loginUrl: null,
  hubConnection: null,
};

const authenticate = (state = defaultState, action: BaseAction) => {
  switch (action.type) {
    case ReduxActions.AUTHENTICATE:
      return {
        ...state,
        error: action.data.error,
        isAuthenticated: action.data.isAuthenticated,
      };
    case ReduxActions.SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.data,
      };
    case ReduxActions.SET_AUTH_URL:
      return {
        ...state,
        loginUrl: action.data,
      };
    case ReduxActions.SET_HUB_CONNECTION:
      return {
        ...state,
        hubConnection: action.data,
      };
    default:
      return state;
  }
};

export default authenticate;
