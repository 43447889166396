// make sure that this enum has the same values and order as at the backend side
enum MsTeamsActivityType {
  Available,
  Away,
  BeRightBack,
  Busy,
  DoNotDisturb,
  InACall,
  InAConferenceCall,
  Inactive,
  InAMeeting,
  Offline,
  OffWork,
  OutOfOffice,
  PresenceUnknown, //==Status unknown
  Presenting,
  UrgentInterruptionsOnly,
}

export default MsTeamsActivityType;
