import axios from 'axios';
import { endpoints } from '../config/endpoints';
import ResponseModel from '../models/response-model';
import { CommunicationLog } from '../models/communication-log';

function getVoicemails() {
  const url = `${endpoints.backEndBase}/voicemail`;
  return axios.get<ResponseModel<CommunicationLog>>(url);
}

function deleteVoicemails(ids: number[]) {
  const url = `${endpoints.backEndBase}/voicemail`;
  return axios.delete<ResponseModel<boolean>>(url, { data: ids });
}

function updateVoicemailSeenPlayed(body: any) {
  const url = `${endpoints.backEndBase}/voicemail`;
  return axios.put<ResponseModel<boolean>>(url, body);
}

export { getVoicemails, deleteVoicemails, updateVoicemailSeenPlayed };
