import featureFlags from '../../components/org-page/feature-flags';
import ReduxActions from '../../constants/redux-actions';
import { BaseAction } from '../model/action';

const defaultState = {
  hasMsGraphAccess: null,
  hasSearchContactsAccess: null,
  isGlobalAdmin: null,
  isAdmin: null,
  hasSmsWidgetAccess: null,
  hasCallTransferWidgetAccess: null,
  hasCallRecordingWidgetAccess: null,
  addPermissionsUrl: null,
  addSearchPermissionsUrl: null,
  loggedInAs: null,
  presenceActivities: null,
  userPresenceMappings: null,
  userPresenceDefaultMappings: null,
  voicemailSettings: null,
  testUsers: [],
  isPermissionsLoaded: false,
  smsMaxLength: 500,
  featureFlags: null,
};

const settingsData = (state = defaultState, action: BaseAction) => {
  switch (action.type) {
    case ReduxActions.SET_PERMISSIONS: {
      return {
        ...state,
        // Probably later create one property for all permissions
        hasMsGraphAccess: action.data.appPermissions.hasMsGraphAccess,
        hasSearchContactsAccess: action.data.appPermissions.hasSearchContactsAccess,
        loggedInAs: action.data.appPermissions.loggedInAs,
        hasSmsWidgetAccess: action.data.appPermissions.hasSmsWidgetAccess,
        hasCallTransferWidgetAccess: action.data.appPermissions.hasCallTransferWidgetAccess,
        hasCallRecordingWidgetAccess: action.data.appPermissions.hasCallRecordingWidgetAccess,
        isAdmin: action.data.appPermissions.isAdmin,
        isPermissionsLoaded: true,
        smsMaxLength: action.data.appPermissions.smsMaxLength,
      };
    }
    case ReduxActions.SET_TEST_USERS: {
      return {
        ...state,
        // Probably later create one property for all permissions
        testUsers: action.data.testUsers,
      };
    }
    case ReduxActions.SET_PRESENCE_ACTIVITIES: {
      return {
        ...state,
        presenceActivities: action.data.presenceActivities,
      };
    }
    case ReduxActions.SET_USER_PRESENCE_MAPPINGS: {
      return {
        ...state,
        userPresenceMappings: action.data.userPresenceMappings,
      };
    }
    case ReduxActions.SET_USER_PRESENCE_DEFAULT_MAPPINGS: {
      return {
        ...state,
        userPresenceDefaultMappings: action.data.userPresenceDefaultMappings,
      };
    }
    case ReduxActions.SET_ADMIN_ROLE: {
      return {
        ...state,
        isGlobalAdmin: action.data.isAdmin,
      };
    }
    case ReduxActions.SET_GRAPH_URLS: {
      return {
        ...state,
        addPermissionsUrl: action.data.adminUrl,
        addSearchPermissionsUrl: action.data.userUrl,
      };
    }
    case ReduxActions.SET_VOICEMAIL_SETTINGS: {
      return {
        ...state,
        voicemailSettings: action.data.voicemailSettings,
      };
    }
    case ReduxActions.SET_FEATURE_FLAGS: {
      return {
        ...state,
        featureFlags: action.data.featureFlags,
      };
    }
    default:
      return state;
  }
};

export default settingsData;
