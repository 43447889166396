import axios from 'axios';
import { endpoints } from '../config/endpoints';
import FeatureFlags from '../models/feature-flags';

function getFeatureFlags() {
  const url = `${endpoints.backEndBase}/admin/featureFlags`;
  return axios.get<FeatureFlags>(url);
}

function setFeatureFlags(body: FeatureFlags) {
  const url = `${endpoints.backEndBase}/admin/featureFlags`;
  return axios.put<boolean>(url, body);
}

export {
  getFeatureFlags,
  setFeatureFlags,
};
