import React from 'react';
import {
  Button,
  Text,
  Dropdown,
  Option,
  Subtitle2Stronger,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  Body1Strong,
} from '@fluentui/react-components';
import type { OptionOnSelectData, DropdownProps } from '@fluentui/react-components';
import { Save24Regular, ArrowReset24Filled } from '@fluentui/react-icons';
import * as microsoftTeams from '@microsoft/teams-js';

import * as settingsDataActions from '../../store/actions/settings-data-action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isLoadingSelector, msTeamsContextSelector } from '../../store/selectors/common-selectors';
import {
  featureFlagsSelector,
  presenceActivitiesSelector,
  userPresenceMappingsSelector,
  userPresenceDefaultMappingsSelector,
} from '../../store/selectors/settings-data-selectors';
import FeatureFlags from '../../models/feature-flags';
import PresenceActivity from './../../models/presence-activity';
import UserPresenceMapping from '../../models/user-presence-mapping';
import MsTeamsActivityType from '../../models/ms-teams-activity-type.enum';
import { settings } from '../../i18n/all';
import { cloneDeep, sortBy } from 'lodash';
import { isMobile } from 'react-device-detect';

interface PresenceActivityMappingProps {
  featureFlags: FeatureFlags;
  presenceActivities: PresenceActivity[];
  userPresenceMappings: UserPresenceMapping[];
  userPresenceDefaultMappings: UserPresenceMapping[];
  msTeamsContext: microsoftTeams.app.Context | null;
  isLoading: boolean;
  actions: any;
}

interface PresenceActivityMappingState {
  mappings: { [type: string]: PresenceActivity };
}

export class PresenceActivityMapping extends React.Component<
  PresenceActivityMappingProps,
  PresenceActivityMappingState,
  DropdownProps
> {
  mainTypes = [
    MsTeamsActivityType.Available,
    MsTeamsActivityType.Busy,
    MsTeamsActivityType.DoNotDisturb,
    MsTeamsActivityType.BeRightBack,
    MsTeamsActivityType.Away,
    // When user select "Appear offline" Microsoft Graph sends "Offline" value as activity
    MsTeamsActivityType.OffWork,
  ];
  constructor(props: PresenceActivityMappingProps) {
    super(props);
    this.props.actions.loadFeatureFlags();
    this.props.actions.loadPresenceActivitiesMapping();
    this.props.actions.loadPresenceActivitiesDefaultMapping(this.props.msTeamsContext?.user?.id);
    this.state = { mappings: {} };
    this.onSaveData.bind(this);
  }

  onSaveData(mappings: UserPresenceMapping[]) {
    const items = cloneDeep(mappings);
    items.forEach((item) => {
      const newValue = this.state.mappings[item.teamsPresenceActivity.toString()];
      if (newValue) {
        item.telepoPresenceActivityId = newValue.id;
        item.telepoPresenceAvailable = newValue.available;
      }
    });
    this.setState({ mappings: {} });
    this.props.actions.savePresenceActivitiesMapping(items);
  }

  onResetToDefault() {
    this.props.actions.resetUserPresenceMappingsToDefault();
  }

  renderItem(m: UserPresenceMapping, allItems: PresenceActivity[], userPresenceMappingsEnabled: boolean) {
    // Find the corresponding Org level mapping
    const defaultMapping = this.props.userPresenceDefaultMappings?.find(
      (mapping) => mapping.teamsPresenceActivity === m.teamsPresenceActivity
    );
    const defaultPresence = this.props.presenceActivities.find(
      (p) => p.id === defaultMapping?.telepoPresenceActivityId
    );
    let presenceItems = [...allItems];

    // Add the "no choice" option, showing Org level setting if set
    presenceItems.unshift(
      new PresenceActivity('', false, defaultPresence ? defaultPresence.name + ' (' + settings.default + ')' : ' ', '') // no user value
    );

    let itemsToRender = presenceItems.map((a) => ({
      key: a.id,
      header: a.name || '',
      item: m.teamsPresenceActivity.toString(),
    }));

    const handleSelection = !userPresenceMappingsEnabled ? undefined : (e: any, data: OptionOnSelectData) => {
      const selected = presenceItems.find((pa) => pa.id === data.optionValue?.split('/')[0]);
      if (selected) {
        let mappings = this.state.mappings;
        mappings[data.optionValue?.split('/')[1]!] = selected;
      }
    };

    return (
      <TableRow key={m.teamsPresenceActivity} style={{ border: 'none' }}>
        <TableCell colSpan={3}>{settings.getString(MsTeamsActivityType[m.teamsPresenceActivity])}</TableCell>
        <TableCell colSpan={3}>
          {!this.props.isLoading && (
            <Dropdown
              disabled={!userPresenceMappingsEnabled}
              style={{ width: '100%' }}
              onOptionSelect={handleSelection}
              defaultValue={!userPresenceMappingsEnabled ? presenceItems[0].name : presenceItems.find((pa) => pa.id === m.telepoPresenceActivityId)?.name}
              data-testid={'dropdown-' + m.teamsPresenceActivity}
            >
              {itemsToRender.map((option) => (
                <Option
                  key={option.item}
                  value={option.key + '/' + option.item}
                  text={option.header}
                  data-testid={'dropdown-' + option.item + '-' + option.header}
                >
                  {option.header}
                </Option>
              ))}
            </Dropdown>
          )}
        </TableCell>
        <TableCell colSpan={6}></TableCell>
      </TableRow>
    );
  }

  render() {
    if (!this.props.presenceActivities) {
      return '';
    }

    const allItems = [...this.props.presenceActivities];
    allItems.sort((a, b) => (a.name || '').localeCompare(b.name || ''));

    return (
      !!this.props.userPresenceMappings?.length && (
        <>
          <Table size="extra-small">
            <TableHeader>
              <TableRow style={{ border: 'none' }}>
                <TableHeaderCell colSpan={12}>
                  <Subtitle2Stronger>{settings.mappingSettings}</Subtitle2Stronger>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
          </Table>
          <Table size="extra-small">
            <TableHeader>
              <TableRow style={{ border: 'none' }}>
                <TableHeaderCell colSpan={12}>
                  {this.props.featureFlags.userPresenceMappingsEnabled && (
                    <Text style={{ fontStyle: 'italic' }}>{settings.userPresenceMappingsEnabled}</Text>
                  )}
                  {!this.props.featureFlags.userPresenceMappingsEnabled && (
                    <Text style={{ fontStyle: 'italic' }}>{settings.userPresenceMappingsDisabled}</Text>
                  )}
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
          </Table>
          <Table size="medium">
            <TableHeader>
              <TableRow style={{ border: 'none' }}>
                <TableHeaderCell colSpan={3}>
                  <Body1Strong>{settings.msTeamsManualPresence}</Body1Strong>
                </TableHeaderCell>
                <TableHeaderCell colSpan={9}>
                  <Body1Strong>{settings.pbxPresence}</Body1Strong>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortBy(
                this.props.userPresenceMappings.filter(
                  (upm) => this.mainTypes.findIndex((mt) => upm.teamsPresenceActivity === mt) !== -1
                ),
                (item) => this.mainTypes.indexOf(item.teamsPresenceActivity)
              ).map((m) => this.renderItem(m, allItems, this.props.featureFlags.userPresenceMappingsEnabled))}
            </TableBody>
          </Table>
          <Table size="medium">
            <TableHeader>
              <TableRow style={{ border: 'none' }}>
                <TableHeaderCell colSpan={12}>
                  <Body1Strong>{settings.msTeamsAutoPresence}</Body1Strong>
                </TableHeaderCell>
              </TableRow>
              <TableRow style={{ border: 'none' }}>
                <TableHeaderCell colSpan={12}>
                  <Text style={{ fontStyle: 'italic' }}>{settings.msTeamsAutoPresenceHint}</Text>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {this.props.userPresenceMappings
                .filter((upm) => this.mainTypes.findIndex((mt) => upm.teamsPresenceActivity === mt) === -1)
                .map((m) => this.renderItem(m, allItems, this.props.featureFlags.userPresenceMappingsEnabled))}
            </TableBody>
          </Table>
          {this.props.featureFlags.userPresenceMappingsEnabled && (
            <Table size="extra-small">
              <TableHeader>
                <TableRow style={{ border: 'none' }}>
                  <TableHeaderCell colSpan={isMobile ? 1 : 3}></TableHeaderCell>
                  <TableHeaderCell colSpan={isMobile ? 11 : 9}>
                    <Button
                      id="save"
                      size="medium"
                      icon={<Save24Regular />}
                      content={settings.save}
                      iconPosition="before"
                      appearance="primary"
                      onClick={() => this.onSaveData(this.props.userPresenceMappings)}
                    >
                      {settings.save}
                    </Button>
                    <Button
                      style={{ marginLeft: '10px' }}
                      id="reset"
                      size="medium"
                      icon={<ArrowReset24Filled />}
                      content={settings.resetToDefault}
                      iconPosition="before"
                      appearance="outline"
                      onClick={() => this.onResetToDefault()}
                    >
                      {settings.resetToDefault}
                    </Button>
                  </TableHeaderCell>
                </TableRow>
              </TableHeader>
            </Table>
          )}
        </>
      )
    );
  }
}

const mapStateToProps = (state: any) => ({
  featureFlags: featureFlagsSelector(state),
  presenceActivities: presenceActivitiesSelector(state),
  userPresenceMappings: userPresenceMappingsSelector(state),
  userPresenceDefaultMappings: userPresenceDefaultMappingsSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PresenceActivityMapping);
