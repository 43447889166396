// IMPORTANT FOR DEVS!!
// Duplicate translations also in en.json to add them in weblate.soluno.se
import LocalizedStrings from 'react-localization';

const loginForm = new LocalizedStrings({
  en: {
    "enterYourEmail": "Enter your email:",
    "enterYourPassword": "Enter your password:",
    "NONE": "Undefined error",
    "UNKNOWN": "Unknown error",
    "INVALID_CREDENTIALS": "Invalid credentials",
    "CREDS_ALREADY_IN_USE": "Credentials are already in use. Seems like currently you are logged in to your Telepo account under other Microsoft profile. Please log out there first.",
    "USER_NOT_FOUND": "User not found",
    "DATA_NOT_FOUND": "Data not found",
    "UPDATE_FAIL": "Failed to update",
    "DELETE_FAIL": "Failed to delete",
    "INVALID_DATA": "Invalid data",
    "MS_TEAMS_DATA_NOT_FOUND": "We couldn't find your Teams data. Please, reinstall application",
    "FAILED_TO_AUTHENTICATE": "Failed to authenticate",
    "CancelledByUser": "Login window was closed too early by user. You should not close popup window manually",
    "FailedToOpenWindow": "Opening popup windows are blocked. Please check your website settings",
    "ERR_NETWORK": "The connection has not been established, please try again later",
    "ERR_BAD_REQUEST_400": "Something went wrong, please try again later (Error code: 400)",
    "ERR_BAD_REQUEST_401": "Something went wrong, please try again later (Error code: 401)",
    "ERR_BAD_REQUEST_404": "Something went wrong, please try again later (Error code: 404)",
    "ERR_BAD_REQUEST_500": "Something went wrong, please try again later (Error code: 500)",
  },
  sv: {
    "enterYourEmail": "Ange e-postadress:",
    "enterYourPassword": "Ange lösenord:",
    "NONE": "Odefinierat fel",
    "UNKNOWN": "Okänt fel",
    "INVALID_CREDENTIALS": "Ogiltiga uppgifter",
    "CREDS_ALREADY_IN_USE": "Uppgifterna används redan. [Seems like currently you are logged in to your Telepo account under other Microsoft profile. Please log out there first.]",
    "USER_NOT_FOUND": "Användaren hittades inte",
    "DATA_NOT_FOUND": "Data hittades inte",
    "UPDATE_FAIL": "Det gick inte att uppdatera",
    "DELETE_FAIL": "Det gick inte att ta bort",
    "INVALID_DATA": "Ogiltiga data",
    "MS_TEAMS_DATA_NOT_FOUND": "Vi kunde inte hitta dina Teamsdata. Var vänlig installera om programmet",
    "FAILED_TO_AUTHENTICATE": "Det gick inte att autentisera",
    "NO_LOGIN_URL": "Det finns ingen inloggningsadress",
    "CancelledByUser": "Inloggningsfönstret stängdes för tidigt av användaren. Du bör inte stänga popup-fönstret manuellt",
    "FailedToOpenWindow": "Öppna popup-fönster är blockerade. Kontrollera dina webbplatsinställningar",
    "ERR_NETWORK": "Anslutningen har ej etablerats, var god försök senare",
    "ERR_BAD_REQUEST_400": "Något gick fel, var god försök senare (Felkod: 400)",
    "ERR_BAD_REQUEST_401": "Något gick fel, var god försök senare (Felkod: 401)",
    "ERR_BAD_REQUEST_404": "Något gick fel, var god försök senare (Felkod: 404)",
    "ERR_BAD_REQUEST_500": "Något gick fel, var god försök senare (Felkod: 500)",
  },
  nl: {
    "enterYourEmail": "Voer uw e-mailadres in:",
    "enterYourPassword": "Voer uw wachtwoord in:",
    "NONE": "Ongedefinieerde fout",
    "UNKNOWN": "Onbekende fout",
    "INVALID_CREDENTIALS": "Ongeldige inloggegevens",
    "CREDS_ALREADY_IN_USE": "Inloggegevens zijn al in gebruik. [Seems like currently you are logged in to your Telepo account under other Microsoft profile. Please log out there first.]",
    "USER_NOT_FOUND": "Gebruiker niet gevonden",
    "DATA_NOT_FOUND": "Data niet gevonden",
    "UPDATE_FAIL": "Kan niet worden bijgewerkt",
    "DELETE_FAIL": "Kon niet worden verwijderd",
    "INVALID_DATA": "Onjuiste data",
    "MS_TEAMS_DATA_NOT_FOUND": "We kunnen uw Teams-gegevens niet vinden. Installeer de applicatie opnieuw",
    "FAILED_TO_AUTHENTICATE": "Authenticatie mislukt",
    "NO_LOGIN_URL": "Er is geen inlog-url",
    "CancelledByUser": "Het inlogvenster is te vroeg gesloten door de gebruiker. U mag het pop-upvenster niet handmatig sluiten",
    "FailedToOpenWindow": "Het openen van pop-upvensters wordt geblokkeerd. Controleer uw website-instellingen",
    "ERR_NETWORK": "De verbinding is niet tot stand gebracht, probeer het later opnieuw",
    "ERR_BAD_REQUEST_400": "Er is iets fout gegaan, probeer het later opnieuw (foutcode: 400)",
    "ERR_BAD_REQUEST_401": "Er is iets fout gegaan, probeer het later opnieuw (foutcode: 401)",
    "ERR_BAD_REQUEST_404": "Er is iets fout gegaan, probeer het later opnieuw (foutcode: 404)",
    "ERR_BAD_REQUEST_500": "Er is iets fout gegaan, probeer het later opnieuw (foutcode: 500)",
  },
  de: {
    "enterYourEmail": "Geben sie ihre E-Mail Adresse ein:",
    "enterYourPassword": "Geben Sie Ihr Passwort ein:",
    "NONE": "Undefinierter Fehler",
    "UNKNOWN": "Unbekannter Fehler",
    "INVALID_CREDENTIALS": "Ungültige Anmeldeinformationen",
    "CREDS_ALREADY_IN_USE": "Anmeldeinformationen werden bereits verwendet. [Seems like currently you are logged in to your Telepo account under other Microsoft profile. Please log out there first.]",
    "USER_NOT_FOUND": "Benutzer nicht gefunden",
    "DATA_NOT_FOUND": "Daten nicht gefunden",
    "UPDATE_FAIL": "Aktualisierung fehlgeschlagen",
    "DELETE_FAIL": "Fehler beim Löschen",
    "INVALID_DATA": "Ungültige Daten",
    "MS_TEAMS_DATA_NOT_FOUND": "Wir konnten Ihre Teamdaten nicht finden. Bitte installieren Sie die Anwendung neu",
    "FAILED_TO_AUTHENTICATE": "Authentifizierung fehlgeschlagen",
    "NO_LOGIN_URL": "Es gibt keine Anmelde-URL",
    "CancelledByUser": "Anmeldefenster wurde vom Benutzer zu früh geschlossen. Sie sollten das Popup-Fenster nicht manuell schließen",
    "FailedToOpenWindow": "Das Öffnen von Popup-Fenstern wird blockiert. Bitte überprüfen Sie Ihre Website-Einstellungen",
    "ERR_NETWORK": "Die Verbindung wurde nicht hergestellt, bitte versuchen Sie es später noch einmal",
    "ERR_BAD_REQUEST_400": "Etwas lief schief, bitte versuchen Sie es später noch einmal (Fehlercode: 400)",
    "ERR_BAD_REQUEST_401": "Etwas lief schief, bitte versuchen Sie es später noch einmal (Fehlercode: 401)",
    "ERR_BAD_REQUEST_404": "Etwas lief schief, bitte versuchen Sie es später noch einmal (Fehlercode: 404)",
    "ERR_BAD_REQUEST_500": "Etwas lief schief, bitte versuchen Sie es später noch einmal (Fehlercode: 500)",
  },
  fr: {
    "enterYourEmail": "Entrez votre nom d'utilisateur :",
    "enterYourPassword": "Entrez votre mot de passe :",
    "NONE": "Erreur indéfinie",
    "UNKNOWN": "Erreur inconnue",
    "INVALID_CREDENTIALS": "Informations d'identification non valides",
    "CREDS_ALREADY_IN_USE": "Les informations d'identification sont déjà utilisées. Vous êtes probablement connecté à votre compte sous un autre profil Microsoft. Veuillez d'abord vous déconnecter de là.",
    "USER_NOT_FOUND": "Utilisateur introuvable",
    "DATA_NOT_FOUND": "Données introuvables",
    "UPDATE_FAIL": "Échec de la mise à jour",
    "DELETE_FAIL": "Échec de suppression",
    "INVALID_DATA": "Données non valides",
    "MS_TEAMS_DATA_NOT_FOUND": "Nous n'avons pas trouvé les données de vos Teams. Veuillez réinstaller l'application",
    "FAILED_TO_AUTHENTICATE": "Échec de l'authentification",
    "NO_LOGIN_URL": "Il n'y a pas d'url de connexion",
    "CancelledByUser": "La fenêtre de connexion a été fermée trop tôt par l'utilisateur. Vous ne devez pas fermer la fenêtre contextuelle manuellement",
    "FailedToOpenWindow": "L'ouverture des fenêtres contextuelles est bloquée. Veuillez vérifier les paramètres de votre site web",
    "ERR_NETWORK": "La connexion n'a pas été établie, veuillez réessayer plus tard",
    "ERR_BAD_REQUEST_400": "Quelque chose n'a pas fonctionné, veuillez réessayer plus tard (code d'erreur : 400)",
    "ERR_BAD_REQUEST_401": "Quelque chose n'a pas fonctionné, veuillez réessayer plus tard (code d'erreur : 401)",
    "ERR_BAD_REQUEST_404": "Quelque chose n'a pas fonctionné, veuillez réessayer plus tard (code d'erreur : 404)",
    "ERR_BAD_REQUEST_500": "Quelque chose n'a pas fonctionné, veuillez réessayer plus tard (code d'erreur : 500)",
  },
  da: {
    "enterYourEmail": "Skriv dit brugernavn:",
    "enterYourPassword": "Indtast din adgangskode:",
    "NONE": "Udefineret fejl",
    "UNKNOWN": "Ukendt fejl",
    "INVALID_CREDENTIALS": "Ugyldige legitimationsoplysninger",
    "CREDS_ALREADY_IN_USE": "Legitimationsoplysninger er allerede i brug. Du er sandsynligvis logget ind på din konto under en anden Microsoft-profil. Log venligst ud først.",
    "USER_NOT_FOUND": "Bruger ikke fundet",
    "DATA_NOT_FOUND": "Data ikke fundet",
    "UPDATE_FAIL": "Kunne ikke opdatere",
    "DELETE_FAIL": "Kunne ikke slette",
    "INVALID_DATA": "Ugyldige data",
    "MS_TEAMS_DATA_NOT_FOUND": "Vi kunne ikke finde dine Teams-data. Geninstaller venligst programmet",
    "FAILED_TO_AUTHENTICATE": "Kunne ikke godkendes",
    "NO_LOGIN_URL": "Der er ingen login-url",
    "CancelledByUser": "Login-vinduet blev lukket for tidligt af brugeren. Du bør ikke lukke popup-vinduet manuelt",
    "FailedToOpenWindow": "Åbning af pop op-vinduer er blokeret. Tjek venligst dine webstedsindstillinger",
    "ERR_NETWORK": "Forbindelsen er ikke etableret, prøv venligst igen senere",
    "ERR_BAD_REQUEST_400": "Noget gik galt, prøv venligst igen senere (fejlkode: 400)",
    "ERR_BAD_REQUEST_401": "Noget gik galt, prøv venligst igen senere (fejlkode: 401)",
    "ERR_BAD_REQUEST_404": "Noget gik galt, prøv venligst igen senere (fejlkode: 404)",
    "ERR_BAD_REQUEST_500": "Noget gik galt, prøv venligst igen senere (fejlkode: 500)",
  },
  fi: {
    "enterYourEmail": "Kirjoita käyttäjätunnuksesi:",
    "enterYourPassword": "Syötä salasanasi:",
    "NONE": "Määrittelemätön virhe",
    "UNKNOWN": "Tuntematon virhe",
    "INVALID_CREDENTIALS": "Virheelliset valtakirjat",
    "CREDS_ALREADY_IN_USE": "Tunnukset ovat jo käytössä. Olet todennäköisesti kirjautunut tilillesi muulla Microsoft-profiililla. Kirjaudu ulos sieltä ensin.",
    "USER_NOT_FOUND": "Käyttäjää ei löydy",
    "DATA_NOT_FOUND": "Tietoja ei löydy",
    "UPDATE_FAIL": "Päivitys epäonnistui",
    "DELETE_FAIL": "Epäonnistuin poistamaan",
    "INVALID_DATA": "Virheelliset tiedot",
    "MS_TEAMS_DATA_NOT_FOUND": "Emme löytäneet Teams-tietojasi. Asenna sovellus uudelleen",
    "FAILED_TO_AUTHENTICATE": "Autentikointi epäonnistui",
    "NO_LOGIN_URL": "Kirjautumisurlia ei ole",
    "CancelledByUser": "Käyttäjä sulki kirjautumisikkunan liian aikaisin. Sinun ei pitäisi sulkea ponnahdusikkunaa manuaalisesti",
    "FailedToOpenWindow": "Avautuvat ponnahdusikkunat on estetty. Tarkista sivuston asetukset",
    "ERR_NETWORK": "Yhteyttä ei ole muodostettu, yritä myöhemmin uudelleen.",
    "ERR_BAD_REQUEST_400": "Jotain meni pieleen, yritä myöhemmin uudelleen (Virhekoodi: 400)",
    "ERR_BAD_REQUEST_401": "Jotain meni pieleen, yritä myöhemmin uudelleen (Virhekoodi: 401)",
    "ERR_BAD_REQUEST_404": "Jotain meni pieleen, yritä myöhemmin uudelleen (Virhekoodi: 404)",
    "ERR_BAD_REQUEST_500": "Jotain meni pieleen, yritä myöhemmin uudelleen (Virhekoodi: 500)",
  },
  es: {
    "enterYourEmail": "Ingrese su nombre de usuario:",
    "enterYourPassword": "Introduzca su contraseña:",
    "NONE": "Error no definido",
    "UNKNOWN": "Error desconocido",
    "INVALID_CREDENTIALS": "Credenciales no válidas",
    "CREDS_ALREADY_IN_USE": "Las credenciales ya están en uso. Probablemente haya iniciado sesión en su cuenta con otro perfil de Microsoft. Cierra la sesión allí primero.",
    "USER_NOT_FOUND": "Usuario no encontrado",
    "DATA_NOT_FOUND": "Datos no encontrados",
    "UPDATE_FAIL": "Error al actualizar",
    "DELETE_FAIL": "Error al eliminar",
    "INVALID_DATA": "Datos inválidos",
    "MS_TEAMS_DATA_NOT_FOUND": "No pudimos encontrar los datos de Teams, Por favor, reinstale la aplicación",
    "FAILED_TO_AUTHENTICATE": "No se pudo autenticar",
    "NO_LOGIN_URL": "No hay URL de inicio de sesión",
    "CancelledByUser": "El usuario cerró la ventana de inicio de sesión demasiado pronto. No debe cerrar la ventana emergente manualmente",
    "FailedToOpenWindow": "La apertura de ventanas emergentes está bloqueada. Por favor, compruebe la configuración de su sitio web",
    "ERR_NETWORK": "La conexión no se ha establecido, inténtelo de nuevo más tarde",
    "ERR_BAD_REQUEST_400": "Algo salió mal, inténtelo de nuevo más tarde (Código de error: 400)",
    "ERR_BAD_REQUEST_401": "Algo salió mal, inténtelo de nuevo más tarde (Código de error: 401)",
    "ERR_BAD_REQUEST_404": "Algo salió mal, inténtelo de nuevo más tarde (Código de error: 404)",
    "ERR_BAD_REQUEST_500": "Algo salió mal, inténtelo de nuevo más tarde (Código de error: 500)",
  },
  it: {
    "enterYourEmail": "Inserire il nome utente:",
    "enterYourPassword": "Immettere la password:",
    "NONE": "Errore indefinito",
    "UNKNOWN": "Errore sconosciuto",
    "INVALID_CREDENTIALS": "Credenziali non valide",
    "CREDS_ALREADY_IN_USE": "Credenziali già in uso. Probabilmente è stato effettuato l'accesso all'account con un altro profilo Microsoft. Come prima cosa, disconnettersi da quel profilo.",
    "USER_NOT_FOUND": "Utente non trovato",
    "DATA_NOT_FOUND": "Dati non trovati",
    "UPDATE_FAIL": "Aggiornamento non riuscito",
    "DELETE_FAIL": "Eliminazione non riuscita",
    "INVALID_DATA": "Dati non validi",
    "MS_TEAMS_DATA_NOT_FOUND": "Non è stato possibile trovare i dati Teams. Reinstallare l'applicazione",
    "FAILED_TO_AUTHENTICATE": "Autenticazione non riuscita",
    "NO_LOGIN_URL": "Non è presente alcun URL di accesso",
    "CancelledByUser": "La finestra di accesso è stata chiusa troppo presto dall'utente. Non si dovrebbe chiudere la finestra popup manualmente",
    "FailedToOpenWindow": "L'apertura delle finestre popup è bloccata. Verificare le impostazioni del proprio sito Web",
    "ERR_NETWORK": "La connessione non è stata stabilita, riprovare più tardi",
    "ERR_BAD_REQUEST_400": "Qualcosa è andato storto, riprovare più tardi (codice errore: 400)",
    "ERR_BAD_REQUEST_401": "Qualcosa è andato storto, riprovare più tardi (codice errore: 401)",
    "ERR_BAD_REQUEST_404": "Qualcosa è andato storto, riprovare più tardi (codice errore: 404)",
    "ERR_BAD_REQUEST_500": "Qualcosa è andato storto, riprovare più tardi (codice errore: 500)",
  },
  ja: {
    "enterYourEmail": "ユーザー名を入力します:",
    "enterYourPassword": "パスワードを入力してください",
    "NONE": "未定義のエラー",
    "UNKNOWN": "不明なエラー",
    "INVALID_CREDENTIALS": "無効なクレデンシャル",
    "CREDS_ALREADY_IN_USE": "資格情報はすでに使用されています。他のMicrosoftプロファイルでアカウントにログインしている可能性があります。最初にログアウトしてください。",
    "USER_NOT_FOUND": "ユーザーが見つかりません",
    "DATA_NOT_FOUND": "データが見つかりません",
    "UPDATE_FAIL": "更新に失敗",
    "DELETE_FAIL": "削除に失敗しました",
    "INVALID_DATA": "無効なデータ",
    "MS_TEAMS_DATA_NOT_FOUND": "チームデータが見つかりませんでした。アプリケーションを再インストールしてください",
    "FAILED_TO_AUTHENTICATE": "認証できませんでした",
    "NO_LOGIN_URL": "ログインURLがありません",
    "CancelledByUser": "ユーザーがログインウィンドウを閉じるのが早すぎました。ポップアップウィンドウを手動で閉じないでください",
    "FailedToOpenWindow": "ポップアップウィンドウを開くことはできません。Webサイトの設定を確認してください",
    "ERR_NETWORK": "接続が確立されていません。しばらくしてから再試行してください。",
    "ERR_BAD_REQUEST_400": "何らかの問題が発生しました。後でもう一度お試してください(エラーコード400)",
    "ERR_BAD_REQUEST_401": "何らかの問題が発生しました。後でもう一度お試してください(エラーコード401)",
    "ERR_BAD_REQUEST_404": "何らかの問題が発生しました。後でもう一度お試してください(エラーコード404)",
    "ERR_BAD_REQUEST_500": "何らかの問題が発生しました。後でもう一度お試してください(エラーコード500)",
  },
  lt: {
    "enterYourEmail": "Įveskite vartotojo vardą:",
    "enterYourPassword": "Įveskite slaptažodį:",
    "NONE": "Nenustatyta klaida",
    "UNKNOWN": "Neatpažinta klaida",
    "INVALID_CREDENTIALS": "Neteisingi prisijungimo duomenys",
    "CREDS_ALREADY_IN_USE": "Tokie prisijungimo duomenys jau naudojami. Tikriausiai jau esate prisijungę prie paskyros naudodami kitą „Microsoft“ profilį. Visų pirma atsijunkite.",
    "USER_NOT_FOUND": "Vartotojas nerastas",
    "DATA_NOT_FOUND": "Duomenų nerasta",
    "UPDATE_FAIL": "Nepavyko atnaujinti",
    "DELETE_FAIL": "Nepavyko ištrinti",
    "INVALID_DATA": "Neteisingi duomenys",
    "MS_TEAMS_DATA_NOT_FOUND": "Neradome jūsų „Teams“ duomenų. Iš naujo įdiekite programą",
    "FAILED_TO_AUTHENTICATE": "Nepavyko patvirtinti tapatybės",
    "NO_LOGIN_URL": "Nėra prisijungimo url",
    "CancelledByUser": "Vartotojas per anksti uždarė prisijungimo langą. Neuždaryti iššokančio lango rankiniu būdu",
    "FailedToOpenWindow": "Iššokantys langai užblokuoti. Pasitikrinkite svetainės parametrus",
  },
  lv: {
    "enterYourEmail": "Ievadiet savu lietotājvārdu:",
    "enterYourPassword": "Ievadiet savu paroli:",
    "NONE": "Nedefinēta kļūda",
    "UNKNOWN": "Nezināma kļūda",
    "INVALID_CREDENTIALS": "Nederīgi akreditācijas dati",
    "CREDS_ALREADY_IN_USE": "Akreditācijas dati jau tiek izmantoti. Jūs, iespējams, esat pieteicies savā kontā, izmantojot citu Microsoft profilu. Lūdzu, vispirms izrakstieties tur.",
    "USER_NOT_FOUND": "Lietotājs nav atrasts",
    "DATA_NOT_FOUND": "Dati nav atrasti",
    "UPDATE_FAIL": "Neizdevās atjaunināt",
    "DELETE_FAIL": "Neizdevās izdzēst",
    "INVALID_DATA": "Nederīgi dati",
    "MS_TEAMS_DATA_NOT_FOUND": "Mēs nevarējām atrast jūsu komandas datus. Lūdzu, atkārtoti instalējiet lietojumprogrammu",
    "FAILED_TO_AUTHENTICATE": "Neizdevās autentificēt",
    "NO_LOGIN_URL": "Nav pieteikšanās url",
    "CancelledByUser": "Lietotājs pārāk agri aizvēra pieteikšanās logu. Uznirstošo logu nevajadzētu aizvērt manuāli",
    "FailedToOpenWindow": "Uznirstošo logu atvēršana ir bloķēta. Lūdzu, pārbaudiet savas vietnes iestatījumus",
    "ERR_NETWORK": "Savienojums nav izveidots. Lūdzu, vēlāk mēģiniet vēlreiz.",
    "ERR_BAD_REQUEST_400": "Kaut kas neizdevās. Lūdzu, vēlāk mēģiniet vēlreiz (kļūdas kods: 400)",
    "ERR_BAD_REQUEST_401": "Kaut kas neizdevās. Lūdzu, vēlāk mēģiniet vēlreiz (kļūdas kods: 401)",
    "ERR_BAD_REQUEST_404": "Kaut kas neizdevās. Lūdzu, vēlāk mēģiniet vēlreiz (kļūdas kods: 404)",
    "ERR_BAD_REQUEST_500": "Kaut kas neizdevās. Lūdzu, vēlāk mēģiniet vēlreiz (kļūdas kods: 500)",
  },
  nb: {
    "enterYourEmail": "Skriv inn brukernavnet ditt:",
    "enterYourPassword": "Skriv inn passordet:",
    "NONE": "Udefinert feil",
    "UNKNOWN": "Ukjent feil",
    "INVALID_CREDENTIALS": "Ugyldig legitimasjon",
    "CREDS_ALREADY_IN_USE": "Legitimasjonen er allerede i bruk. Du er sannsynligvis logget på kontoen din under en annen Microsoft-profil. Logg ut dit først.",
    "USER_NOT_FOUND": "Finner ikke bruker",
    "DATA_NOT_FOUND": "Finner ikke data",
    "UPDATE_FAIL": "Kan ikke oppdatere",
    "DELETE_FAIL": "Kan ikke slette",
    "INVALID_DATA": "Ugyldige data",
    "MS_TEAMS_DATA_NOT_FOUND": "Finner ikke Teams-dataene dine. Installer programmet på nytt",
    "FAILED_TO_AUTHENTICATE": "Kan ikke godkjenne",
    "NO_LOGIN_URL": "Det er ingen påloggingsadresse",
    "CancelledByUser": "Påloggingsvinduet ble lukket for tidlig av brukeren. Du bør ikke lukke popup-vinduet manuelt",
    "FailedToOpenWindow": "Åpne popup-vinduer er blokkert. Kontroller innstillingene for nettstedet ditt",
    "ERR_NETWORK": "Tilkoblingen er ikke opprettet, vennligst prøv igjen senere.",
    "ERR_BAD_REQUEST_400": "Noe gikk galt, prøv igjen senere (feilkode: 400)",
    "ERR_BAD_REQUEST_401": "Noe gikk galt, prøv igjen senere (feilkode: 401)",
    "ERR_BAD_REQUEST_404": "Noe gikk galt, prøv igjen senere (feilkode: 404)",
    "ERR_BAD_REQUEST_500": "Noe gikk galt, prøv igjen senere (feilkode: 500)",
  },
  et: {
    "enterYourEmail": "Sisestage oma kasutajanimi:",
    "enterYourPassword": "Sisestage oma salasõna:",
    "NONE": "Määratlemata tõrge",
    "UNKNOWN": "Teadmata tõrge",
    "INVALID_CREDENTIALS": "Kehtetu identimisteave",
    "CREDS_ALREADY_IN_USE": "Identimisteave on juba kasutusel. Olete tõenäoliselt teise Microsofti profiiliga kontole sisse logitud. Palun logige esmalt välja.",
    "USER_NOT_FOUND": "Kasutajat ei leitud",
    "DATA_NOT_FOUND": "Andmeid ei leitud",
    "UPDATE_FAIL": "Värskendamine ebaõnnestus",
    "DELETE_FAIL": "Kustutamine ebaõnnestus",
    "INVALID_DATA": "Valed andmed",
    "MS_TEAMS_DATA_NOT_FOUND": "Me ei leidnud teie Teamsi andmeid. Installige rakendus uuesti",
    "FAILED_TO_AUTHENTICATE": "Autentimine ebaõnnestus",
    "NO_LOGIN_URL": "Sisselogimise URL puudub",
    "CancelledByUser": "Kasutaja sulges sisselogimisakna liiga vara. Hüpikakent ei tohi käsitsi sulgeda",
    "FailedToOpenWindow": "Hüpikakende avamine on blokeeritud. Kontrollige veebisaidi sätteid",
    "ERR_NETWORK": "Ühendust ei loodud, proovige hiljem uuesti",
    "ERR_BAD_REQUEST_400": "Midagi läks valesti, proovige hiljem uuesti (veakood: 400)",
    "ERR_BAD_REQUEST_401": "Midagi läks valesti, proovige hiljem uuesti (veakood: 401)",
    "ERR_BAD_REQUEST_404": "Midagi läks valesti, proovige hiljem uuesti (veakood: 404)",
    "ERR_BAD_REQUEST_500": "Midagi läks valesti, proovige hiljem uuesti (veakood: 500)",
  }
});
const tabPageSelector = new LocalizedStrings({
  en: {
    "chooseTabPage": "Choose tab page:",
    "selectTabPage": "Select tab page",
    "dashboard": "Dashboard",
    "settings": "Settings",
    "org-page": "Organization Page",
    "test": "test",
  },
  sv: {
    "chooseTabPage": "Välj flik:",
    "selectTabPage": "Välj flik:",
    "dashboard": "Översikt",
    "settings": "Inställningar",
    "org-page": "Organisationssida",
    "test": "test",
  },
  nl: {
    "chooseTabPage": "Kies een tabblad:",
    "selectTabPage": "Selecteer een tabblad",
    "dashboard": "Overzicht",
    "settings": "Instellingen",
    "org-page": "Organisatiepagina",
    "test": "test",
  },
  de: {
    "chooseTabPage": "Registerkarte auswählen:",
    "selectTabPage": "Registerkarte auswählen",
    "dashboard": "Überblick",
    "settings": "die Einstellungen",
    "org-page": "Organisationsseite",
    "test": "test",
  },
  fr: {
    "chooseTabPage": "Choisissez un onglet :",
    "selectTabPage": "Sélectionner l'onglet",
    "dashboard": "Aperçu",
    "settings": "Réglages",
    "org-page": "Page de l'organisation",
    "test": "essai",
  },
  da: {
    "chooseTabPage": "Vælg faneblad:",
    "selectTabPage": "Vælg faneblad",
    "dashboard": "Overblik",
    "settings": "Indstillinger",
    "org-page": "Organisationside",
    "test": "test",
  },
  fi: {
    "chooseTabPage": "Valitse välilehti:",
    "selectTabPage": "Valitse välilehti",
    "dashboard": "Yleiskatsaus",
    "settings": "Asetukset",
    "org-page": "Organisaation sivu",
    "test": "testi",
  },
  es: {
    "chooseTabPage": "Elija la página de la pestaña:",
    "selectTabPage": "Seleccionar página de pestaña",
    "dashboard": "Visión de conjunto",
    "settings": "Ajustes",
    "org-page": "Página de la organización",
    "test": "prueba",
  },
  it: {
    "chooseTabPage": "Scegli scheda:",
    "selectTabPage": "Seleziona scheda",
    "dashboard": "Panoramica",
    "settings": "Impostazioni",
    "org-page": "Pagina Organizzazione",
    "test": "test",
  },
  ja: {
    "chooseTabPage": "タブページを選択する:",
    "selectTabPage": "タブページを選択",
    "dashboard": "概要",
    "settings": "設定",
    "org-page": "組織ページ",
    "test": "テスト",
  },
  lt: {
    "chooseTabPage": "Pasirinkite skirtuką:",
    "selectTabPage": "Pasirinkite skirtuką",
    "dashboard": "Apžvalga",
    "settings": "Nustatymai",
    "org-page": "Organizacijos puslapis",
    "test": "bandymas",
  },
  lv: {
    "chooseTabPage": "Izvēlieties cilnes lapu:",
    "selectTabPage": "Atlasiet cilnes lapu",
    "dashboard": "Pārskats",
    "settings": "Iestatījumi",
    "org-page": "Organizācijas lapa",
    "test": "tests",
  },
  nb: {
    "chooseTabPage": "Velg kategoriside:",
    "selectTabPage": "Velg kategoriside",
    "dashboard": "Oversikt",
    "settings": "Innstillinger",
    "org-page": "Organisasjonsside",
    "test": "prøve",
  },
  et: {
    "chooseTabPage": "Valige vahekaardi leht:",
    "selectTabPage": "Valige vahekaardi leht",
    "dashboard": "Ülevaade",
    "settings": "Seaded",
    "org-page": "Organisatsiooni leht",
    "test": "test",
  }
});
const acdAttendantGroups = new LocalizedStrings({
  en: {
    "name": "Name:",
    "loggedInAgents": "Logged in Agents",
    "isLoggedIn": "Log out/in",
    "listOfGroups": "Groups",
    "emptyPlaceholder": "You have no groups where you have supervisor role",
  },
  sv: {
    "name": "Namn:",
    "loggedInAgents": "Inloggade agenter",
    "isLoggedIn": "Logga ut/in",
    "listOfGroups": "Grupper",
    "emptyPlaceholder": "Du har inga grupper där du har chefsroll",
  },
  nl: {
    "name": "Naam:",
    "loggedInAgents": "Ingelogde agenten",
    "isLoggedIn": "Uitloggen/Log in",
    "listOfGroups": "Groepen",
    "emptyPlaceholder": "Je hebt geen groepen waarin je de rol van supervisor hebt",
  },
  de: {
    "name": "Name:",
    "loggedInAgents": "Angemeldete Agenten",
    "isLoggedIn": "Aus/Einloggen",
    "listOfGroups": "Gruppen",
    "emptyPlaceholder": "Sie haben keine Gruppen, in denen Sie eine Supervisor-Rolle haben",
  },
  fr: {
    "name": "Nom:",
    "loggedInAgents": "Agents connectés",
    "isLoggedIn": "Déconnecter/connecter",
    "listOfGroups": "Groupes",
    "emptyPlaceholder": "Vous n'avez aucun groupe dans lequel vous avez un rôle de superviseur",
  },
  da: {
    "name": "Navn:",
    "loggedInAgents": "Logget ind Agenter",
    "isLoggedIn": "Log ud/ind",
    "listOfGroups": "Grupper",
    "emptyPlaceholder": "Du har ingen grupper, hvor du har en supervisorrolle",
  },
  fi: {
    "name": "Nimi:",
    "loggedInAgents": "Kirjautuneet asiakaspalvelijat",
    "isLoggedIn": "Kirjaudu Ulos/Sisään",
    "listOfGroups": "Ryhmät",
    "emptyPlaceholder": "Sinulla ei ole ryhmiä, joissa sinulla on esimiesrooli.",
  },
  es: {
    "name": "Nombre:",
    "loggedInAgents": "Agentes registrados",
    "isLoggedIn": "Iniciar/cerrar sesión",
    "listOfGroups": "Grupos",
    "emptyPlaceholder": "No tiene grupos en los que tenga la función de supervisor",
  },
  it: {
    "name": "Nome:",
    "loggedInAgents": "Agenti connessi",
    "isLoggedIn": "Disconnettiti/Connettiti",
    "listOfGroups": "Gruppi",
    "emptyPlaceholder": "L'utente non ha il ruolo di supervisore in nessun gruppo",
  },
  ja: {
    "name": "名前：",
    "loggedInAgents": "ログインしているエージェント",
    "isLoggedIn": "ログアウト/イン",
    "listOfGroups": "グループ",
    "emptyPlaceholder": "スーパーバイザロールを持つグループがありません",
  },
  lt: {
    "name": "Vardas:",
    "loggedInAgents": "Prisijungę agentai",
    "isLoggedIn": "Atsijungti / prisijungti",
    "listOfGroups": "Grupės",
    "emptyPlaceholder": "Nesate nė vienos grupių vadovas",
  },
  lv: {
    "name": "Vārds:",
    "loggedInAgents": "Pieteicies vienumā Aģenti",
    "isLoggedIn": "Pieteikties/Atteikties",
    "listOfGroups": "Groupas",
    "emptyPlaceholder": "Jums nav grupu, kurā jums ir supervizora loma",
  },
  nb: {
    "name": "Navn:",
    "loggedInAgents": "Innloggede agenter",
    "isLoggedIn": "Logg ut/inn",
    "listOfGroups": "Grupper",
    "emptyPlaceholder": "Du har ingen grupper der du har lederrolle",
  },
  et: {
    "name": "Nimi:",
    "loggedInAgents": "Sisselogitud agendid",
    "isLoggedIn": "Sisse/välja logimine",
    "listOfGroups": "Rühmad",
    "emptyPlaceholder": "Teil pole rühmi, kus teil on ülevaataja roll",
  }
});
const supervisorGroups = new LocalizedStrings({
  en: {
    "name": "Log in/out colleagues of groups",
    "emptyPlaceholder": "You have no groups where you have supervisor role",
    "group": "Group",
  },
  sv: {
    "name": "Logga in / ut kollegor i grupper",
    "emptyPlaceholder": "Du har inga grupper där du har chefsroll",
    "group": "Grupp",
  },
  nl: {
    "name": "Log in / uit collegas van groepen",
    "emptyPlaceholder": "Je hebt geen groepen waarin je de rol van supervisor hebt",
    "group": "Groep",
  },
  de: {
    "name": "Anmelden / Abmelden von Kollegen von Gruppen",
    "emptyPlaceholder": "Sie haben keine Gruppen, in denen Sie eine Supervisor-Rolle haben",
    "group": "Gruppe",
  },
  fr: {
    "name": "Connecter/déconnecter les collègues des groupes.",
    "emptyPlaceholder": "Vous n'avez aucun groupe dans lequel vous avez un rôle de superviseur",
    "group": "Groupe",
  },
  da: {
    "name": "Log ind/ud kolleger i grupper",
    "emptyPlaceholder": "Du har ingen grupper, hvor du har en supervisorrolle",
    "group": "Gruppe",
  },
  fi: {
    "name": "Kirjaudu sisään/out ryhmätoverit",
    "emptyPlaceholder": "Sinulla ei ole ryhmiä, joissa sinulla on esimiesrooli.",
    "group": "Ryhmä",
  },
  es: {
    "name": "Iniciar/cerrar sesión de compañeros de grupos",
    "emptyPlaceholder": "No tiene grupos en los que tenga la función de supervisor",
    "group": "Grupo",
  },
  it: {
    "name": "Connetti/disconnetti colleghi dei gruppi",
    "emptyPlaceholder": "L'utente non ha il ruolo di supervisore in nessun gruppo",
    "group": "Gruppo",
  },
  ja: {
    "name": "グループの同僚にログイン/ログアウトします",
    "emptyPlaceholder": "スーパーバイザロールを持つグループがありません",
    "group": "グループ",
  },
  lt: {
    "name": "Prijungti / atjungti grupių kolegas",
    "emptyPlaceholder": "Nesate nė vienos grupių vadovas",
    "group": "Grupė",
  },
  lv: {
    "name": "Pieteikties/Atteikties grupu kolēģiem",
    "emptyPlaceholder": "Jums nav grupu, kurā jums ir supervizora loma",
    "group": "Groupa",
  },
  nb: {
    "name": "Logg inn/ut kolleger av grupper",
    "emptyPlaceholder": "Du har ingen grupper der du har lederrolle",
    "group": "Gruppe",
  },
  et: {
    "name": "Rühmade kolleegide sisse/välja logimine",
    "emptyPlaceholder": "Teil pole rühmi, kus teil on ülevaataja roll",
    "group": "Rühm",
  }
});
const sendSms = new LocalizedStrings({
  en: {
    "sendSms": "Send SMS",
    "searchPlaceholder": "Search contacts (type min 3 letters)",
    "searchNoResults": "We could not find any matches.",
    "typeNumberPlaceholder": "Add recipients manually (ex.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Select Caller ID",
    "messagePlaceholder": "Type your message here...",
    "messageIsSent": "Message is sent",
    "messageIsNotSent": "Message is not sent",
    "errorMessage": "Can not deliver the message to the recipients:",
    "updating": "Updating...",
  },
  sv: {
    "sendSms": "Skicka SMS",
    "searchPlaceholder": "Sök kontakter (skriv minst 3 tecken)",
    "searchNoResults": "Vi kunde inte hitta några träffar.",
    "typeNumberPlaceholder": "Lägg till mottagare manuellt (ex .: +123456789012; +09875432109)",
    "selectCallerIdPlaceholder": "Välj avsändarnummer.",
    "messagePlaceholder": "Skriv ditt meddelande här...",
    "messageIsSent": "Meddelandet skickat",
    "messageIsNotSent": "Meddelandet skickades inte",
    "errorMessage": "Kan inte leverera meddelandet till mottagarna:",
    "updating": "Uppdaterar...",
  },
  nl: {
    "sendSms": "Verstuur SMS",
    "searchPlaceholder": "Zoek contacten (typ minimaal 3 letters)",
    "searchNoResults": "Kan geen overeenkomsten vinden.",
    "typeNumberPlaceholder": "Handmatig ontvangers toevoegen (ex.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Selecteer nummerherkenning",
    "messagePlaceholder": "Schrijf je bericht hier...",
    "messageIsSent": "Bericht is verzonden",
    "messageIsNotSent": "Bericht is niet verzonden",
    "errorMessage": "Kan het bericht niet afleveren bij de ontvangers:",
    "updating": "Updaten...",
  },
  de: {
    "sendSms": "SMS senden",
    "searchPlaceholder": "Kontakte durchsuchen (Geben Sie mindestens 3 Buchstaben ein)",
    "searchNoResults": "Wir konnten keine Übereinstimmungen finden.",
    "typeNumberPlaceholder": "Empfänger manuell hinzufügen (ex.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Wählen Sie Anrufer-ID",
    "messagePlaceholder": "Schreiben Sie ihre Nachricht hier...",
    "messageIsSent": "Nachricht wird gesendet",
    "messageIsNotSent": "Nachricht wird nicht gesendet",
    "errorMessage": "Kan het bericht niet afleveren bij de ontvangers:",
    "updating": "Updaten...",
  },
  fr: {
    "sendSms": "Envoyer un SMS",
    "searchPlaceholder": "Recherche de contacts (tapez min 3 lettres)",
    "searchNoResults": "Nous n'avons pas trouvé de correspondance.",
    "typeNumberPlaceholder": "Ajouter des destinataires manuellement (ex : +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Sélectionnez l'identification de l'appelant",
    "messagePlaceholder": "Saisissez votre message ici...",
    "messageIsSent": "Le message est envoyé",
    "messageIsNotSent": "Le message n'est pas envoyé",
    "errorMessage": "Impossible d’envoyer le message aux destinataires :",
    "updating": "Mise à jour...",
  },
  da: {
    "sendSms": "Send sms",
    "searchPlaceholder": "Søg i kontakter (skriv mindst 3 bogstaver)",
    "searchNoResults": "Vi kunne ikke finde nogen matcher.",
    "typeNumberPlaceholder": "Tilføj modtagere manuelt (f.eks.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Vælg nummervisning",
    "messagePlaceholder": "Skriv din besked her...",
    "messageIsSent": "Beskeden er sendt",
    "messageIsNotSent": "Beskeden er ikke sendt",
    "errorMessage": "Kan ikke levere beskeden til modtagerne:",
    "updating": "Opdaterer...",
  },
  fi: {
    "sendSms": "Lähetä SMS",
    "searchPlaceholder": "Etsi yhteystietoja (kirjoita vähintään 3 kirjainta)",
    "searchNoResults": "Emme löytäneet yhtään vastaavaa.",
    "typeNumberPlaceholder": "Lisää vastaanottajat manuaalisesti (esim.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Valitse puhelinnumero",
    "messagePlaceholder": "Kirjoita viestisi tähän...",
    "messageIsSent": "Viesti on lähetetty",
    "messageIsNotSent": "Viestiä ei lähetetä",
    "errorMessage": "Viestiä ei voida toimittaa vastaanottajille:",
    "updating": "Päivitetään...",
  },
  es: {
    "sendSms": "Enviar SMS",
    "searchPlaceholder": "Buscar contactos (escriba min 3 letras)",
    "searchNoResults": "No pudimos encontrar ninguna coincidencia.",
    "typeNumberPlaceholder": "Agregar destinatarios manualmente (ej.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Seleccionar identificador de llamadas",
    "messagePlaceholder": "Escriba su mensaje aquí...",
    "messageIsSent": "Mensaje enviado",
    "messageIsNotSent": "Mensaje no enviado",
    "errorMessage": "No se puede entregar el mensaje a los destinatarios:",
    "updating": "Actualizando...",
  },
  it: {
    "sendSms": "Invia SMS",
    "searchPlaceholder": "Cerca contatti (digita almeno 3 lettere)",
    "searchNoResults": "Non è stato possibile trovare delle corrispondenze.",
    "typeNumberPlaceholder": "Aggiungi i destinatari manualmente (es.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Seleziona ID chiamante",
    "messagePlaceholder": "Digitare qui il messaggio...",
    "messageIsSent": "Messaggio inviato",
    "messageIsNotSent": "Messaggio non inviato",
    "errorMessage": "Impossibile consegnare il messaggio ai destinatari:",
    "updating": "In aggiornamento...",
  },
  ja: {
    "sendSms": "SMSを送信",
    "searchPlaceholder": "連絡先を検索します（3文字以上入力してください）",
    "searchNoResults": "一致するものが見つかりませんでした。",
    "typeNumberPlaceholder": "受信者を手動で追加します（例：+123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "発信者IDの選択",
    "messagePlaceholder": "ここにメッセージを入力してください。",
    "messageIsSent": "メッセージが送信されます",
    "messageIsNotSent": "メッセージは送信されません",
    "errorMessage": "受信者にメッセージを配信できません：",
    "updating": "更新中...",
  },
  lt: {
    "sendSms": "Siųsti SMS",
    "searchPlaceholder": "Ieškoti kontaktų (įveskite bent 3 raides)",
    "searchNoResults": "Neradome jokių atitikmenų.",
    "typeNumberPlaceholder": "Pridėkite gavėjus rankiniu būdu (pvz.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Pasirinkite skambintojo ID",
    "messagePlaceholder": "Rašykite žinutę čia...",
    "messageIsSent": "Žinutė išsiųsta",
    "messageIsNotSent": "Žinutė neišsiųsta",
    "errorMessage": "Nepavyko išsiųsti žinutės gavėjams:",
    "updating": "Naujinama...",
  },
  lv: {
    "sendSms": "Sūtīt SMS",
    "searchPlaceholder": "Meklēt kontaktpersonas (ievadiet vismaz 3 burtus)",
    "searchNoResults": "Mēs nevarējām atrast nevienu atbilstību.",
    "typeNumberPlaceholder": "Pievienojiet adresātus manuāli (piem.: +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Izvēlieties zvanītāja ID",
    "messagePlaceholder": "Ierakstiet savu ziņojumu šeit...",
    "messageIsSent": "Ziņojums ir nosūtīts",
    "messageIsNotSent": "Ziņojums nav nosūtīts",
    "errorMessage": "Nevar nosūtīt ziņojumu adresātiem:",
    "updating": "Notiek atjaunināšana...",
  },
  nb: {
    "sendSms": "Send SMS",
    "searchPlaceholder": "Søkekontakter (skriv inn min. 3 bokstaver)",
    "searchNoResults": "Vi fant ingen treff.",
    "typeNumberPlaceholder": "Legge til mottakere manuelt (f.eks. +123456789012;+09875432109)",
    "selectCallerIdPlaceholder": "Velg oppringer-ID",
    "messagePlaceholder": "Skriv inn meldingen her...",
    "messageIsSent": "Meldingen er sendt",
    "messageIsNotSent": "Meldingen er ikke sendt",
    "errorMessage": "Kan ikke levere meldingen til mottakerne:",
    "updating": "Oppdaterer ...",
  },
  et: {
    "sendSms": "SMS saatmine",
    "searchPlaceholder": "Kontaktide otsing (sisestage vähemalt 3 tähte)",
    "searchNoResults": "Me ei leidnud ühtegi vastet.",
    "typeNumberPlaceholder": "Lisage adressaadid käsitsi (nt: +123456789012; +09875432109)",
    "selectCallerIdPlaceholder": "Valige helistaja ID",
    "messagePlaceholder": "Kirjutage sõnum siia...",
    "messageIsSent": "Sõnum saadetud",
    "messageIsNotSent": "Sõnum ei ole saadetud",
    "errorMessage": "Sõnumit ei saa adressaatidele edastada:",
    "updating": "Uuendan...",
  }
});
const setCallerId = new LocalizedStrings({
  en: {
    "setYourCallerId": "Select your caller id",
  },
  sv: {
    "setYourCallerId": "Välj avsändarnummer",
  },
  nl: {
    "setYourCallerId": "Selecteer nummerherkenning",
  },
  de: {
    "setYourCallerId": "Wählen Sie Ihre Anrufer-ID",
  },
  fr: {
    "setYourCallerId": "Sélectionnez votre identifiant d'appelant",
  },
  da: {
    "setYourCallerId": "Vælg dit opkalds-id",
  },
  fi: {
    "setYourCallerId": "Valitse puhelinnumerosi",
  },
  es: {
    "setYourCallerId": "Seleccione su identificador de llamadas",
  },
  it: {
    "setYourCallerId": "Seleziona il tuo ID chiamante",
  },
  ja: {
    "setYourCallerId": "発信IDの選択",
  },
  lt: {
    "setYourCallerId": "Pasirinkite skambintojo ID",
  },
  lv: {
    "setYourCallerId": "Izvēlieties savu zvanītāja ID",
  },
  nb: {
    "setYourCallerId": "Velg oppringer-ID",
  },
  et: {
    "setYourCallerId": "Valige oma helistaja ID",
  }
});

const setRole = new LocalizedStrings({
  en: {
    "setYourRole": "Set your active role",
  },
  sv: {
    "setYourRole": "Ange din nuvarande roll",
  },
  nl: {
    "setYourRole": "Stel uw actieve rol in",
  },
  de: {
    "setYourRole": "Legen Sie Ihre aktive Rolle fest",
  },
  fr: {
    "setYourRole": "Réglez votre rôle actif",
  },
  da: {
    "setYourRole": "Indstil din aktive rolle",
  },
  fi: {
    "setYourRole": "Aseta aktiivinen tehtäväsi",
  },
  es: {
    "setYourRole": "Establece su rol activo",
  },
  it: {
    "setYourRole": "Imposta il tuo ruolo attivo",
  },
  ja: {
    "setYourRole": "アクティブロールを設定します",
  },
  lt: {
    "setYourRole": "[Set your active role]",
  },
  lv: {
    "setYourRole": "Iestatīt aktīvo lomu",
  },
  nb: {
    "setYourRole": "Angi din aktive rolle",
  },
  et: {
    "setYourRole": "Määrake oma aktiivne roll",
  }
});

const voiceMail = new LocalizedStrings({
  en: {
    "caller": "Caller",
    "record": "Record",
    "time": "Time",
    "seen": "Seen",
    "played": "Played",
    "listHeader": "Voicemails",
    "noRecordsText": "You have no voicemails",
    "deleteConfirmation1": "Are you sure you want to delete",
    "deleteConfirmation2": "item(s)?",
    "popupHeader": "Confirm delete?",
    "deleteSelected": "Delete selected",
    "showItems": "Show items:",
    "of": "of",
    "errorDeleting": "Error deleting item(s):",
    "voiceMailLoadError": "Failed to load voicemail",
    "cancel": "Cancel",
    "confirm": "Confirm",
  },
  sv: {
    "caller": "Uppringare",
    "record": "Spela in",
    "time": "Tid",
    "seen": "Sett",
    "played": "Lyssnat",
    "listHeader": "Röstmeddelanden",
    "noRecordsText": "Inga röstmeddelanden",
    "deleteConfirmation1": "Är du säker på att du vill ta bort ",
    "deleteConfirmation2": " objekt?",
    "popupHeader": "Bekräfta borttagning",
    "deleteSelected": "Radera valda",
    "showItems": "Visa artiklar:",
    "of": "av",
    "errorDeleting": "Fel vid radering av objekt:",
    "voiceMailLoadError": "Det gick inte att ladda r?stmeddelandet",
    "cancel": "Annullera",
    "confirm": "Bekräfta",
  },
  nl: {
    "caller": "Beller",
    "record": "Vermelding",
    "time": "Tijd",
    "seen": "Gezien",
    "played": "Gespeeld",
    "listHeader": "Voicemails",
    "noRecordsText": "U heeft geen voicemails",
    "deleteConfirmation1": "Weet je zeker dat je wilt verwijderen",
    "deleteConfirmation2": "Artikelen?",
    "popupHeader": "Bevestig verwijderen?",
    "deleteSelected": "Verwijder geselecteerde",
    "showItems": "Toon items:",
    "of": "van",
    "errorDeleting": "Fout bij het verwijderen van items:",
    "voiceMailLoadError": "kon voicemail niet laden",
    "cancel": "Annuleren",
    "confirm": "Bevestigen",
  },
  de: {
    "caller": "Anruferin",
    "record": "Aufzeichnung",
    "time": "Zeit",
    "seen": "Gesehen",
    "played": "Gespielt",
    "listHeader": "Voicemails",
    "noRecordsText": "Sie haben keine Voicemails",
    "deleteConfirmation1": "Sind Sie sicher, dass Sie löschen möchten",
    "deleteConfirmation2": "Artikel?",
    "popupHeader": "Löschen bestätigen?",
    "deleteSelected": "Ausgewählte löschen",
    "showItems": "Elemente anzeigen:",
    "of": "von",
    "errorDeleting": "Fehler beim Löschen von Elementen:",
    "voiceMailLoadError": "Voicemail konnte nicht geladen werden",
    "cancel": "Stornieren",
    "confirm": "Bestätigen",
  },
  fr: {
    "caller": "Appelant",
    "record": "Enregistrement",
    "time": "Heure",
    "seen": "Vu",
    "played": "Lu",
    "listHeader": "Messagerie vocale",
    "noRecordsText": "Vous n'avez pas de messages vocaux",
    "deleteConfirmation1": "Voulez-vous vraiment supprimer",
    "deleteConfirmation2": "élément(s)?",
    "popupHeader": "Confirmer supprimer?",
    "deleteSelected": "Supprimer la sélection",
    "showItems": "Afficher les éléments :",
    "of": "sur",
    "errorDeleting": "Erreur de suppression d'élément(s) :",
    "voiceMailLoadError": "Échec du chargement de la messagerie vocale",
    "cancel": "Annuler",
    "confirm": "Confirmer",
  },
  da: {
    "caller": "Ringer op",
    "record": "Optagelse",
    "time": "Tid",
    "seen": "Set",
    "played": "Spillede",
    "listHeader": "Voicemails",
    "noRecordsText": "Du har ingen voicemails",
    "deleteConfirmation1": "Er du sikker på, at du vil slette",
    "deleteConfirmation2": "vare(r)?",
    "popupHeader": "Bekræft sletning?",
    "deleteSelected": "Slet valgte",
    "showItems": "Vis varer:",
    "of": "af",
    "errorDeleting": "Fejl ved sletning af emne(r):",
    "voiceMailLoadError": "Voicemail kunne ikke indlæses",
    "cancel": "Annuller",
    "confirm": "Bekræft",
  },
  fi: {
    "caller": "soittaja",
    "record": "Tallenne",
    "time": "Aika",
    "seen": "Nähty",
    "played": "Soitettu",
    "listHeader": "Ääniviestit",
    "noRecordsText": "Sinulla ei ole ääniviestejä",
    "deleteConfirmation1": "Haluatko varmasti poistaa",
    "deleteConfirmation2": "tallenteet?",
    "popupHeader": "Vahvista poisto?",
    "deleteSelected": "Poista valitut",
    "showItems": "Näytä tallenteet:",
    "of": "tai",
    "errorDeleting": "Virhe tallenteen poistamisessa:",
    "voiceMailLoadError": "Ääniviestin lataaminen epäonnistui",
    "cancel": "Peruuta",
    "confirm": "Vahvista",
  },
  es: {
    "caller": "Emisor",
    "record": "Grabando",
    "time": "Hora",
    "seen": "Visto",
    "played": "Reproducido",
    "listHeader": "Correos de voz",
    "noRecordsText": "No tiene mensajes de voz",
    "deleteConfirmation1": "¿Está seguro de que quiere borrarlo",
    "deleteConfirmation2": "elemento(s)?",
    "popupHeader": "¿Confirmar eliminación?",
    "deleteSelected": "Borrar elemento seleccionado",
    "showItems": "Mostrar elementos:",
    "of": "de",
    "errorDeleting": "Error al eliminar elementos:",
    "voiceMailLoadError": "No se pudo cargar el correo de voz",
    "cancel": "Cancelar",
    "confirm": "Confirmar",
  },
  it: {
    "caller": "Chiamante",
    "record": "Registrazione",
    "time": "Ora",
    "seen": "Viste",
    "played": "Riprodotte",
    "listHeader": "registrati",
    "noRecordsText": "Non sono presenti messaggi nella casella vocale",
    "deleteConfirmation1": "Sei sicuro di voler eliminare",
    "deleteConfirmation2": "l'elemento/gli elementi?",
    "popupHeader": "Confermare l'eliminazione?",
    "deleteSelected": "Elimina elementi selezionati",
    "showItems": "Mostra elementi:",
    "of": "di",
    "errorDeleting": "Errore durante l'eliminazione dell'elemento/degli elementi:",
    "voiceMailLoadError": "Impossibile caricare casella vocale",
    "cancel": "Annulla",
    "confirm": "Conferma",
  },
  ja: {
    "caller": "依頼者",
    "record": "録音",
    "time": "時刻",
    "seen": "表示されます",
    "played": "プレイ済み",
    "listHeader": "ボイスメール",
    "noRecordsText": "ボイスメールがありません",
    "deleteConfirmation1": "削除してもよろしいでしょうか",
    "deleteConfirmation2": "アイテム？",
    "popupHeader": "削除を確認しますか？",
    "deleteSelected": "選択を削除",
    "showItems": "項目を表示:",
    "of": "の",
    "errorDeleting": "アイテムの削除中にエラーが発生しました:",
    "voiceMailLoadError": "ボイスメールをロードできませんでした。",
    "cancel": "キャンセル",
    "confirm": "確認",
  },
  lt: {
    "caller": "Skambintojas",
    "record": "Įrašymas",
    "time": "Laikas",
    "seen": "Matyta",
    "played": "Leista",
    "listHeader": "Balso pranešimai",
    "noRecordsText": "Neturitė pranešimų",
    "deleteConfirmation1": "Ar tikrai norite naikinti",
    "deleteConfirmation2": "elementą (-us)?",
    "popupHeader": "Patvirtinti naikinimą?",
    "deleteSelected": "Pasirinktas naikinimas",
    "showItems": "Rodyti elementus:",
    "of": "iš",
    "errorDeleting": "Klaida naikinant elementą (-us):",
    "voiceMailLoadError": "Nepavyko įkelti balso pranešimų",
    "cancel": "Atšaukti",
    "confirm": "Patvirtinti",
  },
  lv: {
    "caller": "Zvanītājs",
    "record": "Ierakstīšana",
    "time": "Laiks",
    "seen": "Redzēts",
    "played": "Atskaņots",
    "listHeader": "Balss pasts",
    "noRecordsText": "Jums nav balss pasta ziņojumu",
    "deleteConfirmation1": "Vai tiešām vēlaties dzēst?",
    "deleteConfirmation2": "vienums(i)?",
    "popupHeader": "Vai apstiprināt dzēšanu?",
    "deleteSelected": "Dzēst atlasīto",
    "showItems": "Rādīt vienumus:",
    "of": "no",
    "errorDeleting": "Kļūda, dzēšot vienumu(-s):",
    "voiceMailLoadError": "Neizdevās ielādēt balss pastu",
    "cancel": "Atcelt",
    "confirm": "Apstiprināt",
  },
  nb: {
    "caller": "Ringer",
    "record": "Tar opp",
    "time": "Klokkeslett",
    "seen": "Innlysende",
    "played": "Spilte",
    "listHeader": "Telefonsvarer",
    "noRecordsText": "Du har ingen telefonsvarer",
    "deleteConfirmation1": "Er du sikker på at du vil slette",
    "deleteConfirmation2": "element(er)?",
    "popupHeader": "Bekrefte sletting?",
    "deleteSelected": "Slett valgt",
    "showItems": "Vis elementer:",
    "of": "av",
    "errorDeleting": "Feil under sletting av element(er):",
    "voiceMailLoadError": "Kan ikke laste inn telefonsvarer",
    "cancel": "Avbryt",
    "confirm": "Bekreft",
  },
  et: {
    "caller": "Helistaja",
    "record": "Salvestamine",
    "time": "Kellaaeg",
    "seen": "Nähtav",
    "played": "Turniire",
    "listHeader": "Kõnepost",
    "noRecordsText": "Teil pole ühtegi kõneposti",
    "deleteConfirmation1": "Kas olete kindel, et soovite kustutada",
    "deleteConfirmation2": "üksuse/üksust?",
    "popupHeader": "Kas kinnitate kustutamise?",
    "deleteSelected": "Kustutage valitud",
    "showItems": "Kuva üksuseid:",
    "of": "/",
    "errorDeleting": "Tõrge üksuse/üksuste kustutamisel:",
    "voiceMailLoadError": "Kõneposti laadimine ebaõnnestus",
    "cancel": "Tühista",
    "confirm": "Kinnita",
  }
});
const dashboard = new LocalizedStrings({
  en: {
    "login": "Login",
    "signup": "[Contact our support] for help or information on how to become a customer"
  },
  sv: {
    "login": "Logga in",
    "signup": "[Kontakta vår support] för hjälp eller information om hur du blir kund."
  },
  nl: {
    "login": "Log in",
    "signup": "[Neem contact op met onze ondersteuning] voor hulp of informatie over hoe u klant kunt worden."
  },
  de: {
    "login": "Einloggen",
    "signup": "[Wenden Sie sich an unseren Support], um Hilfe oder Informationen darüber zu erhalten, wie Sie Kunde werden können."
  },
  fr: {
    "login": "Se connecter",
    "signup": "[Contactez notre support] pour obtenir de l'aide ou des informations sur la façon de devenir client."
  },
  da: {
    "login": "Log ind",
    "signup": "[Kontakt vores support] til hjælp eller information om, hvordan du bliver kunde."
  },
  fi: {
    "login": "Sisäänkirjautuminen",
    "signup": "[Ota yhteyttä tukeemme] saadaksesi apua tai lisätietoja siitä, miten voit liittyä asiakkaaksi."
  },
  es: {
    "login": "Iniciar sesión",
    "signup": "[Comuníquese con nuestro soporte] para obtener ayuda o información sobre cómo convertirse en cliente."
  },
  it: {
    "login": "Accedi",
    "signup": "[Contatta il nostro supporto] per assistenza o informazioni su come diventare un cliente."
  },
  ja: {
    "login": "ログイン",
    "signup": "[ヘルプま]たは顧客になる方法については、サポートにお問い合わせください。"
  },
  lt: {
    "login": "Prisijungti",
    "signup": "[Susisiekite su mūsų palaikymo komanda], kad gautumėte pagalbos ar informacijos, kaip tapti klientu."
  },
  lv: {
    "login": "Pieteikties",
    "signup": "[Sazinieties ar mūsu atbalsta dienestu], lai saņemtu palīdzību vai informāciju par to, kā kļūt par klientu."
  },
  nb: {
    "login": "Logg på",
    "signup": "[Kontakt vår støtte] for hjelp eller informasjon om hvordan du blir kunde."
  },
  et: {
    "login": "Logi sisse",
    "signup": "Abi voi kliendiks saamise kohta teabe saamiseks votke [ühendust meie toega]."
  }
});
const settings = new LocalizedStrings({
  en: {
    "userPresenceMappingsEnabled": "Your organization administrator can disable editing of presence mappings",
    "userPresenceMappingsDisabled": "Your organization administrator has disabled editing of presence mappings",
    "mappingSettings": "Presence mappings",
    "defaultMappingSettings": "Default presence mappings",
    "msTeamsManualPresence": "Microsoft Teams Manual Presences",
    "msTeamsAutoPresence": "Microsoft Teams Automatic Presences",
    "msTeamsAutoPresenceHint": "Microsoft Teams sets these presences based on app usage. Only map these if comfortable with Microsoft Teams behaviour",
    "pbxPresence": "Phone system presences",
    "msTeamsNoChangeState": "No change",
    "save": "Save",
    "discard": "Discard",
    "cancel": "Cancel",
    "yes": "Yes",
    "areYouSure": "Are you sure?",
    "Available": "Available",
    "Away": "Appear away",
    "BeRightBack": "Be right back",
    "Busy": "Busy",
    "DoNotDisturb": "Do not disturb",
    "InACall": "In a call",
    "InAConferenceCall": "In a conference call",
    "Inactive": "Inactive",
    "InAMeeting": "In a meeting",
    "Offline":  "Off work",
    "OffWork": "Appear offline",
    "OutOfOffice": "Out of office",
    "PresenceUnknown": "Presence unknown",
    "Presenting": "Presenting",
    "UrgentInterruptionsOnly": "Urgent interruptions only",
    "resetToDefault": "Reset to default",
    "resetAllUserPresenceMappingsToDefault": "Reset all user presence mappings to default",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "This will delete presence mapping overrides for all users.",
    "adminOnly": "Available only for Admin",
    "default": "default"
  },
  sv: {
    "userPresenceMappingsEnabled": "Din organisationsadministratör kan stänga av möjlighet att redigera aktivitetsmappningar",
    "userPresenceMappingsDisabled": "Din organisationsadministratör har stängt av möjlighet att redigera aktivitetsmappningar",
    "mappingSettings": "Aktivitetsmappningar",
    "defaultMappingSettings": "Standard aktivitetsmappningar",
    "msTeamsManualPresence": "Microsoft Teams manuella status",
    "msTeamsAutoPresence": "Microsoft Teams automatiska statusar",
    "msTeamsAutoPresenceHint":
      "Microsoft Teams sätter dessa närvarostatusar baserat på hur Microsoft Teams används. Koppla endast ihop dessa med alternativ i växeln om ni är bekväma med hur de ändras.",
    "pbxPresence": "Motsvarande status i telefonsystemet",
    "msTeamsNoChangeState": "[No change]",
    "save": "Spara",
    "discard": "Kassera",
    "cancel": "Annullera",
    "yes": "Ja",
    "areYouSure": "Är du säker?",
    "Available": "Tillgänglig",
    "Away": "Visa som borta",
    "BeRightBack": "Strax tillbaka",
    "Busy": "Upptagen",
    "DoNotDisturb": "Stör ej",
    "InACall": "Upptagen i samtal",
    "InAConferenceCall": "Upptagen i konferenssamtal",
    "Inactive": "Inaktiv",
    "InAMeeting": "I ett möte",
    "Offline": "Från Jobbet",
    "OffWork": "Visa som offline",
    "OutOfOffice": "Inte på kontoret",
    "PresenceUnknown": "Närvaro okänd",
    "Presenting": "Presenterar",
    "UrgentInterruptionsOnly": "Endast brådskande avbrott",
    "resetToDefault": "Återställ standard",
    "resetAllUserPresenceMappingsToDefault": "Återställ alla användares aktivitetsmappningar till standard",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "Detta kommer att radera alla användares överridande aktivitetsmappningar.",
    "adminOnly": "Endast tillgängligt för Admin",
    "default": "standard"
  },
  nl: {
    "userPresenceMappingsEnabled": "De beheerder van uw organisatie kan het bewerken van aanwezigheidsmappings uitschakelen",
    "userPresenceMappingsDisabled": "De beheerder van uw organisatie heeft het bewerken van aanwezigheidsmappings uitgeschakeld",
    "mappingSettings": "Aanwezigheidsmappings",
    "defaultMappingSettings": "Standaard aanwezigheidsmappings",
    "msTeamsManualPresence": "Microsoft Teams handmatige aanwezigheid",
    "msTeamsAutoPresence": "Automatische aanwezigheid van Microsoft Teams",
    "msTeamsAutoPresenceHint":
      'Microsoft Teams stelt deze aanwezigheid in op basis van app-gebruik. Breng deze alleen in kaart als u vertrouwd bent met het gedrag van Microsoft Teams',
    "pbxPresence": "Aanwezigheid telefoonsysteem",
    "msTeamsNoChangeState": "[No change]",
    "save": "Opslaan",
    "discard": "Weggooien",
    "cancel": "Annuleren",
    "yes": "Ja",
    "areYouSure": "Bent u zeker?",
    "Available": "Beschikbaar",
    "Away": "Als afwezig weergeven",
    "BeRightBack": "Zo terug",
    "Busy": "Bezet",
    "DoNotDisturb": "Niet storen",
    "InACall": "In een gesprek",
    "InAConferenceCall": "In een telefonische vergadering",
    "Inactive": "Inactief",
    "InAMeeting": "In een vergadering",
    "Offline": "Buiten het werk",
    "OffWork": "Offline weergeven", 
    "OutOfOffice": "Niet op kantoor",
    "PresenceUnknown": "Aanwezigheid onbekend",
    "Presenting": "Presenteren",
    "UrgentInterruptionsOnly": "Alleen urgente onderbrekingen",
    "resetToDefault": "Terugzetten naar standaard",
    "resetAllUserPresenceMappingsToDefault": "Alle gebruikers-aanwezigheidsmappings terugzetten naar standaard",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "Dit zal overschrijvingen van aanwezigheidsmappings voor alle gebruikers verwijderen.",
    "adminOnly": "Alleen beschikbaar voor Admin",
    "default": "standaard"
  },
  de: {
    "userPresenceMappingsEnabled": "Ihr Organisationsadministrator kann die Bearbeitung von Anwesenheitszuordnungen deaktivieren",
    "userPresenceMappingsDisabled": "Ihr Organisationsadministrator hat die Bearbeitung von Anwesenheitszuordnungen deaktiviert",
    "mappingSettings": "Anwesenheitszuordnungen",
    "defaultMappingSettings": "Standardanwesenheitszuordnungen",
    "msTeamsManualPresence": "Manuelle Anwesenheit des Microsoft Teams",
    "msTeamsAutoPresence": "Microsoft Teams Automatische Präsenz",
    "msTeamsAutoPresenceHint":
      'Microsoft Teams legen diese Einstellungen basierend auf der App-Nutzung fest. Ordnen Sie diese nur zu, wenn Sie mit dem Verhalten des Microsoft Teams vertraut sind',
    "pbxPresence": "Anwesenheit des Telefonsystems",
    "msTeamsNoChangeState": "[No change]",
    "save": "Speichern",
    "discard": "Verwerfen",
    "cancel": "Stornieren",
    "yes": "Ja",
    "areYouSure": "Sind Sie sicher?",
    "Available": "Verfügbar",
    "Away": "Als abwesend anzeigen",
    "BeRightBack": "Bin gleich zurück",
    "Busy": "Beschäftigt",
    "DoNotDisturb": "Nicht stören",
    "InACall": "In einem Telefongesprach",
    "InAConferenceCall": "In einer Telefonkonferenz",
    "Inactive": "Inaktiv",
    "InAMeeting": "In einem Meeting",
    "Offline": "Off work",
    "OffWork": "Als offline anzeigen",
    "OutOfOffice": "Ausserhaus",
    "PresenceUnknown": "Anwesenheit unbekannt",
    "Presenting": "Präsentieren",
    "UrgentInterruptionsOnly": "Nur dringende Unterbrechungen",
    "resetToDefault": "Auf Standard zurücksetzen",
    "resetAllUserPresenceMappingsToDefault": "Alle Benutzeranwesenheitszuordnungen auf Standard zurücksetzen",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "Dadurch werden Überschreibungen der Anwesenheitszuordnung für alle Benutzer gelöscht.",
    "adminOnly": "Nur für Admin verfügbar",
    "default": "standard"
  },
  fr: {
    "userPresenceMappingsEnabled": "L'administrateur de votre organisation peut désactiver la modification des mappages de présence",
    "userPresenceMappingsDisabled": "L'administrateur de votre organisation a désactivé la modification des mappages de présence",
    "mappingSettings": "Mappages de présence",
    "defaultMappingSettings": "Mappages de présence par défaut",
    "msTeamsManualPresence": "Présences manuelles des Microsoft Teams",
    "msTeamsAutoPresence": "Présences automatiques des Microsoft Teams",
    "msTeamsAutoPresenceHint": "Microsoft Teams définit ces présences en fonction de l'utilisation de l'application. Ne les mappez que si vous êtes à l'aise avec le comportement des Microsoft Teams",
    "pbxPresence": "Présences du système téléphonique",
    "msTeamsNoChangeState": "[No change]",
    "save": "Enregistrer",
    "discard": "Rejeter",
    "cancel": "Annuler",
    "yes": "Oui",
    "areYouSure": "Êtes-vous sûr ?",
    "Available": "Disponible",
    "Away": "Apparaître absent",
    "BeRightBack": "De retour bientôt",
    "Busy": "Occupé",
    "DoNotDisturb": "Ne pas déranger",
    "InACall": "Au téléphone",
    "InAConferenceCall": "Durant une conférence",
    "Inactive": "Inactif",
    "InAMeeting": "En réunion",
    "Offline": "Hors travail",
    "OffWork": "Apparaître hors ligne",
    "OutOfOffice": "Hors du bureau",
    "PresenceUnknown": "Présence inconnue",
    "Presenting": "Présenter",
    "UrgentInterruptionsOnly": "Interruptions urgentes uniquement",
    "resetToDefault": "Rétablir la valeur par défaut",
    "resetAllUserPresenceMappingsToDefault": "Rétablir les paramètres de présence par défaut pour tous les utilisateurs",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "Cette opération supprime les combinaisons de présences pour tous les utilisateurs.",
    "adminOnly": "Disponible uniquement pour l'Admin",
    "default": "défaut"
  },
  da: {
    "userPresenceMappingsEnabled": "Din organisationsadministrator kan deaktivere redigering af tilstedeværelsestilknytninger",
    "userPresenceMappingsDisabled": "Din organisations administrator har deaktiveret redigering af tilstedeværelsestilknytninger",
    "mappingSettings": "Tilstedeværelseskortlægninger",
    "defaultMappingSettings": "Standard tilstedeværelsestilknytninger",
    "msTeamsManualPresence": "Microsoft Teams manuelle tilstedeværelser",
    "msTeamsAutoPresence": "Microsoft Teams automatiske tilstedeværelser",
    "msTeamsAutoPresenceHint": "Microsoft Teams indstiller disse tilstedeværelser baseret på appbrug. Kortlæg kun disse, hvis du er fortrolig med Microsoft Teams adfærd",
    "pbxPresence": "Telefonsystemtilstedeværelse",
    "msTeamsNoChangeState": "[No change]",
    "save": "Gem",
    "discard": "Kasser",
    "cancel": "Annuller",
    "yes": "Ja",
    "areYouSure": "Er du sikker?",
    "Available": "Online",
    "Away": "Vis som Ikke til stede",
    "BeRightBack": "Er straks tilbage",
    "Busy": "Optaget",
    "DoNotDisturb": "Vil ikke forstyrres",
    "InACall": "I et opkald",
    "InAConferenceCall": "I et telefonmøde",
    "Inactive": "Inaktiv",
    "InAMeeting": "Er i møde",
    "Offline": "Fri fra arbejde",
    "OffWork": "Vis som offline",
    "OutOfOffice": "Ikke på kontoret",
    "PresenceUnknown": "Tilstedeværelse ukendt",
    "Presenting": "Præsenterer",
    "UrgentInterruptionsOnly": "Kun akutte afbrydelser",
    "resetToDefault": "Nulstil til standard",
    "resetAllUserPresenceMappingsToDefault": "Nulstil alle brugertilstedeværelsestilknytninger til standard",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "Dette vil slette tilsidesættelser af tilstedeværelseskortlægning for alle brugere.",
    "adminOnly": "Kun tilgængelig for Admin",
    "default": "standard"
  },
  fi: {
    "userPresenceMappingsEnabled": "Organisaation järjestelmänvalvoja voi poistaa läsnäolokäsittelyjen muokkaamisen käytöstä",
    "userPresenceMappingsDisabled": "Organisaation järjestelmänvalvoja on poistanut läsnäolokäsittelyjen muokkaamisen",
    "mappingSettings": "Läsnäolon kartoitukset",
    "defaultMappingSettings": "Oletusläsnäolon kartoitukset",
    "msTeamsManualPresence": "Teams Manuaalinen Läsnäolotila",
    "msTeamsAutoPresence": "Teams Automaattinen Läsnäolotila",
    "msTeamsAutoPresenceHint": "Teams määrittää nämä olotilat sovelluksen käytön perusteella. Määritä nämä vain, jos olet tyytyväinen Teamsin käyttäytymiseen",
    "pbxPresence": "Puhelinjärjestelmän Läsnäolotila",
    "msTeamsNoChangeState": "[No change]",
    "save": "Tallenna",
    "discard": "Hävitä",
    "cancel": "Peruuta",
    "yes": "Joo",
    "areYouSure": "Oletko varma?",
    "Available": "Tavoitettavissa",
    "Away": "Poissa paikalta",
    "BeRightBack": "Palaan pian",
    "Busy": "Varattu",
    "DoNotDisturb": "Älä häiritse",
    "InACall": "Puhelu käynnissä",
    "InAConferenceCall": "Konferenssissa puhelu",
    "Inactive": "Ei aktiivinen",
    "InAMeeting": "* Kokouksessa.",
    "Offline": "Ei verkossa",
    "OffWork": "Poissa työstä",
    "OutOfOffice": "Poissa virasta",
    "PresenceUnknown": "Olotila tuntematon",
    "Presenting": "Esittely",
    "UrgentInterruptionsOnly": "Vain kiireelliset keskeytykset",
    "resetToDefault": "Palauta oletusarvoon",
    "resetAllUserPresenceMappingsToDefault": "Palauta kaikki käyttäjän läsnäolon määritykset oletusarvoon",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "Tämä poistaa läsnäolon kartoituksen ohitukset kaikille käyttäjille.",
    "adminOnly": "Saatavilla vain ylläpitäjälle",
    "default": "oletuksena"
  },
  es: {
    "userPresenceMappingsEnabled": "El administrador de su organización puede deshabilitar la edición de asignaciones de presencia",
    "userPresenceMappingsDisabled": "El administrador de su organización ha deshabilitado la edición de asignaciones de presencia",
    "mappingSettings": "Mapeos de presencia",
    "defaultMappingSettings": "Mapeos de presencia predeterminados",
    "msTeamsManualPresence": "Presencias manuales de Microsoft Teams",
    "msTeamsAutoPresence": "Presencias automáticas de Microsoft Teams",
    "msTeamsAutoPresenceHint": "Microsoft Teams establece estas presencias en función del uso de la aplicación. Asigne estos solo si se siente cómodo con el comportamiento de Microsoft Teams",
    "pbxPresence": "Presencias del sistema telefónico",
    "msTeamsNoChangeState": "[No change]",
    "save": "Guardar",
    "discard": "Descartar",
    "cancel": "Cancelar",
    "yes": "Sí",
    "areYouSure": "¿Está seguro?",
    "Available": "Disponible",
    "Away": "Aparecer como ausente",
    "BeRightBack": "Vuelvo enseguida",
    "Busy": "Ocupado",
    "DoNotDisturb": "No molestar",
    "InACall": "En una llamada",
    "InAConferenceCall": "En una conferencia",
    "Inactive": "Inactivo",
    "InAMeeting": "En una reunión",
    "Offline": "Fuera del trabajo",
    "OffWork": "Desconectado",
    "OutOfOffice": "Fuera de la oficina",
    "PresenceUnknown": "Presencia desconocida",
    "Presenting": "Presentación",
    "UrgentInterruptionsOnly": "Sólo interrupciones urgentes",
    "resetToDefault": "Restablecer a los predeterminados",
    "resetAllUserPresenceMappingsToDefault": "Restablecer todos los mapeos de presencia de usuarios a los valores predeterminados",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "Esto eliminará las anulaciones de mapeo de presencia para todos los usuarios.",
    "adminOnly": "Disponible solo para el administrador",
    "default": "predeterminada"
  },
  it: {
    "userPresenceMappingsEnabled": "L'amministratore della propria organizzazione può disabilitare la modifica delle mappature delle presenze",
    "userPresenceMappingsDisabled": "L'amministratore della propria organizzazione ha disabilitato la modifica delle mappature delle presenze",
    "mappingSettings": "Mappature delle presenze",
    "defaultMappingSettings": "Mappature delle presenze predefinite",
    "msTeamsManualPresence": "Presenze Microsoft Teams manuali",
    "msTeamsAutoPresence": "Presenze Microsoft Teams automatiche",
    "msTeamsAutoPresenceHint": "Microsoft Teams imposta queste presenze in base all'utilizzo dell'app. Mapparle solo se hai dimestichezza con il comportamento di Microsoft Teams",
    "pbxPresence": "Presenze sistema telefonico",
    "msTeamsNoChangeState": "[No change]",
    "save": "Salva",
    "discard": "Elimina",
    "cancel": "Annulla",
    "yes": "Sì",
    "areYouSure": "Sicuri?",
    "Available": "Disponibile",
    "Away": "Risulta assente",
    "BeRightBack": "Torno subito",
    "Busy": "Non disponibile",
    "DoNotDisturb": "Non disturbare",
    "InACall": "In una chiamata",
    "InAConferenceCall": "In una conferenza",
    "Inactive": "Inattivo",
    "InAMeeting": "In un meeting",
    "Offline": "Non al lavoro",
    "OffWork": "Risulta offline",
    "OutOfOffice": "Fuori ufficio",
    "PresenceUnknown": "Presenza sconosciuta",
    "Presenting": "Presentazione in corso",
    "UrgentInterruptionsOnly": "Solo interruzioni urgenti",
    "resetToDefault": "Ripristina predefiniti",
    "resetAllUserPresenceMappingsToDefault": "Ripristina le mappature delle presenze di tutti gli utenti alle impostazioni predefinite",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "Le sovrascritture delle mappature delle presenze per tutti gli utenti verranno eliminate.",
    "adminOnly": "Disponibile solo per l'amministratore",
    "default": "predefiniti"
  },
  ja: {
    "userPresenceMappingsEnabled": "組織管理者はプレゼンスマッピングの編集を無効にすることができます",
    "userPresenceMappingsDisabled": "組織の管理者がプレゼンスマッピングの編集を無効にしました",
    "mappingSettings": "プレゼンスマッピング",
    "defaultMappingSettings": "デフォルトプレゼンスマッピング",
    "msTeamsManualPresence": "チームの手動設定",
    "msTeamsAutoPresence": "チーム自動プレゼンス",
    "msTeamsAutoPresenceHint": "チームは、アプリケーションの使用状況に基づいてこれらのプレゼンスを設定します。チームの行動に慣れている場合にのみ、これらをマッピングしてください",
    "pbxPresence": "電話システムプレゼンス",
    "msTeamsNoChangeState": "[No change]",
    "save": "保存",
    "discard": "取消",
    "cancel": "キャンセル",
    "yes": "はい",
    "areYouSure": "実行してもよろしいですか?",
    "Available": "連絡可能",
    "Away": "退席中表示",
    "BeRightBack": "一時退席中",
    "Busy": "取り込み中",
    "DoNotDisturb": "応答不可",
    "InACall": "呼び出し中",
    "InAConferenceCall": "電話会議中",
    "Inactive": "非アクティブ",
    "InAMeeting": "会議中",
    "Offline": "勤務時間外",
    "OffWork": "オフライン表示",
    "OutOfOffice": "外出中",
    "PresenceUnknown": "状態確認不可",
    "Presenting": "存在する",
    "UrgentInterruptionsOnly": "緊急の割り込みのみ",
    "resetToDefault": "デフォルトにリセット",
    "resetAllUserPresenceMappingsToDefault": "すべてのユーザプレゼンスマッピングをデフォルトにリセットします",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "これにより、すべてのユーザのプレゼンスマッピングオーバーライドが削除されます。",
    "adminOnly": "管理者のみ利用可能",
    "default": "デフォルト"
  },
  lt: {
    "mappingSettings": "Buvimo atvaizdavimas",
    "defaultMappingSettings": "Numatytasis buvimo atvaizdavimas",
    "msTeamsManualPresence": "„Microsoft Teams“ rankinis buvo nustatymas",
    "msTeamsAutoPresence": "„Microsoft Teams“ automatinis buvimo nustatymas",
    "msTeamsAutoPresenceHint": "„Microsoft Teams“ nustato buvimą remiantis programos naudojimu. Atvaizduoti tai tik tada, jei tai tinkama „Microsoft Teams“ elgesiui",
    "pbxPresence": "Buvimas per telefono sistemą",
    "msTeamsNoChangeState": "[No change]",
    "save": "Išsaugoti",
    "discard": "Atmesti",
    "cancel": "Atšaukti",
    "Available": "Pasiekiamas",
    "Away": "Rodyti kaip atsitraukusį",
    "BeRightBack": "Netrukus grįšiu",
    "Busy": "Užsiėmęs",
    "DoNotDisturb": "Netrukdyti",
    "InACall": "Dalyvauja pokalbyje",
    "InAConferenceCall": "Dalyvauja konferenciniame pokalbyje",
    "Inactive": "Neaktyvus",
    "InAMeeting": "Dalyvauja susitikime",
    "Offline": "Ne darbe",
    "OffWork": "Rodyti kaip neprisijungusį",
    "OutOfOffice": "Išvykęs",
    "PresenceUnknown": "Buvimo būsena nežinoma",
    "Presenting": "Dalyvauja pateiktyje",
    "UrgentInterruptionsOnly": "Kreiptis tik skubiu reikalu",
    "resetToDefault": "Grąžinti numatytuosius parametrus",
    "adminOnly": "Disponibile solo per l'amministratore",
    "default": "numatytas"
  },
  lv: {
    "userPresenceMappingsEnabled": "Jūsu organizācijas administrators var atspējot klātbūtnes kartējumu rediģēšanu",
    "userPresenceMappingsDisabled": "Jūsu organizācijas administrators ir atspējojis klātbūtnes kartējumu rediģēšanu.",
    "mappingSettings": "Klātbūtnes kartējums",
    "defaultMappingSettings": "Noklusējuma klātbūtnes kartējums",
    "msTeamsManualPresence": "Microsoft Teams manuālā klātbūtne",
    "msTeamsAutoPresence": "Microsoft Teams automātiskā klātbūtne",
    "msTeamsAutoPresenceHint": "Microsoft Teams iestata šīs klātbūtnes, pamatojoties uz lietotnes lietojumu. Kartējiet tos tikai tad, ja jums patīk Microsoft Teams darbība",
    "pbxPresence": "Tālruņa sistēmas klātbūtne",
    "msTeamsNoChangeState": "[No change]",
    "save": "SaglabātSave",
    "discard": "Atmest",
    "cancel": "Atcelt",
    "yes": "Jā",
    "areYouSure": "Vai esat pārliecināts?",
    "Available": "Pieejams",
    "Away": "Redzams kā prombūtnē",
    "BeRightBack": "Tulīt atgriezīšos",
    "Busy": "Aizņemts",
    "DoNotDisturb": "Netraucēt",
    "InACall": "Zvanā",
    "InAConferenceCall": "Konferences zvanā",
    "Inactive": "Neaktīvs",
    "InAMeeting": "Sanāksmē",
    "Offline": "Nestrādā",
    "OffWork": "Parādās bezsaistē",
    "OutOfOffice": "Nav birojā",
    "PresenceUnknown": "Klātbūtne nav zināma",
    "Presenting": "Prezentēšana",
    "UrgentInterruptionsOnly": "Tikai steidzami pārtraukumi",
    "resetToDefault": "Atiestatīt uz noklusējuma vērtību",
    "resetAllUserPresenceMappingsToDefault": "Atiestatīt visus lietotāja klātbūtnes kartējumus uz noklusējuma iestatījumiem",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "Tādējādi visiem lietotājiem tiks dzēsti klātbūtnes kartējumu ignorēšanas gadījumi.",
    "adminOnly": "Pieejams tikai administratoram",
    "default": "noklusējuma"
  },
  nb: {
    "userPresenceMappingsEnabled": "Organisasjonsadministratoren kan deaktivere redigering av tilstedeværelsestilordninger",
    "userPresenceMappingsDisabled": "Din organisasjonsadministrator har deaktivert redigering av tilstedeværelsesmapperinger.",
    "mappingSettings": "Tilordninger for tilstedeværelse",
    "defaultMappingSettings": "Standard tilstedeværelsestilordninger",
    "msTeamsManualPresence": "Microsoft Teams manuell tilstedeværelse",
    "msTeamsAutoPresence": "Automatiske tilstedeværelser for Microsoft Teams",
    "msTeamsAutoPresenceHint": "Microsoft Teams angir disse tilstedeværelsene basert på appbruk. Bare kartlegg disse hvis de er komfortable med Microsoft Teams-atferd",
    "pbxPresence": "Tilstedeværelse av telefonsystem",
    "msTeamsNoChangeState": "[No change]",
    "save": "Lagre",
    "discard": "Forkast",
    "cancel": "Avbryt",
    "yes": "Ja",
    "areYouSure": "Er du sikker?",
    "Available": "Tilgjengelig",
    "Away": "Vis som borte",
    "BeRightBack": "Kommer straks tilbake",
    "Busy": "Opptatt",
    "DoNotDisturb": "Ikke forstyrr",
    "InACall": "I en samtale",
    "InAConferenceCall": "I en telefonkonferanse",
    "Inactive": "Inaktiv",
    "InAMeeting": "I et møte",
    "Offline": "Ikke på jobb",
    "OffWork": "Vis som frakoblet",
    "OutOfOffice": "Fraværende",
    "PresenceUnknown": "Tilstedeværelse ukjent",
    "Presenting": "Presentere",
    "UrgentInterruptionsOnly": "Bare akutte avbrudd",
    "resetToDefault": "Tilbakestill til standard",
    "resetAllUserPresenceMappingsToDefault": "Tilbakestill alle tilordninger for brukertilstedeværelse til standard",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "Dette sletter overstyringer av tilstedeværelsestilordning for alle brukere.",
    "adminOnly": "Tilgjengelig kun for administrator",
    "default": "standard"
  },
  et: {
    "userPresenceMappingsEnabled": "Teie organisatsioon administraator saab esindatuse kaardistuste muutmise funktsiooni desaktiveerida",
    "userPresenceMappingsDisabled": "Teie organisatsiooni administraator on keelanud kohaloleku kaardistamise muutmise.",
    "mappingSettings": "Esindatuse kaardistused",
    "defaultMappingSettings": "Esindatuse vaikekaardistused",
    "msTeamsManualPresence": "Microsoft Teams manuaalsed kohalolekud",
    "msTeamsAutoPresence": "Microsoft Teams automaatsed kohalolekud",
    "msTeamsAutoPresenceHint": "Microsoft Teams määrab need kohalolekud rakenduse kasutamise järgi. Vastendage need ainult juhul, kui olete Microsoft Teams toimimisega rahul",
    "pbxPresence": "Telefonisüsteemi olemasolu",
    "msTeamsNoChangeState": "[No change]",
    "save": "Salvesta",
    "discard": "Hülga",
    "cancel": "Tühista",
    "yes": "Jah",
    "areYouSure": "Kas olete kindel?",
    "Available": "Saadaval",
    "Away": "Kuva olek Eemal",
    "BeRightBack": "Kohe tagasi",
    "Busy": "Hõivatud",
    "DoNotDisturb": "Mitte segada",
    "InACall": "Telefonil",
    "InAConferenceCall": "Konverentskõnes",
    "Inactive": "Passiivne",
    "InAMeeting": "Koosolekul",
    "Offline": "Praegu ei tööta",
    "OffWork": "Kuva olek Võrgust väljas",
    "OutOfOffice": "Kontorist väljas",
    "PresenceUnknown": "Kohalolek teadmata",
    "Presenting": "Esitleb",
    "UrgentInterruptionsOnly": "Segada vaid pakiliste küsimustega",
    "resetToDefault": "Lähtesta vaikeseadetele",
    "resetAllUserPresenceMappingsToDefault": "Lähtesta kõik esindatuse kaardistused vaikeseadetele",
    "resetAllUserPresenceMappingsToDefaultConfirmation": "See kustutab esindatuse kaardistuste tühistamised kõigi kasutajate jaoks.",
    "adminOnly": "Saadaval ainult administraatorile",
    "default": "vaikimisi"
  }
});
const grantPermission = new LocalizedStrings({
  en: {
    "header": "Microsoft Teams permissions",
    "getPermission": "For line state synchronisation, [grant permissions] (via login.microsoftonline.com).",
    "permissionsGranted": "Application permissions were granted successfully",
    "getUserPermission": "To search in Outlook contacts, you can [grant permissions] (via login.microsoftonline.com) for your user",
    "permissionsSearchGranted": "Permissions to search outlook contacts were granted successfully",
  },
  sv: {
    "header": "Microsoft Teams behörigheter",
    "getPermission": "För synkronisering av linjestatus, [grant permissions] (via login.microsoftonline.com).",
    "permissionsGranted": "Begärda behörigheter beviljades framgångsrikt",
    "getUserPermission": "För att söka i Outlook-kontakter kan du [bevilja behörigheter] (via login.microsoftonline.com) för din användare",
    "permissionsSearchGranted": "Behörigheter att söka Outlook-kontakter beviljades framgångsrikt",
  },
  nl: {
    "header": "Microsoft Teams-machtigingen",
    "getPermission": "Voor synchronisatie van lijnstatus, [machtigingen verlenen] (via login.microsoftonline.com).",
    "permissionsGranted": "Toepassingsrechten zijn met succes verleend",
    "getUserPermission": "Om in Outlook-contacten te zoeken, kunt u [machtigingen verlenen] (via login.microsoftonline.com) voor uw gebruiker",
    "permissionsSearchGranted": "Toestemmingen om Outlook-contacten te zoeken zijn met succes verleend",
  },
  de: {
    "header": "Microsoft Teams-Berechtigungen",
    "getPermission": "Für die Synchronisierung des Leitungsstatus, [Berechtigungen erteilen] (über login.microsoftonline.com).",
    "permissionsGranted": "Anwendungsberechtigungen wurden erfolgreich erteilt",
    "getUserPermission": "Um in Outlook-Kontakten zu suchen, können Sie Ihrem Benutzer [Berechtigungen erteilen] (über login.microsoftonline.com)",
    "permissionsSearchGranted": "Berechtigungen zum Durchsuchen von Outlook-Kontakten wurden erfolgreich erteilt",
  },
  fr: {
    "header": "Autorisations de Microsoft Teams",
    "getPermission": "Pour la synchronisation de l'état de la ligne, [accorder des autorisations] (via login.microsoftonline.com).",
    "permissionsGranted": "Les autorisations de l'application ont été accordées avec succès",
    "getUserPermission": "Pour effectuer une recherche dans les contacts Outlook, vous pouvez [accorder des autorisations] à votre utilisateur.",
    "permissionsSearchGranted": "Les autorisations de recherche dans les contacts Outlook ont été accordées avec succès",
  },
  da: {
    "header": "Microsoft Teams-tilladelser",
    "getPermission": "Til linjetilstandssynkronisering, [giv tilladelser] (via login.microsoftonline.com).",
    "permissionsGranted": "Applikationstilladelser blev givet",
    "getUserPermission": "For at søge i Outlook-kontakter kan du [give tilladelser] til din bruger",
    "permissionsSearchGranted": "Tilladelser til at søge i Outlook-kontakter blev givet med succes",
  },
  fi: {
    "header": "Microsoft Teamsin käyttöoikeudet",
    "getPermission": "Linjan tilan synkronointia varten, [myönnä käyttöoikeudet] (login.microsoftonline.com -sivun kautta).",
    "permissionsGranted": "Sovellusoikeudet myönnettiin onnistuneesti",
    "getUserPermission": "Jos haluat etsiä Outlookin yhteystiedoista, voit [myöntää käyttöoikeuksia] käyttäjällesi.",
    "permissionsSearchGranted": "Outlookin yhteystietojen hakuoikeudet myönnettiin onnistuneesti.",
  },
  es: {
    "header": "Permisos de Microsoft Teams",
    "getPermission": "Para la sincronización del estado de la línea, [otorgar permisos] (a través de login.microsoftonline.com).",
    "permissionsGranted": "Los permisos de la aplicación se otorgaron con éxito",
    "getUserPermission": "Para buscar en los contactos de Outlook, puede [otorgar permisos] para su usuario",
    "permissionsSearchGranted": "Los permisos para buscar contactos de Outlook se otorgaron con éxito",
  },
  it: {
    "header": "Autorizzazioni di Microsoft Teams",
    "getPermission": "Per la sincronizzazione dello stato della linea, [concedere le autorizzazioni] (tramite login.microsoftonline.com).",
    "permissionsGranted": "Autorizzazioni all'applicazione concesse correttamente",
    "getUserPermission": "Per cercare i contatti di Outlook, è necessario [concedere le autorizzazioni] all'utente",
    "permissionsSearchGranted": "Autorizzazioni a cercare contatti di Outlook concesse correttamente",
  },
  ja: {
    "header": "Microsoft Teamsアクセス許可",
    "getPermission": "回線状態の同期の場合は、[grant permissions] (via login.microsoftonline.com経由）の参照。",
    "permissionsGranted": "アプリケーション権限が正常に付与されました",
    "getUserPermission": "Outlookの連絡先を検索するには、ユーザーに[権限を付与]します",
    "permissionsSearchGranted": "Outlook連絡先を検索する権限が正常に付与されました",
  },
  lt: {
    "header": "„Microsoft Teams“ leidimai",
    "getPermission": "Turėdami administratoriaus paskyrą, galite [suteikti leidimus] visiems kitiems vartotojams, kurie neturi administratoriaus teisių",
    "permissionsGranted": "Programos leidimai suteikti sėkmingai",
    "getUserPermission": "Norėdami ieškoti „Outlook“ kontaktų, galite [suteikti leidimus] savo vartotojui",
    "permissionsSearchGranted": "Leidimas ieškoti „Outlook“ kontaktų suteiktas sėkmingai",
  },
  lv: {
    "header": "Microsoft Teams atļaujas",
    "getPermission": "Līnijas stāvokļa sinhronizācijai [piešķirt atļaujas] (izmantojot login.microsoftonline.com).",
    "permissionsGranted": "Lietojumprogrammas atļaujas tika veiksmīgi piešķirtas",
    "getUserPermission": "Lai meklētu Outlook kontaktpersonās, savam lietotājam varat [piešķirt atļaujas]",
    "permissionsSearchGranted": "Atļaujas meklēt Outlook kontaktpersonas tika veiksmīgi piešķirtas",
  },
  nb: {
    "header": "Microsoft Teams-tillatelser",
    "getPermission": "For synkronisering av linjestatus, [gi tillatelser] (via login.microsoftonline.com).",
    "permissionsGranted": "Applikasjonstillatelser ble gitt",
    "getUserPermission": "Hvis du vil søke i Outlook-kontakter, kan du [gi tillatelser] for brukeren",
    "permissionsSearchGranted": "Tillatelser til å søke i Outlook-kontakter ble gitt",
  },
  et: {
    "header": "Microsoft Teamsi õigused",
    "getPermission": "Liini oleku sünkroonimiseks [andke õigused] (login.microsoftonline.com kaudu).",
    "permissionsGranted": "Rakenduse load on edukalt antud",
    "getUserPermission": "Outlooki kontaktidest otsimiseks saate oma kasutajale [õigusi anda]",
    "permissionsSearchGranted": "Outlooki kontaktide otsimise õigused on edukalt antud",
  }
});
const authentication = new LocalizedStrings({
  en: {
    "logout": "Log out",
    "loggedInUser": "You are currently logged in as ",
    "version": "Version"
  },
  sv: {
    "logout": "Logga ut",
    "loggedInUser": "Du är för närvarande inloggad som ",
    "version": "Version"
  },
  nl: {
    "logout": "Uitloggen",
    "loggedInUser": "U bent momenteel aangemeld als ",
    "version": "Versie"
  },
  de: {
    "logout": "Ausloggen",
    "loggedInUser": "Sie sind derzeit als angemeldet ",
    "version": "Version"
  },
  fr: {
    "logout": "Déconnexion",
    "loggedInUser": "Vous êtes actuellement connecté en tant que ",
    "version": "Version"
  },
  da: {
    "logout": "Log ud",
    "loggedInUser": "Du er i øjeblikket logget ind som ",
    "version": "Version"
  },
  fi: {
    "logout": "Ulos/Sisään",
    "loggedInUser": "Olet tällä hetkellä kirjautuneena sisään nimellä ",
    "version": "Versio"
  },
  es: {
    "logout": "Cerrar sesión",
    "loggedInUser": "Está conectado actualmente como ",
    "version": "Versión"
  },
  it: {
    "logout": "Disconnetti",
    "loggedInUser": "L'utente è attualmente connesso come ",
    "version": "Versione"
  },
  ja: {
    "logout": "ログアウト",
    "loggedInUser": "現在ログインしています ",
    "version": "バージョン"
  },
  lt: {
    "logout": "Atsijungti",
    "loggedInUser": "Šiuo metu esate prisijungę kaip ",
    "version": "[Version]"
  },
  lv: {
    "logout": "Atteikties",
    "loggedInUser": "Jūs pašlaik esat pieteicies kā ",
    "version": "Versija"
  },
  nb: {
    "logout": "Logg av",
    "loggedInUser": "Du er for øyeblikket logget på som ",
    "version": "Versjon"
  },
  et: {
    "logout": "Logi välja",
    "loggedInUser": "Olete sisse logitud kasutajana ",
    "version": "Versioon"
  }
});
const callRecording = new LocalizedStrings({
  en: {
    "recordCall": "Start call recording",
    "recordCallError1": "Can't process the operation. The are no active calls",
    "recordCallError2": "Can't process the operation. Please specify the call you want to do an action for",
    "recordCallError3": "Can't process the operation. Selected call is not active",
    "recordCallError4": "Can't process the operation. Probably this action is not supported",
    "record": "Record",
    "stop": "Stop",
    "pause": "Pause",
    "resume": "Resume",
    "noCalls": "No active calls detected. If you have one, press \"Refresh\" button.",
    "callsNoteMessage": "Note: Only calls recorded by recording service shown here.",
    "noRecordingForThisCall": "This call is not being recorded.",
  },
  sv: {
    "recordCall": "Starta samtalsinspelning",
    "recordCallError1": "Det går inte att bearbeta åtgärden. Det är inga aktiva samtal",
    "recordCallError2": "Det går inte att bearbeta åtgärden. Ange samtalet du vill göra en åtgärd för",
    "recordCallError3": "Det går inte att bearbeta åtgärden. Det valda samtalet är inte aktivt",
    "recordCallError4": "Det går inte att bearbeta åtgärden. Förmodligen stöds inte denna åtgärd",
    "record": "Spela in",
    "stop": "Sluta",
    "pause": "Paus",
    "resume": "Återuppta",
    "noCalls": "Inga pågående samtal. Om du har ett pågående samtal, tryck på \"Ladda om\" knappen.",
    "callsNoteMessage": "Obs. Endast samtal som spelas in med inspelningstjänst visas här.",
    "noRecordingForThisCall": "Detta samtal spelas inte in.",
  },
  nl: {
    "recordCall": "Start oproepopname",
    "recordCallError1": "Kan de bewerking niet verwerken. Er zijn geen actieve oproepen",
    "recordCallError2": "Kan de bewerking niet verwerken. Specificeer de oproep waarvoor u een actie wilt uitvoeren",
    "recordCallError3": "Kan de bewerking niet verwerken. Het geselecteerde gesprek is niet actief",
    "recordCallError4": "Kan de bewerking niet verwerken. Waarschijnlijk wordt deze actie niet ondersteund",
    "record": "Vermelding",
    "stop": "Hou op",
    "pause": "Pauze",
    "resume": "Hervat",
    "noCalls": "Geen actieve oproepen gevonden. Als u er een hebt, drukt u op de knop \"vernieuwen\"",
    "callsNoteMessage": "Opmerking: Alleen oproepen die zijn opgenomen door de opnameservice die hier worden weergegeven.",
    "noRecordingForThisCall": "Dit gesprek wordt niet opgenomen.",
  },
  de: {
    "recordCall": "Starten Sie die Anrufaufzeichnung",
    "recordCallError1": "Die Operation kann nicht verarbeitet werden. Das sind keine aktiven Anrufe",
    "recordCallError2": "Die Operation kann nicht verarbeitet werden. Bitte geben Sie den Anruf an, für den Sie eine Aktion ausführen möchten",
    "recordCallError3": "Die Operation kann nicht verarbeitet werden. Der ausgewählte Anruf ist nicht aktiv",
    "recordCallError4": "Die Operation kann nicht verarbeitet werden. Wahrscheinlich wird diese Aktion nicht unterstützt",
    "record": "Aufzeichnung",
    "stop": "Halt",
    "pause": "Pause",
    "resume": "Fortsetzen",
    "noCalls": "Es wurden keine aktiven Anrufe gefunden. Wenn Sie eine haben, dr?cken Sie die Taste \"Aktualisieren\".",
    "callsNoteMessage": "Hinweis: Nur Anrufe, die vom Aufzeichnungsdienst aufgezeichnet werden, werden hier angezeigt.",
    "noRecordingForThisCall": "Dieser Aufruf wird nicht aufgezeichnet.",
  },
  fr: {
    "recordCall": "[Start call recording]",
    "recordCallError1": "[Can't process the operation. The are no active calls]",
    "recordCallError2": "[Can't process the operation. Please specify the call you want to do an action for]",
    "recordCallError3": "[Can't process the operation. Selected call is not active]",
    "recordCallError4": "[Can't process the operation. Probably this action is not supported]",
    "record": "[Record]",
    "stop": "[Stop]",
    "pause": "[Pause]",
    "resume": "[Resume]",
    "noCalls": "[No active calls detected. If you have one, press \"Refresh\" button.]",
    "callsNoteMessage": "[Note: Only calls recorded by recording service shown here.]",
    "noRecordingForThisCall": "[This call is not being recorded.]",
  },
  da: {
    "recordCall": "[Start call recording]",
    "recordCallError1": "[Can't process the operation. The are no active calls]",
    "recordCallError2": "[Can't process the operation. Please specify the call you want to do an action for]",
    "recordCallError3": "[Can't process the operation. Selected call is not active]",
    "recordCallError4": "[Can't process the operation. Probably this action is not supported]",
    "record": "[Record]",
    "stop": "[Stop]",
    "pause": "[Pause]",
    "resume": "[Resume]",
    "noCalls": "[No active calls detected. If you have one, press \"Refresh\" button.]",
    "callsNoteMessage": "[Note: Only calls recorded by recording service shown here.]",
    "noRecordingForThisCall": "[This call is not being recorded.]",
  },
  fi: {
    "recordCall": "[Start call recording]",
    "recordCallError1": "[Can't process the operation. The are no active calls]",
    "recordCallError2": "[Can't process the operation. Please specify the call you want to do an action for]",
    "recordCallError3": "[Can't process the operation. Selected call is not active]",
    "recordCallError4": "[Can't process the operation. Probably this action is not supported]",
    "record": "[Record]",
    "stop": "[Stop]",
    "pause": "[Pause]",
    "resume": "[Resume]",
    "noCalls": "[No active calls detected. If you have one, press \"Refresh\" button.]",
    "callsNoteMessage": "[Note: Only calls recorded by recording service shown here.]",
    "noRecordingForThisCall": "[This call is not being recorded.]",
  },
  es: {
    "recordCall": "[Start call recording]",
    "recordCallError1": "[Can't process the operation. The are no active calls]",
    "recordCallError2": "[Can't process the operation. Please specify the call you want to do an action for]",
    "recordCallError3": "[Can't process the operation. Selected call is not active]",
    "recordCallError4": "[Can't process the operation. Probably this action is not supported]",
    "record": "[Record]",
    "stop": "[Stop]",
    "pause": "[Pause]",
    "resume": "[Resume]",
    "noCalls": "[No active calls detected. If you have one, press \"Refresh\" button.]",
    "callsNoteMessage": "[Note: Only calls recorded by recording service shown here.]",
    "noRecordingForThisCall": "[This call is not being recorded.]",
  },
  it: {
    "recordCall": "[Start call recording]",
    "recordCallError1": "[Can't process the operation. The are no active calls]",
    "recordCallError2": "[Can't process the operation. Please specify the call you want to do an action for]",
    "recordCallError3": "[Can't process the operation. Selected call is not active]",
    "recordCallError4": "[Can't process the operation. Probably this action is not supported]",
    "record": "[Record]",
    "stop": "[Stop]",
    "pause": "[Pause]",
    "resume": "[Resume]",
    "noCalls": "[No active calls detected. If you have one, press \"Refresh\" button.]",
    "callsNoteMessage": "[Note: Only calls recorded by recording service shown here.]",
    "noRecordingForThisCall": "[This call is not being recorded.]",
  },
  ja: {
    "recordCall": "[Start call recording]",
    "recordCallError1": "[Can't process the operation. The are no active calls]",
    "recordCallError2": "[Can't process the operation. Please specify the call you want to do an action for]",
    "recordCallError3": "[Can't process the operation. Selected call is not active]",
    "recordCallError4": "[Can't process the operation. Probably this action is not supported]",
    "record": "[Record]",
    "stop": "[Stop]",
    "pause": "[Pause]",
    "resume": "[Resume]",
    "noCalls": "[No active calls detected. If you have one, press \"Refresh\" button.]",
    "callsNoteMessage": "[Note: Only calls recorded by recording service shown here.]",
    "noRecordingForThisCall": "[This call is not being recorded.]",
  },
  lt: {
    "recordCall": "[Start call recording]",
    "recordCallError1": "[Can't process the operation. The are no active calls]",
    "recordCallError2": "[Can't process the operation. Please specify the call you want to do an action for]",
    "recordCallError3": "[Can't process the operation. Selected call is not active]",
    "recordCallError4": "[Can't process the operation. Probably this action is not supported]",
    "record": "[Record]",
    "stop": "[Stop]",
    "pause": "[Pause]",
    "resume": "[Resume]",
    "noCalls": "[No active calls detected. If you have one, press \"Refresh\" button.]",
    "callsNoteMessage": "[Note: Only calls recorded by recording service shown here.]",
    "noRecordingForThisCall": "[This call is not being recorded.]",
  },
  lv: {
    "recordCall": "[Start call recording]",
    "recordCallError1": "[Can't process the operation. The are no active calls]",
    "recordCallError2": "[Can't process the operation. Please specify the call you want to do an action for]",
    "recordCallError3": "[Can't process the operation. Selected call is not active]",
    "recordCallError4": "[Can't process the operation. Probably this action is not supported]",
    "record": "[Record]",
    "stop": "[Stop]",
    "pause": "[Pause]",
    "resume": "[Resume]",
    "noCalls": "[No active calls detected. If you have one, press \"Refresh\" button.]",
    "callsNoteMessage": "[Note: Only calls recorded by recording service shown here.]",
    "noRecordingForThisCall": "[This call is not being recorded.]",
  },
  nb: {
    "recordCall": "[Start call recording]",
    "recordCallError1": "[Can't process the operation. The are no active calls]",
    "recordCallError2": "[Can't process the operation. Please specify the call you want to do an action for]",
    "recordCallError3": "[Can't process the operation. Selected call is not active]",
    "recordCallError4": "[Can't process the operation. Probably this action is not supported]",
    "record": "[Record]",
    "stop": "[Stop]",
    "pause": "[Pause]",
    "resume": "[Resume]",
    "noCalls": "[No active calls detected. If you have one, press \"Refresh\" button.]",
    "callsNoteMessage": "[Note: Only calls recorded by recording service shown here.]",
    "noRecordingForThisCall": "[This call is not being recorded.]",
  },
  et: {
    "recordCall": "[Start call recording]",
    "recordCallError1": "[Can't process the operation. The are no active calls]",
    "recordCallError2": "[Can't process the operation. Please specify the call you want to do an action for]",
    "recordCallError3": "[Can't process the operation. Selected call is not active]",
    "recordCallError4": "[Can't process the operation. Probably this action is not supported]",
    "record": "[Record]",
    "stop": "[Stop]",
    "pause": "[Pause]",
    "resume": "[Resume]",
    "noCalls": "[No active calls detected. If you have one, press \"Refresh\" button.]",
    "callsNoteMessage": "[Note: Only calls recorded by recording service shown here.]",
    "noRecordingForThisCall": "[This call is not being recorded.]",
  }
}); 
const callTransfer = new LocalizedStrings({
  en: {
    "MOBILE": "Mobile",
    "TABLET": "Tablet",
    "SOFTPHONE": "Softphone",
    "DESKPHONE": "Deskphone",
    "PBX": "PBX",
    "DECT": "Dect",
    "THIRD_PARTY": "3rd party",
    "activeCalls": "Select the device to transfer the call",
  },
  sv: {
    "MOBILE": "Mobil",
    "TABLET": "Läsplatta",
    "SOFTPHONE": "Softphone",
    "DESKPHONE": "Skrivbordstelefon",
    "PBX": "PBX",
    "DECT": "Dect",
    "THIRD_PARTY": "3: e part",
    "activeCalls": "Välj enheten för att överföra samtalet",
  },
  nl: {
    "MOBILE": "Mobiel",
    "TABLET": "Tablet",
    "SOFTPHONE": "Softphone",
    "DESKPHONE": "Bureau telefoon",
    "PBX": "PBX",
    "DECT": "Dect",
    "THIRD_PARTY": "3e partij",
    "activeCalls": "Selecteer het apparaat om het gesprek door te verbinden",
  },
  de: {
    "MOBILE": "Mobil",
    "TABLET": "Tablette",
    "SOFTPHONE": "Softphone",
    "DESKPHONE": "Tischtelefon",
    "PBX": "PBX",
    "DECT": "Dect",
    "THIRD_PARTY": "Drittanbieter",
    "activeCalls": "Wählen Sie das Gerät aus, um den Anruf weiterzuleiten",
  },
  fr: {
    "MOBILE": "[Mobile]",
    "TABLET": "[Tablet]",
    "SOFTPHONE": "[Softphone]",
    "DESKPHONE": "[Deskphone]",
    "PBX": "[PBX]",
    "DECT": "[Dect]",
    "THIRD_PARTY": "[3rd party]",
    "activeCalls": "[Select the device to transfer the call]",
  },
  da: {
    "MOBILE": "[Mobile]",
    "TABLET": "[Tablet]",
    "SOFTPHONE": "[Softphone]",
    "DESKPHONE": "[Deskphone]",
    "PBX": "[PBX]",
    "DECT": "[Dect]",
    "THIRD_PARTY": "[3rd party]",
    "activeCalls": "[Select the device to transfer the call]",
  },
  fi: {
    "MOBILE": "[Mobile]",
    "TABLET": "[Tablet]",
    "SOFTPHONE": "[Softphone]",
    "DESKPHONE": "[Deskphone]",
    "PBX": "[PBX]",
    "DECT": "[Dect]",
    "THIRD_PARTY": "[3rd party]",
    "activeCalls": "[Select the device to transfer the call]",
  },
  es: {
    "MOBILE": "[Mobile]",
    "TABLET": "[Tablet]",
    "SOFTPHONE": "[Softphone]",
    "DESKPHONE": "[Deskphone]",
    "PBX": "[PBX]",
    "DECT": "[Dect]",
    "THIRD_PARTY": "[3rd party]",
    "activeCalls": "[Select the device to transfer the call]",
  },
  it: {
    "MOBILE": "[Mobile]",
    "TABLET": "[Tablet]",
    "SOFTPHONE": "[Softphone]",
    "DESKPHONE": "[Deskphone]",
    "PBX": "[PBX]",
    "DECT": "[Dect]",
    "THIRD_PARTY": "[3rd party]",
    "activeCalls": "[Select the device to transfer the call]",
  },
  ja: {
    "MOBILE": "[Mobile]",
    "TABLET": "[Tablet]",
    "SOFTPHONE": "[Softphone]",
    "DESKPHONE": "[Deskphone]",
    "PBX": "[PBX]",
    "DECT": "[Dect]",
    "THIRD_PARTY": "[3rd party]",
    "activeCalls": "[Select the device to transfer the call]",
  },
  lt: {
    "MOBILE": "[Mobile]",
    "TABLET": "[Tablet]",
    "SOFTPHONE": "[Softphone]",
    "DESKPHONE": "[Deskphone]",
    "PBX": "[PBX]",
    "DECT": "[Dect]",
    "THIRD_PARTY": "[3rd party]",
    "activeCalls": "[Select the device to transfer the call]",
  },
  lv: {
    "MOBILE": "[Mobile]",
    "TABLET": "[Tablet]",
    "SOFTPHONE": "[Softphone]",
    "DESKPHONE": "[Deskphone]",
    "PBX": "[PBX]",
    "DECT": "[Dect]",
    "THIRD_PARTY": "[3rd party]",
    "activeCalls": "[Select the device to transfer the call]",
  },
  nb: {
    "MOBILE": "[Mobile]",
    "TABLET": "[Tablet]",
    "SOFTPHONE": "[Softphone]",
    "DESKPHONE": "[Deskphone]",
    "PBX": "[PBX]",
    "DECT": "[Dect]",
    "THIRD_PARTY": "[3rd party]",
    "activeCalls": "[Select the device to transfer the call]",
  },
  et: {
    "MOBILE": "[Mobile]",
    "TABLET": "[Tablet]",
    "SOFTPHONE": "[Softphone]",
    "DESKPHONE": "[Deskphone]",
    "PBX": "[PBX]",
    "DECT": "[Dect]",
    "THIRD_PARTY": "[3rd party]",
    "activeCalls": "[Select the device to transfer the call]",
  }
}); 
const auth = new LocalizedStrings({
  en: {
    "header": "Login page",
    "loading": "Loading...",
    "failedToLoginToSmg": "Failed to login to the platform",
  },
  sv: {
    "header": "Login page",
    "loading": "Loading...",
    "failedToLoginToSmg": "Det gick inte att logga in på plattformen",
  },
  nl: {
    "header": "Login page",
    "loading": "Loading...",
    "failedToLoginToSmg": "Inloggen op platform mislukt",
  },
  de: {
    "header": "Login page",
    "loading": "Loading...",
    "failedToLoginToSmg": "Fehler bei der Anmeldung an der Plattform",
  },
  fr: {
    "header": "Page de connexion",
    "loading": "Chargement en cours...",
    "failedToLoginToSmg": "Échec de la connexion à la plateforme",
  },
  da: {
    "header": "Login side",
    "loading": "Indlæser...",
    "failedToLoginToSmg": "Kunne ikke logge ind på platformen",
  },
  fi: {
    "header": "Kirjautumissivu",
    "loading": "Lataa...",
    "failedToLoginToSmg": "Kirjautuminen alustalle epäonnistui",
  },
  es: {
    "header": "Página de inicio de sesión",
    "loading": "Cargando...",
    "failedToLoginToSmg": "Error al iniciar sesión en la plataforma",
  },
  it: {
    "header": "Pagina di accesso",
    "loading": "Caricamento...",
    "failedToLoginToSmg": "impossibile accedere alla piattaforma",
  },
  ja: {
    "header": "ログイン ページ",
    "loading": "読み込み中・・・",
    "failedToLoginToSmg": "プラットフォームへのログインに失敗しました",
  },
  lt: {
    "header": "Prisijungimo puslapis",
    "loading": "Įkeliama...",
    "failedToLoginToSmg": "Accesso alla piattaforma fallito",
  },
  lv: {
    "header": "Pieteikšanās lapa",
    "loading": "Notiek ielāde...",
    "failedToLoginToSmg": "Neizdevās pieteikties platformā",
  },
  nb: {
    "header": "Påloggingsside",
    "loading": "Laster...",
    "failedToLoginToSmg": "Kunne ikke logge inn på plattformen",
  },
  et: {
    "header": "Sisselogimisleht",
    "loading": "Laadimine...",
    "failedToLoginToSmg": "Platvormile sisse logimine ebaõnnestus",
  }
});

const voicemailSettings = new LocalizedStrings({
  en: {
    "widgetTitle": "Voicemail notification",
    "Email": "Email",
    "SMS": "SMS",
    "attachMessageToEmail": "Attach message in e-mail",
  },
  sv: {
    "widgetTitle": "Avisering om röstmeddelande",
    "Email": "E-post",
    "SMS": "SMS",
    "attachMessageToEmail": "Bifoga meddelande i e-post",
  },
  nl: {
    "widgetTitle": "Voicemail melding",
    "Email": "Email",
    "SMS": "SMS",
    "attachMessageToEmail": "Voeg bericht toe aan e-mail",
  },
  de: {
    "widgetTitle": "Voicemail-Benachrichtigung",
    "Email": "Email",
    "SMS": "SMS",
    "attachMessageToEmail": "Nachricht in E-Mail anhängen",
  },
  fr: {
    "widgetTitle": "Notification de la messagerie vocale",
    "Email": "Email",
    "SMS": "SMS",
    "attachMessageToEmail": "Joindre un message dans un e-mail",
  },
  da: {
    "widgetTitle": "Voicemail notifikation",
    "Email": "Email",
    "SMS": "SMS",
    "attachMessageToEmail": "Vedhæft besked i e-mail",
  },
  fi: {
    "widgetTitle": "Vastaajan ilmoitukset",
    "Email": "Sähköpostiin",
    "SMS": "SMS",
    "attachMessageToEmail": "Liitä viesti sähköpostiin",
  },
  es: {
    "widgetTitle": "Notificación de correo de voz",
    "Email": "Email",
    "SMS": "SMS",
    "attachMessageToEmail": "Adjuntar mensaje en el correo electrónico",
  },
  it: {
    "widgetTitle": "Notifica casella vocale",
    "Email": "[Email]",
    "SMS": "[SMS]",
    "attachMessageToEmail": "Allega il messaggio all'e-mail",
  },
  ja: {
    "widgetTitle": "ボイスメール通知",
    "Email": "Eメール",
    "SMS": "ショートメッセージサービス",
    "attachMessageToEmail": "メールにメッセージを添付",
  },
  lt: {
    "widgetTitle": "[Voicemail notification]",
    "Email": "[Email]",
    "SMS": "[SMS]",
    "attachMessageToEmail": "[Attach message in e-mail]",
  },
  lv: {
    "widgetTitle": "Balss pasta paziņojums",
    "Email": "E-pasts",
    "SMS": "SMS",
    "attachMessageToEmail": "Pievienojiet ziņojumu e-pastā",
  },
  nb: {
    "widgetTitle": "Varsling om telefonsvarer",
    "Email": "E-post",
    "SMS": "SMS",
    "attachMessageToEmail": "Legg ved melding i e-post",
  },
  et: {
    "widgetTitle": "Kõneposti teade",
    "Email": "Meil",
    "SMS": "SMS",
    "attachMessageToEmail": "Manustage sõnum meilile",
  }
});

const featureFlags = new LocalizedStrings({
  en: {
    "widgetTitle": "Synchronisation settings",
    "linestate": "Linestate",
    "presenceMapping": "Presence",
    "coreToTeams": "Telephony to Teams",
    "teamsToCore": "Teams to Telephony",
    "configuration": "Configuration",
    "userPresenceMappings": "Allow end-users to override presence mappings",
  },
  sv: {
    "widgetTitle": "Synkroniseringsinställningar",
    "linestate": "Linjestatus",
    "presenceMapping": "Närvarostatus",
    "coreToTeams": "Telefoni till Teams",
    "teamsToCore": "Teams till telefoni",
    "configuration": "Konfiguration",
    "userPresenceMappings": "Tillåt användare att överrida aktivitetsmappningar",
  },
  nl: {
    "widgetTitle": "Synchronisatie instellingen",
    "linestate": "Linestate",
    "presenceMapping": "Aanwezigheid",
    "coreToTeams": "Telefonie naar Teams",
    "teamsToCore": "Teams naar Telefonie",
    "configuration": "Configuratie",
    "userPresenceMappings": "Laat eindgebruikers toe om aanwezigheidsmappings te overschrijven",
  },
  de: {
    "widgetTitle": "Synchronisierungseinstellungen",
    "linestate": "Linestate",
    "presenceMapping": "Anwesenheitsstatus",
    "coreToTeams": "Telefonie zu Teams",
    "teamsToCore": "Teams zur Telefonie",
    "configuration": "Konfiguration",
    "userPresenceMappings": "Endbenutzern erlauben, Anwesenheitszuordnungen zu überschreiben",
  },
  fr: {
    "widgetTitle": "Paramètres de synchronisation",
    "linestate": "état de la ligne",
    "presenceMapping": "Présence",
    "coreToTeams": "Téléphonie vers Teams",
    "teamsToCore": "Teams vers Téléphonie",
    "configuration": "Configuration",
    "userPresenceMappings": "Autoriser les utilisateurs finaux à remplacer les mappages de présence",
  },
  da: {
    "widgetTitle": "Synkroniseringsindstillinger",
    "linestate": "Linjetilstand",
    "presenceMapping": "Tilstedeværelse",
    "coreToTeams": "Telefoni til Teams",
    "teamsToCore": "Teams til telefoni",
    "configuration": "Konfiguration",
    "userPresenceMappings": "Tillad slutbrugere at tilsidesætte tilstedeværelsestilknytninger",
  },
  fi: {
    "widgetTitle": "Synkronointiasetukset",
    "linestate": "Linestate",
    "presenceMapping": "Olotila",
    "coreToTeams": "Puhelinpalvelut joukkueille",
    "teamsToCore": "Joukkueet puhelimeen",
    "configuration": "Kokoonpano",
    "userPresenceMappings": "Salli loppukäyttäjien ohittaa läsnäolokäsitykset",
  },
  es: {
    "widgetTitle": "Configuración de sincronización",
    "linestate": "Estado de línea",
    "presenceMapping": "Presencia",
    "coreToTeams": "Telefonía a Equipos",
    "teamsToCore": "Equipos a Telefonía",
    "configuration": "Configuración",
    "userPresenceMappings": "Permitir que los usuarios finales anulen las asignaciones de presencia",
  },
  it: {
    "widgetTitle": "Impostazioni di sincronizzazione",
    "linestate": "Stato della linea",
    "presenceMapping": "Presenza",
    "coreToTeams": "Da telefonia a Teams",
    "teamsToCore": "Da Teams a telefonia",
    "configuration": "Configurazione",
    "userPresenceMappings": "Consenti agli utenti finali di sovrascrivere le mappature delle presenze",
  },
  ja: {
    "widgetTitle": "同期の設定",
    "linestate": "ラインエステート",
    "presenceMapping": "プレセンス",
    "coreToTeams": "チームへのテレフォニー",
    "teamsToCore": "テレフォニーへのチーム",
    "configuration": "設定",
    "userPresenceMappings": "エンドユーザによるプレゼンスマッピングの上書きが許可されます",
  },
  lt: {
    "widgetTitle": "Synchronisation settings",
    "linestate": "Linestate",
    "presenceMapping": "Presence",
    "coreToTeams": "Telephony to Teams",
    "teamsToCore": "Teams to Telephony",
  },
  lv: {
    "widgetTitle": "Sinhronizācijas iestatījumi",
    "linestate": "Linestate",
    "presenceMapping": "Klātbūtne",
    "coreToTeams": "Telefonija komandām",
    "teamsToCore": "Komandas telefonijai",
    "configuration": "Konfigurācija",
    "userPresenceMappings": "Ļaujiet galalietotājiem ignorēt klātbūtnes kartējumus",
  },
  nb: {
    "widgetTitle": "Synkroniseringsinnstillinger",
    "linestate": "Linjetilstand",
    "presenceMapping": "Tilstedeværelse",
    "coreToTeams": "Telefoni til Teams",
    "teamsToCore": "Teams til telefoni",
    "configuration": "Konfigurasjon",
    "userPresenceMappings": "Tillat sluttbrukere å overstyre tilstedeværelsestilordninger",
  },
  et: {
    "widgetTitle": "Sünkroonimise sätted",
    "linestate": "Linestate",
    "presenceMapping": "Olek",
    "coreToTeams": "Telephony Teamsi",
    "teamsToCore": "Teams Telephony`sse",
    "configuration": "Konfiguratsioon",
    "userPresenceMappings": "Luba lõppkasutajatel esindatuse kaardistusi tühistada",
  }
});

//THE MUST to add new resource in the collection
const listOfResources = [
  loginForm,
  tabPageSelector,
  acdAttendantGroups,
  supervisorGroups,
  sendSms,
  setCallerId,
  voiceMail,
  dashboard,
  settings,
  grantPermission,
  authentication,
  callRecording,
  callTransfer,
  auth,
  voicemailSettings,
  setRole,
  featureFlags
];
export {
  listOfResources,
  loginForm,
  tabPageSelector,
  acdAttendantGroups,
  supervisorGroups,
  sendSms,
  setCallerId,
  voiceMail,
  dashboard,
  settings,
  grantPermission,
  authentication,
  callRecording,
  callTransfer,
  auth,
  voicemailSettings,
  setRole,
  featureFlags
};
