import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';
import { Card, tokens } from '@fluentui/react-components';
import { Flex } from '@fluentui/react-migration-v0-v9';
import { isAuthenticatedSelector } from '../store/selectors/authentication-selectors';
import * as authActions from '../store/actions/authenticate-action';
import { msTeamsContextSelector } from '../store/selectors/common-selectors';
import * as settingsDataActions from '../store/actions/settings-data-action';
import { isAdminSelector, isPermissionsLoadedSelector } from '../store/selectors/settings-data-selectors';
import SupervisorGroups from '../components/org-page/supervisor-groups';
import PresenceActivityDefaultMapping from '../components/org-page/presence-activity-default-mapping';
import { Navigate } from 'react-router-dom';
import { Text } from '@fluentui/react-components';
import { settings } from '../i18n/all';
import * as storageHelper from '../helpers/storage-helper';
import IPageBaseState from '../models/pageBaseState';
import { isMobile, isTablet } from 'react-device-detect';
import FeatureFlagsComponent from '../components/org-page/feature-flags';

interface OrgPageProps {
  msTeamsContext: microsoftTeams.app.Context;
  isAuthenticated: boolean;
  isAdmin: boolean;
  actions: any;
  isPermissionsLoaded: boolean;
}

interface OrgPageState extends IPageBaseState {
  email: string | null;
  password: string | null;
  context: microsoftTeams.app.Context | null;
}

class OrgPage extends Component<OrgPageProps, OrgPageState> {
  constructor(props: OrgPageProps) {
    super(props);
    this.state = {
      email: null,
      password: null,
      context: props.msTeamsContext,
      isRedirecting: false,
    };

    if (isMobile || isTablet) {
      setInterval(
        (isRedirecting: boolean) => {
          const accessToken = storageHelper.getSmgAccessToken();
          if (!accessToken && this.props.isAuthenticated) {
            if (!isRedirecting) {
              window.location.assign(`${window.location.origin}/login?page=org-page`);
              this.setState({ ...this.state, isRedirecting: true });
            }
          }
        },
        1000,
        this.state.isRedirecting
      );
    }
  }

  componentDidUpdate() {
    if (this.props.isAuthenticated && !this.props.isPermissionsLoaded) {
      this.props.actions.loadPermissions();
    }
  }

  render() {
    return (
      <Flex column gap="gap.small" padding="padding.medium">
        {this.props.isAuthenticated && (
          <div style={{ width: '100%', minWidth: '100%' }}>
            {!!this.props.isAdmin && (
              <Card appearance="subtle" style={{ backgroundColor: tokens.colorNeutralBackground2 }}>
                <FeatureFlagsComponent />
              </Card>
            )}
            <Card appearance="subtle" style={{ marginTop: '10px', backgroundColor: tokens.colorNeutralBackground2 }}>
              <SupervisorGroups />
            </Card>
            {!!this.props.isAdmin && (
              <Card appearance="subtle" style={{ marginTop: '10px', backgroundColor: tokens.colorNeutralBackground2 }}>
                <PresenceActivityDefaultMapping />
              </Card>
            )}
            {this.props.isAdmin === false && (
              <Card appearance="subtle" style={{ marginTop: '10px', backgroundColor: tokens.colorNeutralBackground2 }}>
                <Text>{settings.adminOnly}</Text>
              </Card>
            )}
          </div>
        )}
        {this.props.isAuthenticated === false && <Navigate to={`/login?page=org-page`} />}
      </Flex>
    );
  }
}

// eslint-disable-next-line
const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
  isAdmin: isAdminSelector(state),
  isPermissionsLoaded: isPermissionsLoadedSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...authActions,
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgPage);
