import { Button, Dropdown, Text, Option, Subtitle2Stronger, Persona } from '@fluentui/react-components';
import type { OptionOnSelectData } from '@fluentui/react-components';
import {
  ArrowSync16Filled,
  PersonQuestionMark16Regular,
  Desktop16Regular,
  Keyboard16Regular,
  PhoneDesktop16Regular,
  PhoneLaptop16Regular,
  Phone16Regular,
} from '@fluentui/react-icons';
import { Flex } from '@fluentui/react-migration-v0-v9';
import * as microsoftTeams from '@microsoft/teams-js';
import { cloneDeep } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import formatAliasName from '../../helpers/alias-name-formatter';
import { setCallerId } from '../../i18n/all';
import CallerAlias from '../../models/caller-alias';
import * as userDataActions from '../../store/actions/user-data-action';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import { userAliasesSelector } from '../../store/selectors/user-data-selectors';

interface SetCallerIdProps {
  callerAliases: {
    availableAliases: CallerAlias[];
    currentAlias: CallerAlias;
  };
  msTeamsContext: microsoftTeams.app.Context | null;
  actions: any;
}

interface SetCallerIdPageState {}

export class SetCallerId extends React.Component<SetCallerIdProps, SetCallerIdPageState> {
  constructor(props: SetCallerIdProps) {
    super(props);
    this.props.actions.loadCallerAliases();
    this.props.actions.loadUserRolesInfo();

    this.onSelectHandle = this.onSelectHandle.bind(this);
  }

  get currentAliasNumber(): number {
    return this.props.callerAliases.availableAliases.findIndex((alias) => alias.active);
  }

  get aliasesPresentation(): string[] {
    return this.props.callerAliases?.availableAliases.map((a) => formatAliasName(a));
  }

  onSelectHandle = (event: any, data: OptionOnSelectData) => {
    if (data.optionValue?.length === -1) {
      return;
    }

    const tempAliases = cloneDeep(this.props.callerAliases.availableAliases);
    tempAliases.forEach((alias, number) => {
      if (formatAliasName(alias) === data.optionText) {
        alias.active = true;
      } else {
        alias.active = false;
      }
    });
    this.props.actions.updateCallerAliases(tempAliases);
  };

  render() {
    return (
      !!this.props.callerAliases.availableAliases && (
        <Flex column gap="gap.smaller">
          <Flex space="between" vAlign="end">
            <Subtitle2Stronger>{setCallerId.setYourCallerId}</Subtitle2Stronger>
            <Button size="medium" icon={<ArrowSync16Filled />} onClick={this.props.actions.loadCallerAliases} />
          </Flex>
          <Dropdown
            onOptionSelect={this.onSelectHandle}
            defaultValue={formatAliasName(this.props.callerAliases.currentAlias)}
          >
            {this.props.callerAliases?.availableAliases.map((c) => (
              <Option key={c.groupId} text={formatAliasName(c)} value={c.number ? c.number : formatAliasName(c)}>
                {formatAliasName(c)}
              </Option>
            ))}
          </Dropdown>
        </Flex>
      )
    );
  }
}

const mapStateToProps = (state: any) => ({
  callerAliases: userAliasesSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...userDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetCallerId);
