import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const communicationLogRecord = (state: any) => state.voicemail;

const voiceMailsSelector = createSelector(communicationLogRecord, (record = EMPTY_OBJECT) => {
  return {
    availableVoiceMails: record.availableVoiceMails,
    totalVoiceMailCount: record.totalVoiceMailCount,
  };
});

export { voiceMailsSelector };
