import React from 'react';
import {
  Table,
  Button,
  Text,
  Subtitle2Stronger,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
  TableCellLayout,
  Switch,
  tokens,
  TableHeaderCell,
} from '@fluentui/react-components';
import { ArrowSync16Filled, PeopleCommunity16Filled, PeopleCommunity16Regular } from '@fluentui/react-icons';
import { Flex } from '@fluentui/react-migration-v0-v9';
import * as microsoftTeams from '@microsoft/teams-js';

import * as userDataActions from '../../store/actions/user-data-action';
import { acdAttendantGroupsSelector } from '../../store/selectors/user-data-selectors';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import AcdAttendantGroup from '../../models/acd-attendant-group';
import RoleType from '../../models/role-type.enum';
import { acdAttendantGroups } from '../../i18n/all';

interface AcdAttendantGroupsProps {
  acdAttendantGroups: AcdAttendantGroup[];
  msTeamsContext: microsoftTeams.app.Context | null;
  actions: any;
}

interface AcdAttendantGroupsState {}

export class AcdAttendantGroups extends React.Component<AcdAttendantGroupsProps, AcdAttendantGroupsState> {
  constructor(props: AcdAttendantGroupsProps) {
    super(props);
    this.props.actions.loadAcdAttendantGroup(this.props.msTeamsContext?.user?.id);
  }

  handleRowClick(group: AcdAttendantGroup) {
    this.props.actions.loginUserToAcdAttendantGroup(group);
  }

  render() {
    const items = [
      { 
        content: acdAttendantGroups.name,
        key: 'name', 
        style: { fontWeight: 'bold' }
      },
      { 
        content: acdAttendantGroups.loggedInAgents,
        key: 'numberOfLoggedInAgents',
        style: { fontWeight: 'bold' }
      },
      { 
        content: acdAttendantGroups.isLoggedIn,
        key: 'isLoggedIn',
        style: { fontWeight: 'bold' }
      },
    ];
    const columns = [
      { columnKey: 'name', label: 'Name' },
      { columnKey: 'numberOfLoggedInAgents', label: 'Number of logged in agents' },
      { columnKey: 'isLoggedIn', label: 'Is LoggedIn' },
    ];
    const rowsPlain = this.props.acdAttendantGroups
      ?.filter((group) => group.roles.includes(RoleType.AGENT))
      ?.map((group) => {
        return {
          key: group.id,
          items: [
            { content: group.name, key: `${group.id}name`, truncateContent: true },
            {
              content: (
                <Flex gap="gap.small" vAlign="center" padding="padding.medium">
                  {group.numberOfLoggedInAgents > 0 && <PeopleCommunity16Filled />}
                  {group.numberOfLoggedInAgents <= 0 && <PeopleCommunity16Regular />}
                  <Text>{group.numberOfLoggedInAgents}</Text>
                </Flex>
              ),
              key: `${group.id}numberOfLoggedInAgents`,
            },
            {
              content: <Switch test-data-id="testok" checked={group.loggedIn} onChange={() => this.handleRowClick(group)}  />,
              key: `${group.id}isLoggedIn`,
            },
          ],
        };
      });
    return (
      <Flex column gap="gap.smaller">
        <Flex space="between" vAlign="end">
          <Subtitle2Stronger>{acdAttendantGroups.listOfGroups}</Subtitle2Stronger>
          <Button
            size="medium"
            icon={<ArrowSync16Filled />}
            onClick={() => {
              this.props.actions.loadAcdAttendantGroup(this.props.msTeamsContext?.user?.id);
            }}
          />
        </Flex>
        {!!rowsPlain?.length ? (
          <Table>
            <TableHeader>
              <TableRow style={{ borderBottomColor: tokens.colorNeutralBackground2Hover }}>
                {items.map((item) => (
                  <TableHeaderCell key={item.key}>
                    <TableCellLayout
                      truncate
                      style={{ fontWeight: tokens.fontWeightBold, fontSize: tokens.fontSizeBase200 }}
                    >
                      {item.content}
                    </TableCellLayout>
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {rowsPlain.map((items) => (
                <TableRow key={items.key} style={{ borderBottomColor: tokens.colorNeutralBackground2Hover }}>
                  {items.items.map((item) => (
                    <TableCell>
                      <TableCellLayout truncate style={{ overflow: 'none' }} key={item.key}>
                        {item.content}
                      </TableCellLayout>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table size="extra-small">
            <TableHeader>
              <TableRow style={{ borderBottomColor: tokens.colorNeutralBackground2Hover }}>
                {items.map((item) => (
                  <TableCell key={item.key}>
                    <TableCellLayout truncate style={item.style}>
                      {item.content}
                    </TableCellLayout>
                  </TableCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow style={{ borderBottomColor: tokens.colorNeutralBackground2Hover }}>
                <TableCell colSpan={3} id={'empty-key'} key={'empty-key'} style={{ border: 'none' }}>
                  <TableCellLayout truncate>{acdAttendantGroups.emptyPlaceholder}</TableCellLayout>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Flex>
    );
  }
}

const mapStateToProps = (state: any) => ({
  acdAttendantGroups: acdAttendantGroupsSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...userDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcdAttendantGroups);
