import {
  Button,
  Card,
  Text,
  tokens,
  MessageBar,
  MessageBarBody,
  Link,
} from '@fluentui/react-components';
import { Flex } from '@fluentui/react-migration-v0-v9';
import * as microsoftTeams from '@microsoft/teams-js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { dashboard, loginForm } from '../i18n/all';
import ErrorCode, { ErrorCodes } from '../models/error-code';
import * as authActions from '../store/actions/authenticate-action';
import { authErrorSelector, isAuthenticatedSelector } from '../store/selectors/authentication-selectors';
import { isLoadingSelector } from '../store/selectors/common-selectors';
import { setLoadingIndicator } from '../store/actions/common-action';
import MsGrantPermissions from '../components/settings/ms-grant-permissions';
import * as storageHelper from '../helpers/storage-helper';
import { Navigate } from 'react-router-dom';

interface LoginPageProps {
  isLoading: boolean;
  isAuthenticated: boolean;
  authError: ErrorCode;
  actions: any;
}

type FailureCode = 'FailedToOpenWindow' | 'CancelledByUser';

interface LoginState {
  pageToRedirect: string;
  failureReason?: FailureCode | ErrorCode;
  classes?: string;
}

class LoginPage extends Component<LoginPageProps, LoginState> {
  constructor(props: LoginPageProps) {
    super(props);

    this.state = {
      pageToRedirect: '/' + new URL(window.location.href).searchParams.get('page') ?? 'dashboard',
    };

    this.authenticate = this.authenticate.bind(this);
    this.getTranslation = this.getTranslation.bind(this);
    this.goToRedirectUrl = this.goToRedirectUrl.bind(this);
  }

  authenticate() {
    this.props.actions.setLoadingIndicator(true);
    const config: microsoftTeams.authentication.AuthenticatePopUpParameters = {
      url: `${window.location.origin}/auth-start`,
      height: 860,
      width: 550,
    };

    microsoftTeams.authentication.authenticate(config).then((result) => {
      console.log('authenticate successCallback', result);
      this.props.actions.setLoadingIndicator(false);
      this.goToRedirectUrl();
    })
    .catch(async (reason) => {
      let ErrorResponse = reason.toString().split(': ');
      console.log('failureCallback failureCallback', ErrorResponse);
      this.props.actions.setLoadingIndicator(false);
      if (ErrorCodes.credsAlreadyInUse === ErrorResponse[1]) {
        await this.props.actions.removeSmgAuthentication();
      }

      this.setState({
        failureReason: ErrorResponse[1],
      });
    });
  }

  goToRedirectUrl(): void {
    window.location.assign(`${window.location.origin}${this.state.pageToRedirect}`);
  }
  getTranslation(key: string): string {
    return loginForm.getString(key) ?? key;
  }

  render() {
    const contactUrl = storageHelper.getContactUrl();
    const start = dashboard.signup.indexOf('[');
    const end = dashboard.signup.indexOf(']');
    const grantMsgDisplay = storageHelper.getGrantMsgDisply();
    return (
      <Flex column gap="gap.small" padding="padding.medium">
        <Card appearance="subtle" style={{ justifyItems: 'center', backgroundColor: tokens.colorNeutralBackground2 }}>
          {!grantMsgDisplay && <MsGrantPermissions />}
          <Button
            size="medium"
            appearance='primary'
            style={{ width: '200px', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}
            onClick={this.authenticate}
          >
            {dashboard.login}
          </Button>

          <div style={{ margin: '20px 0 50px 0', textAlign: 'center' }}>
            <Text>{dashboard.signup.substr(0, start)}&nbsp;</Text>
            {/* eslint-disable-next-line */}
            <Link href={contactUrl} target="_blank" id="contactUrl">
              {dashboard.signup.substr(start + 1, end - 1 - start)}
            </Link>
            <Text>&nbsp;{dashboard.signup.substr(end + 1)}</Text>
          </div>

          {!!this.state.failureReason && (
            <MessageBar layout={'multiline'} intent={'warning'}>
              <MessageBarBody>{this.getTranslation(this.state.failureReason)}</MessageBarBody>
            </MessageBar>
          )}
          {this.props.authError !== ErrorCodes.none && (
            <MessageBar layout={'multiline'} intent={'error'}>
              <MessageBarBody>{this.getTranslation(this.props.authError)}</MessageBarBody>
            </MessageBar>
          )}
        </Card>
        {this.props.isAuthenticated === true && <Navigate to={this.state.pageToRedirect} />}
      </Flex>
    );
  }
}

// eslint-disable-next-line
const mapStateToProps = (state: any) => ({
  isLoading: isLoadingSelector(state),
  authError: authErrorSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...authActions,
      setLoadingIndicator,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
