import React from 'react';
import { Flex } from '@fluentui/react-migration-v0-v9';
import { Dropdown, Text, Option } from '@fluentui/react-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tabPageSelector } from '../../i18n/all';

interface TabPageSelectorProps {
  onSelect: (selectedPage: string) => void;
  actions: any;
}

interface TabPageSelectorState {
  selectedIndex: number;
}

const tabPages = [
  { key: 'dashboard' }, //
  { key: 'settings' },
  { key: 'org-page' },
];

export class TabPageSelector extends React.Component<TabPageSelectorProps, TabPageSelectorState> {
  constructor(props: TabPageSelectorProps) {
    super(props);

    this.state = {
      selectedIndex: -1,
    };

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(event: any, data: any) {
    if (data.highlightedIndex === -1) {
      return;
    }

    const index = data.highlightedIndex;
    this.setState(
      {
        selectedIndex: index === -1 ? data.defaultActiveSelectedIndex : index,
      },
      () => this.props.onSelect(tabPages[this.state.selectedIndex].key)
    );
  }

  render() {
    return (
      <Flex column>
        <Text>{tabPageSelector.chooseTabPage}</Text>
        <Dropdown
          value={
            this.state.selectedIndex === -1
              ? tabPageSelector.selectTabPage
              : tabPageSelector.getString(tabPages[this.state.selectedIndex].key)
          }
          onOpenChange={this.onSelect}
        >
          {tabPages?.map((c) => (
            <Option key={c.key}>
              {tabPageSelector.getString(c.key)}
            </Option>
          ))}
        </Dropdown>
      </Flex>
    );
  }
}

// eslint-disable-next-line
const mapStateToProps = (state: any) => ({
  // TBD
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      // TBD
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabPageSelector);
