import * as commonActionCreators from '../action-creators/common-action-creators';
import {
  setFeatureFlags,
  getFeatureFlags,
} from '../../api/adminApi';
import { BaseAction } from '../model/action';
import httpStatusCodes from '../../constants/http-status-codes';
import * as actionCreators from '../action-creators/admin-data-action-creators';
import FeatureFlags from '../../models/feature-flags';


const saveFeatureFlags = (flags: FeatureFlags) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    flags.linestateCoreToTeams = flags?.linestateCoreToTeams ?? true;
    flags.linestateTeamsToCore = flags?.linestateTeamsToCore ?? true;
    flags.presenceSyncTeamsToCore = flags?.presenceSyncTeamsToCore ?? true;
    flags.userPresenceMappingsEnabled = flags?.userPresenceMappingsEnabled ?? true;
    const response = await setFeatureFlags(flags);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetFeatureFlags(flags));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loadFeatureFlags = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const settingsResponse = await getFeatureFlags();
    if (settingsResponse.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetFeatureFlags(settingsResponse.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

export {
  saveFeatureFlags,
  loadFeatureFlags,
};
