import { Text } from '@fluentui/react-components';
import { Primitive } from '@fluentui/react-migration-v0-v9';
import * as microsoftTeams from '@microsoft/teams-js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { auth } from '../i18n/all';
import * as authActions from '../store/actions/authenticate-action';
import { authUrlSelector } from '../store/selectors/authentication-selectors';

interface AuthEndPageProps {
  actions: any;
}

interface AuthEndState {
  isSuccess: boolean;
  isLoading: boolean;
}

class AuthEndPage extends Component<AuthEndPageProps, AuthEndState> {
  constructor(props: AuthEndPageProps) {
    super(props);
    const url = new URL(window.location.href);
    const code = url.searchParams.get('code');

    this.state = { ...this.state, isSuccess: code ? true : false };
    this.props.actions.doAuthentication(
      code,
      code ? microsoftTeams.authentication.notifySuccess : microsoftTeams.authentication.notifyFailure
    );
  }

  render() {
    return (
      <Primitive>
        {this.state.isLoading && <Text>{auth.loading}</Text>}
        {!this.state.isLoading && !this.state.isSuccess && <Text>{auth.failedToLoginToSmg}</Text>}
      </Primitive>
    );
  }
}

// eslint-disable-next-line
const mapStateToProps = (state: any) => ({
  loginUrl: authUrlSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...authActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthEndPage);
