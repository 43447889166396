import React from 'react';
import { Title1, Subtitle2Stronger } from '@fluentui/react-components';
import { Primitive } from '@fluentui/react-migration-v0-v9';
import axios from 'axios';

import { endpoints } from '../config/endpoints';
import httpStatusCodes from '../constants/http-status-codes';

interface TestPageState {
  responseStatus: number | string;
  isBackEndOk: boolean;
  isDbOk: boolean;
}

export class TestPage extends React.Component<{}, TestPageState> {
  constructor(props: any) {
    super(props);

    this.state = {
      responseStatus: 'loading...',
      isBackEndOk: false,
      isDbOk: false,
    };

    axios
      .get(`${endpoints.backEndBase}/test`)
      .then((response) => {
        this.setState({
          responseStatus: response.status,
          isBackEndOk: response.status === httpStatusCodes.OK,
          isDbOk: response.data.isDbOk,
        });
      })
      .catch((error) => {
        const status = error.response?.status;
        this.setState({
          responseStatus: status ? status : error.message,
          isBackEndOk: false,
          isDbOk: false,
        });
      });
  }

  render() {
    return (
      <Primitive>
        <Title1>Test page</Title1>
        <div>
          <Subtitle2Stronger as="h5">{`Back-end is ${
            this.state.isBackEndOk ? 'OK' : `Not OK (status: ${this.state.responseStatus})`
          }`}</Subtitle2Stronger>
        </div>
        <Subtitle2Stronger as="h5">{`Database is ${this.state.isDbOk ? 'OK' : 'Not OK'}`}</Subtitle2Stronger>
      </Primitive>
    );
  }
}
