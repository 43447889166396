import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FeatureFlags from '../../models/feature-flags';
import { featureFlagsSelector } from '../../store/selectors/settings-data-selectors';
import * as adminDataActions from '../../store/actions/admin-data-action';
import { Checkbox, Subtitle2Stronger } from '@fluentui/react-components';
import type { CheckboxOnChangeData } from '@fluentui/react-components';
import { featureFlags } from '../../i18n/all';

interface FeatureFlagsProps {
  featureFlags: FeatureFlags;
  actions: any;
}

interface FeatureFlagsState {}

export class FeatureFlagsComponent extends React.Component<FeatureFlagsProps, FeatureFlagsState> {
  constructor(props: FeatureFlagsProps) {
    super(props);
    this.props.actions.loadFeatureFlags();
  }

  render() {
    const getUpdateCallback = (field: any, data: CheckboxOnChangeData) => {
      const newSettings: any = { ...this.props.featureFlags };
      newSettings[field] = data.checked;
      this.props.actions.saveFeatureFlags(newSettings);
    };
    const settings = this.props.featureFlags;
    return (
      <div>
        <Subtitle2Stronger>{featureFlags.widgetTitle}</Subtitle2Stronger>
        <div style={{ margin: '10px 0 5px 0', textAlign: 'left' }}>
          <Subtitle2Stronger>{featureFlags.coreToTeams}</Subtitle2Stronger>
        </div>
        <div>
          <Checkbox
            checked={Boolean(settings?.linestateCoreToTeams ? true : settings === null)}
            id="linestateCoreToTeams"
            label={featureFlags.linestate}
            onChange={(ev, data) => getUpdateCallback('linestateCoreToTeams', data)}
          />
        </div>
        <div style={{ margin: '10px 0 5px 0', textAlign: 'left' }}>
          <Subtitle2Stronger>{featureFlags.teamsToCore}</Subtitle2Stronger>
        </div>
        <div>
          <Checkbox
            checked={Boolean(settings?.linestateTeamsToCore ? true : settings === null)}
            id="linestateTeamsToCore"
            label={featureFlags.linestate}
            onChange={(ev, data) => getUpdateCallback('linestateTeamsToCore', data)}
          />
          <Checkbox
            checked={Boolean(settings?.presenceSyncTeamsToCore ? true : settings === null)}
            id="presenceSyncTeamsToCore"
            label={featureFlags.presenceMapping}
            onChange={(ev, data) => getUpdateCallback('presenceSyncTeamsToCore', data)}
          />
        </div>
        <div style={{ margin: '10px 0 5px 0', textAlign: 'left' }}>
          <Subtitle2Stronger>{featureFlags.configuration}</Subtitle2Stronger>
        </div>
        <div>
          <Checkbox
            checked={Boolean(settings?.userPresenceMappingsEnabled ? true : settings === null)}
            id="userPresenceMappingsEnabled"
            label={featureFlags.userPresenceMappings}
            onChange={(ev, data) => getUpdateCallback('userPresenceMappingsEnabled', data)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  featureFlags: featureFlagsSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...adminDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureFlagsComponent);
