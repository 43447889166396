import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const userDataRecord = (state: any) => state.userData;

const userAliasesSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return {
    availableAliases: record.availableCallerAliases,
    currentAlias: record.currentCallerAlias,
  };
});

const acdAttendantGroupsSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return record.acdAttendantGroups;
});

const supervisorGroupsSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return record.acdAttendantGroupAgents;
});

const contactListSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return record.contactList;
});

const sendSmsErrorsSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return record.sendSmsError;
});

const userRolesInfoSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return {
    availableRoles: record.availableRoles,
    currentRoleId: record.currentRoleId,
  };
});

const hasCallerIdLicenseSelector = createSelector(userDataRecord, (record = EMPTY_OBJECT) => {
  return record.hasCallerIdLicense;
});

export {
  userAliasesSelector,
  acdAttendantGroupsSelector,
  supervisorGroupsSelector,
  contactListSelector,
  sendSmsErrorsSelector,
  userRolesInfoSelector,
  hasCallerIdLicenseSelector,
};
