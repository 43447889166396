import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';
import { Card, tokens } from '@fluentui/react-components';
import { Flex } from '@fluentui/react-migration-v0-v9';
import { isAuthenticatedSelector } from '../store/selectors/authentication-selectors';
import * as authActions from '../store/actions/authenticate-action';
import { isLoadingSelector, msTeamsContextSelector } from '../store/selectors/common-selectors';
import MsGrantPermissions from './../components/settings/ms-grant-permissions';
import {
  hasMsGraphAccessSelector,
  hasSearchContactsAccessSelector,
  isGlobalAdminSelector,
} from '../store/selectors/settings-data-selectors';
import Authentication from '../components/settings/authentication';
import * as settingsDataActions from '../store/actions/settings-data-action';
import { Navigate } from 'react-router-dom';
import PresenceActivityMapping from '../components/settings/presence-activity-mapping';
import VoicemailSettingsComponent from '../components/settings/voicemail-settings';
import * as storageHelper from '../helpers/storage-helper';
import IPageBaseState from '../models/pageBaseState';
import { isMobile, isTablet } from 'react-device-detect';

interface SettingsPageProps {
  msTeamsContext: microsoftTeams.app.Context;
  isAuthenticated: boolean;
  hasMsGraphAccess: boolean;
  hasSearchContactsAccess: boolean;
  isGlobalAdmin: boolean;
  isLoading: boolean;
  actions: any;
}

interface SettingsPageState extends IPageBaseState {
  email: string | null;
  password: string | null;
  context: microsoftTeams.app.Context | null;
}

class SettingsPage extends Component<SettingsPageProps, SettingsPageState> {
  constructor(props: SettingsPageProps) {
    super(props);
    this.state = {
      email: null,
      password: null,
      context: props.msTeamsContext,
      isRedirecting: false,
    };

    if (isMobile || isTablet) {
      setInterval(
        (isRedirecting: boolean) => {
          const accessToken = storageHelper.getSmgAccessToken();
          if (!accessToken && this.props.isAuthenticated) {
            if (!isRedirecting) {
              window.location.assign(`${window.location.origin}/login?page=settings`);
              this.setState({ ...this.state, isRedirecting: true });
            }
          }
        },
        1000,
        this.state.isRedirecting
      );
    }
  }

  componentDidUpdate(prevProps: SettingsPageProps) {
    if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
      this.props.actions.loadPermissions();
    }
  }

  render() {
    return (
      <Flex column gap="gap.small" padding="padding.medium">
        <div>
          <Card appearance="subtle" style={{ backgroundColor: tokens.colorNeutralBackground2 }}>
            <MsGrantPermissions />
          </Card>
        </div>
        {this.props.isAuthenticated && (
          <div>
            <div>
              <Card appearance="subtle" style={{ backgroundColor: tokens.colorNeutralBackground2 }}>
                <PresenceActivityMapping />
              </Card>
            </div>
            <div>
              <Card
                appearance="subtle"
                style={{ marginTop: '10px', marginBottom: '10px', backgroundColor: tokens.colorNeutralBackground2 }}
              >
                <VoicemailSettingsComponent />
              </Card>
            </div>
            <div>
              <Card
                appearance="subtle"
                style={{ marginTop: '10px', marginBottom: '10px', backgroundColor: tokens.colorNeutralBackground2 }}
              >
                <Authentication />
              </Card>
            </div>
          </div>
        )}
        {this.props.isAuthenticated === false && <Navigate to={`/login?page=settings`} />}
      </Flex>
    );
  }
}

// eslint-disable-next-line
const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
  hasMsGraphAccess: hasMsGraphAccessSelector(state),
  hasSearchContactsAccess: hasSearchContactsAccessSelector(state),
  isGlobalAdmin: isGlobalAdminSelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...authActions,
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
