import ReduxActions from '../../constants/redux-actions';
import { BaseAction } from '../model/action';
import { HistoryItem } from '../../models/communication-log';
import { cloneDeep } from 'lodash';

const defaultState = {
  totalVoiceMailCount: 0,
  availableVoiceMails: Array<HistoryItem>(),
};

const voiceMail = (state = defaultState, action: BaseAction) => {
  switch (action.type) {
    case ReduxActions.LOAD_VOICE_MAILS: {
      return {
        ...state,
        totalVoiceMailCount: action.data.totalVoiceMailCount,
        availableVoiceMails: action.data.availableVoiceMails,
      };
    }
    case ReduxActions.UPDATE_HISTORY_ITEM: {
      const newList = cloneDeep(state.availableVoiceMails);
      const indexToUpdate = newList.findIndex((item) => action.data.itemToUpdate.itemId === item.itemId);
      newList.splice(indexToUpdate, 1, action.data.itemToUpdate);
      return {
        ...state,
        availableVoiceMails: newList,
      };
    }
    default:
      return state;
  }
};

export default voiceMail;
