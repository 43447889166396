import { createSelector } from 'reselect';

const EMPTY_OBJECT = {};

const commonDataRecord = (state: any) => {
  return state.authData;
};

const isAuthenticatedSelector = createSelector(commonDataRecord, (record = EMPTY_OBJECT) => {
  return record.isAuthenticated;
});

const authErrorSelector = createSelector(commonDataRecord, (record = EMPTY_OBJECT) => {
  return record.error;
});

const authUrlSelector = createSelector(commonDataRecord, (record = EMPTY_OBJECT) => {
  return record.loginUrl;
});

const hubConnectionSelector = createSelector(commonDataRecord, (record = EMPTY_OBJECT) => {
  return record.hubConnection;
});

export { isAuthenticatedSelector, authErrorSelector, authUrlSelector, hubConnectionSelector };
