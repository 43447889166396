type ErrorCode =
  | 'NONE'
  | 'UNKNOWN'
  | 'INVALID_CREDENTIALS'
  | 'CREDS_ALREADY_IN_USE'
  | 'USER_NOT_FOUND'
  | 'DATA_NOT_FOUND'
  | 'UPDATE_FAIL'
  | 'DELETE_FAIL'
  | 'INVALID_DATA'
  | 'MS_TEAMS_DATA_NOT_FOUND'
  | 'FAILED_TO_AUTHENTICATE'
  | 'ERR_BAD_REQUEST_400'
  | 'ERR_BAD_REQUEST_401'
  | 'ERR_BAD_REQUEST_404'
  | 'ERR_BAD_REQUEST_500'
  | 'ERR_NETWORK';

export const ErrorCodes = {
  none: 'NONE' as ErrorCode,
  unknown: 'UNKNOWN' as ErrorCode,
  invalidCredentials: 'INVALID_CREDENTIALS' as ErrorCode,
  credsAlreadyInUse: 'CREDS_ALREADY_IN_USE' as ErrorCode,
  userNotFound: 'USER_NOT_FOUND' as ErrorCode,
  dataNotFound: 'DATA_NOT_FOUND' as ErrorCode,
  updateFail: 'UPDATE_FAIL' as ErrorCode,
  deleteFail: 'DELETE_FAIL' as ErrorCode,
  invalidData: 'INVALID_DATA' as ErrorCode,
  msTeamsDataNotFound: 'MS_TEAMS_DATA_NOT_FOUND' as ErrorCode,
  failedToAuthenticate: 'FAILED_TO_AUTHENTICATE' as ErrorCode,
  errNetwork: 'ERR_NETWORK' as ErrorCode,
  errBr400: 'ERR_BAD_REQUEST_400' as ErrorCode,
  errBr401: 'ERR_BAD_REQUEST_401' as ErrorCode,
  errBr404: 'ERR_BAD_REQUEST_404' as ErrorCode,
  errBr500: 'ERR_BAD_REQUEST_500' as ErrorCode,
};

export default ErrorCode;
