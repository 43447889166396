export const TeamsThemes = {
  contrast: 'contrast',
  dark: 'dark',
  default: 'default',
  light: 'light',
};

export const StorageKeys = {
  msTeamsToken: 'msTeamsToken',
  smgAccessToken: 'SmgAccessToken',
  smgRefreshToken: 'SmgRefreshToken',
  realm: 'Realm',
  client: 'Client',
  smgAuthBase: 'SmgAuthBase',
  contactUrl: 'ContactUrl',
  grantMsgDisplay: 'GrantMsgDisplay',
};
