import * as msTeams from '@microsoft/teams-js';
import ReduxActions from '../../constants/redux-actions';
import { BaseAction } from '../model/action';

function acSetLoadingIndicator(isLoading: boolean): BaseAction {
  return {
    type: ReduxActions.SET_LOADING_INDICATOR,
    data: isLoading,
  };
}

function acSetMsTeamsContext(context: msTeams.Context): BaseAction {
  return {
    type: ReduxActions.SET_MS_TEAMS_CONTEXT,
    data: context,
  };
}

export { acSetLoadingIndicator, acSetMsTeamsContext };
