import React from 'react';
import { Button, Persona } from '@fluentui/react-components';
import { SignOut24Regular, CheckmarkCircle16Filled } from '@fluentui/react-icons';
import * as microsoftTeams from '@microsoft/teams-js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import { authentication } from '../../i18n/all';
import * as authenticateActions from '../../store/actions/authenticate-action';
import { loggedInAsSelector } from '../../store/selectors/settings-data-selectors';
import * as settingsDataActions from '../../store/actions/settings-data-action';
import { isMobile } from 'react-device-detect';
import { appVersion } from '../../config/endpoints';

interface AuthenticationProps {
  msTeamsContext: microsoftTeams.app.Context | null;
  loggedInAs: string;
  actions: any;
}

interface AuthenticationState {}

export class Authentication extends React.Component<AuthenticationProps, AuthenticationState> {
  render() {
    return (
      <div style={{ textAlign: isMobile ? 'center' : 'left' }}>
        {authentication.loggedInUser} <strong>{this.props.loggedInAs}{' '}</strong>
        <Button id="logout" size="medium" icon={<SignOut24Regular />} onClick={this.props.actions.removeAuthentication}>
          {authentication.logout}
        </Button>
        <div style={{ marginTop: '20px' }}>
          {authentication.version} {appVersion}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  loggedInAs: loggedInAsSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...authenticateActions,
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
