import React from 'react';
import {
  Dropdown,
  Option,
  Textarea,
  Text,
  Button,
  Input,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogContent,
  DialogBody,
  DialogTitle,
  DialogActions,
  Subtitle2Stronger,
  Card,
  Menu,
  MenuList,
  MenuItem,
  MenuTrigger,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  TagGroup,
  Tag,
  tokens,
} from '@fluentui/react-components';
import type { DropdownProps, InputOnChangeData, InputProps, TagGroupProps } from '@fluentui/react-components';
import {
  ArrowSync24Filled,
  Send24Filled,
  Call24Regular,
  MailCheckmark20Regular,
  TriangleDown24Filled,
  ContactCard16Regular,
  DismissRegular,
} from '@fluentui/react-icons';
import { Flex } from '@fluentui/react-migration-v0-v9';
import * as microsoftTeams from '@microsoft/teams-js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userDataActions from '../../store/actions/user-data-action';
import {
  contactListSelector,
  sendSmsErrorsSelector,
  userAliasesSelector,
} from '../../store/selectors/user-data-selectors';
import { msTeamsContextSelector, isLoadingSelector } from '../../store/selectors/common-selectors';
import Contact from '../../models/contact';
import {
  hasMsGraphAccessSelector,
  hasSearchContactsAccessSelector,
  smsMaxLengthSelector,
} from '../../store/selectors/settings-data-selectors';
import CallerAlias from '../../models/caller-alias';
import formatAliasName from '../../helpers/alias-name-formatter';
import { sendSms } from '../../i18n/all';
import { ErrorCodes } from '../../models/error-code';
import { isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';

interface SendSmsProps {
  msTeamsContext: microsoftTeams.app.Context | null;
  contactList: Contact[];
  sendSmsError: string;
  hasMsGraphAccess: boolean;
  hasSearchContactsAccess: boolean;
  actions: any;
  isLoading: boolean;
  callerAliases: {
    availableAliases: CallerAlias[];
    currentAlias: CallerAlias;
  };
  smsMaxLength: number;
}

interface SendSmsState {
  message: string;
  chosenContacts: Contact[];
  chosenCallerAlias: any;
  typedNumbers: string;
  sent?: boolean;
  open: boolean;
  searchOpen: boolean;
  typeContact: string;
  typeSearch: string;
}

export class SendSms extends React.Component<SendSmsProps, SendSmsState, DropdownProps> {
  constructor(props: SendSmsProps) {
    super(props);
    this.state = {
      message: '',
      chosenContacts: [],
      chosenCallerAlias: null,
      typedNumbers: '',
      sent: undefined,
      open: false,
      searchOpen: false,
      typeContact: '',
      typeSearch: '',
    };
  }
  onSearchQueryChange: InputProps['onChange'] = (ev, data: InputOnChangeData) => {
    this.setState({ typeSearch: data.value });
    if (data.value && data.value.length > 2) {
      this.setState({ searchOpen: true });
      this.props.actions.loadContactList(this.props.msTeamsContext?.user?.id, data.value);
    } else {
      this.setState({ searchOpen: false });
    }
  };

  dismissMessage = () => {
    this.setState({ sent: undefined });
  };

  removeItem: TagGroupProps['onDismiss'] = (_e, { value }) => {
    var getCurrenTags = [...this.state.chosenContacts];
    var getCurrentTag = getCurrenTags.filter((tag) => tag.phone !== value);
    this.setState({ chosenContacts: getCurrentTag });
  };

  render() {
    const self = this;
    let listItems = this.props.callerAliases?.availableAliases?.filter((a) => !!a.type);
    if ((this.props.hasMsGraphAccess === null && this.props.hasSearchContactsAccess === null) || this.props.isLoading) {
      return (
        <Flex column gap="gap.small">
          <Subtitle2Stronger>{sendSms.sendSms}</Subtitle2Stronger>
          <Flex gap="gap.small" vAlign="center">
            <ArrowSync24Filled />
            <Text>{sendSms.updating}</Text>
          </Flex>
        </Flex>
      );
    } else {
      return (
        <div>
          <Subtitle2Stronger>{sendSms.sendSms}</Subtitle2Stronger>
          <div style={{ display: isMobile ? 'block' : 'flex', paddingTop: '10px', paddingBottom: '10px' }}>
            <Card
              appearance="subtle"
              style={{
                width: '100%',
                maxWidth: '100%',
                marginRight: isMobile ? '0px' : '5px',
                backgroundColor: tokens.colorNeutralBackground2,
              }}
            >
              {this.state.chosenContacts.length !== 0 && (
                <Card
                  appearance="subtle"
                  style={{
                    display: 'contents',
                    marginRight: isMobile ? '0px' : '5px',
                    backgroundColor: tokens.colorNeutralBackground2,
                  }}
                >
                  <TagGroup
                    onDismiss={this.removeItem}
                    aria-label="Dismiss example"
                    key={'tags-data'}
                    style={{ display: 'inline-block', maxHeight: '80px' , overflow: 'auto'}}
                  >
                    {this.state.chosenContacts.map((tag) => (
                      <Tag
                        dismissible
                        dismissIcon={{ 'aria-label': 'remove' }}
                        value={tag.phone}
                        key={tag.displayName}
                        size="medium"
                        secondaryText={tag.phone}
                      >
                        {tag.displayName}
                      </Tag>
                    ))}
                  </TagGroup>
                </Card>
              )}
              {(this.props.hasMsGraphAccess || this.props.hasSearchContactsAccess) && (
                <Menu positioning={'below-start'} open={this.state.searchOpen}>
                  <MenuTrigger disableButtonEnhancement>
                    <Input
                      id="customContact"
                      contentBefore={<ContactCard16Regular />}
                      placeholder={sendSms.searchPlaceholder}
                      onChange={this.onSearchQueryChange}
                      value={this.state.typeSearch}
                    />
                  </MenuTrigger>
                  <MenuList>
                    {this.props.contactList?.map((c) => (
                      <MenuItem
                        key={c.phone}
                        style={{ width: '100%', maxWidth: '100%' }}
                        onClick={() => {
                          var contactsResult = this.state.chosenContacts.find(({ phone }) => phone === c.phone);
                          if (!isEmpty(contactsResult)) {
                            return;
                          }
                          var contact = this.state.chosenContacts.concat(c);
                          this.setState({ chosenContacts: contact });
                          this.setState({ typeSearch: '' });
                          this.setState({ searchOpen: false });
                        }}
                      >
                        {c.displayName && c.phone && (
                          <div>
                            <div>{c.displayName}</div>
                            <div>{c.phone}</div>
                          </div>
                        )}
                      </MenuItem>
                    ))}
                    {this.props.contactList.length <= 0 && <MenuItem>{sendSms.searchNoResults}</MenuItem>}
                  </MenuList>
                </Menu>
              )}
              <Input
                id="customPhones"
                contentBefore={<Call24Regular />}
                placeholder={sendSms.typeNumberPlaceholder}
                value={this.state.typedNumbers}
                onChange={(_e, data: any) => this.setState({ typedNumbers: data.value })}
              />
              {/* This feature is temporary disable as API doesn't support it */}
              {false && !!listItems && (
                <Dropdown
                  placeholder={sendSms.selectCallerIdPlaceholder}
                  onOpenChange={(_e, data: any) => {
                    this.setState({ chosenCallerAlias: data.value.item });
                  }}
                  {...this.props}
                  id="callerIdSelector"
                >
                  {listItems?.map((a) => <Option key={a.number}>{a.number}</Option>)}
                </Dropdown>
              )}
            </Card>
            <Card
              appearance="subtle"
              style={{
                width: '100%',
                maxWidth: '100%',
                marginLeft: isMobile ? '0px' : '5px',
                backgroundColor: tokens.colorNeutralBackground2,
              }}
            >
              <Textarea
                style={{ height: '120px', minWidth: '300px', backgroundColor: tokens.colorNeutralBackground1 }}
                id="message"
                maxLength={this.props.smsMaxLength}
                placeholder={sendSms.messagePlaceholder}
                value={this.state.message}
                onChange={(_e, data: any) => this.setState({ message: data.value })}
              />
              <div style={{ textAlign: 'right' }}>
                {this.state.message.length}/{this.props.smsMaxLength}
              </div>
            </Card>
            {/* </div> */}
          </div>
          <Flex vAlign="end">
            {this.state.sent === true && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <MessageBar layout={'singleline'} intent={'success'} icon={<MailCheckmark20Regular />}>
                  <MessageBarBody>{sendSms.messageIsSent}</MessageBarBody>
                  <MessageBarActions
                    containerAction={
                      <Button
                        onClick={this.dismissMessage}
                        aria-label="dismiss"
                        appearance="transparent"
                        icon={<DismissRegular />}
                      />
                    }
                  ></MessageBarActions>
                </MessageBar>
              </div>
            )}
            {this.state.sent === false && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <MessageBar layout={'singleline'} intent={'error'}>
                  <MessageBarBody>{sendSms.messageIsNotSent}</MessageBarBody>
                  <MessageBarActions
                    containerAction={
                      <Button
                        onClick={this.dismissMessage}
                        aria-label="dismiss"
                        appearance="transparent"
                        icon={<DismissRegular />}
                      />
                    }
                  ></MessageBarActions>
                </MessageBar>
              </div>
            )}
            <div style={{ textAlign: 'right', width: '100%', marginRight: '10px', marginLeft: 'auto' }}>
              <Button
                size="medium"
                id="sendText"
                disabled={!this.state.message || (!this.state.typedNumbers && this.state.chosenContacts.length === 0)}
                onClick={() => {
                  this.props.actions.sendSmsMessage(
                    this.state.message,
                    [
                      ...this.state.chosenContacts.map((c) => c.phone), //
                      ...this.state.typedNumbers.split(/[,;]+/),
                    ].filter((i) => !!i),
                    this.state.chosenCallerAlias ?? listItems.find((pa) => !!pa.active),
                    (res: boolean) => {
                      if (!res) {
                        self.setState({ ...self.state, sent: res });
                      } else {
                        self.setState({
                          message: '',
                          chosenContacts: [],
                          typedNumbers: '',
                          sent: res,
                          chosenCallerAlias: null,
                        });
                      }
                    }
                  );
                }}
              >
                <Send24Filled />
              </Button>
            </div>
          </Flex>
          {!!this.props.sendSmsError && this.props.sendSmsError !== ErrorCodes.none && (
            <Flex gap="gap.small" vAlign="center" padding="padding.medium">
              <Dialog modalType="alert" open={this.state.open} defaultOpen={this.state.open}>
                <DialogSurface>
                  <DialogBody>
                    <DialogTitle>
                      <TriangleDown24Filled />
                    </DialogTitle>
                    <DialogContent>{`${sendSms.errorMessage} ${this.props.sendSmsError}`}</DialogContent>
                    <DialogActions>
                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Close</Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            </Flex>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  contactList: contactListSelector(state),
  sendSmsError: sendSmsErrorsSelector(state),
  hasMsGraphAccess: hasMsGraphAccessSelector(state),
  hasSearchContactsAccess: hasSearchContactsAccessSelector(state),
  isLoading: isLoadingSelector(state),
  callerAliases: userAliasesSelector(state),
  smsMaxLength: smsMaxLengthSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...userDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendSms);
