import ReduxActions from '../../constants/redux-actions';
import { BaseAction } from '../model/action';

const defaultState = {
  loadingCounter: 0,
  msTeamsContext: null,
};

const common = (state = defaultState, action: BaseAction) => {
  switch (action.type) {
    case ReduxActions.SET_LOADING_INDICATOR:
      return {
        ...state,
        loadingCounter: action.data ? state.loadingCounter + 1 : state.loadingCounter - 1,
      };

    case ReduxActions.SET_MS_TEAMS_CONTEXT:
      return {
        ...state,
        msTeamsContext: action.data,
      };

    default:
      return state;
  }
};

export default common;
