import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as microsoftTeams from '@microsoft/teams-js';
import { msTeamsContextSelector, isLoadingSelector } from '../store/selectors/common-selectors';
import * as authActions from '../store/actions/authenticate-action';
import * as commonActions from '../store/actions/common-action';
import * as settingsDataActions from '../store/actions/settings-data-action';
import { isAuthenticatedSelector } from '../store/selectors/authentication-selectors';
import TabPageSelector from '../components/configure/tab-page-selector';
import { connect } from 'react-redux';
import { Flex } from '@fluentui/react-migration-v0-v9';

interface ConfigPageProps {
  msTeamsContext: microsoftTeams.app.Context;
  isAuthenticated: boolean;
  isLoading: boolean;
  actions: any;
}

interface ConfigPageState {
  email: string | null;
  password: string | null;
  context: microsoftTeams.app.Context | null;
  selectedPageKey: string | null;
  userObjectId: string | undefined;
}

class ConfigurePage extends Component<ConfigPageProps, ConfigPageState> {
  constructor(props: ConfigPageProps) {
    super(props);
    this.state = {
      email: null,
      password: null,
      context: props.msTeamsContext,
      selectedPageKey: null,
      userObjectId: this.props.msTeamsContext.user?.id,
    };

    this.createTabUrl = this.createTabUrl.bind(this);
    this.onTabPageSelect = this.onTabPageSelect.bind(this);
    this.onLoginCredentialsChange = this.onLoginCredentialsChange.bind(this);
    try {
      this.registerSaveHandler();
    } catch {} // eslint-disable-line
  }

  componentDidUpdate(prevProps: ConfigPageProps) {
    if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
      this.props.actions.loadPermissions();
    }
  }

  registerSaveHandler() {
    microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
      microsoftTeams.pages.config.setConfig({
        contentUrl: this.createTabUrl(),
        entityId: this.createTabUrl(),
      });
      saveEvent.notifySuccess();
    });
  }

  createTabUrl(): string {
    return `${window.location.protocol}//${window.location.host}/${this.state.selectedPageKey}?userId=${this.state.context?.user?.id}`;
  }

  onLoginCredentialsChange(creds: { email: string; password: string }) {
    this.setState({ email: creds.email, password: creds.password }, () => {
      microsoftTeams.pages.config.setValidityState(!!this.state.email && !!this.state.password);
    });
  }

  onTabPageSelect(selectedPageKey: string) {
    this.setState({ selectedPageKey: selectedPageKey }, () => {
      microsoftTeams.pages.config.setValidityState(!!this.state.selectedPageKey);
    });
  }

  render() {
    return (
      <Flex column>
        <TabPageSelector onSelect={this.onTabPageSelect} />
      </Flex>
    );
  }
}

const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...commonActions,
      ...authActions,
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurePage);