import React from 'react';
import {
  Text,
  Switch,
  Subtitle2Stronger,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableCellLayout,
  tokens,
} from '@fluentui/react-components';
import { Flex } from '@fluentui/react-migration-v0-v9';
import {
  PeopleCheckmark24Filled,
  ArrowDown24Filled,
  ArrowUp24Filled
} from '@fluentui/react-icons';
import * as microsoftTeams from '@microsoft/teams-js';

import * as userDataActions from '../../store/actions/user-data-action';
import { supervisorGroupsSelector } from '../../store/selectors/user-data-selectors';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import { supervisorGroups } from '../../i18n/all';
import AcdAttendantGroupAgents from './../../models/acd-attendant-group-agents';
import Agent from '../../models/agent';
import { cloneDeep } from 'lodash';

interface SupervisorGroupsProps {
  supervisorGroups: AcdAttendantGroupAgents[];
  msTeamsContext: microsoftTeams.app.Context | null;
  actions: any;
}

interface SupervisorGroupsState {
  isCollapsed: any;
}

export class SupervisorGroups extends React.Component<SupervisorGroupsProps, SupervisorGroupsState> {
  constructor(props: SupervisorGroupsProps) {
    super(props);
    this.state = { isCollapsed: {} };
    this.props.actions.loadSupervisorGroup(this.props.msTeamsContext?.user?.id);
  }
  switcherClick(group: AcdAttendantGroupAgents, agent: Agent) {
    this.props.actions.supervisorLoginUserToAcdAttendantGroup(this.props.msTeamsContext?.user?.id, group, agent);
  }
  changeExpand(groupId: number) {
    let newState = cloneDeep(this.state);
    newState.isCollapsed[groupId] = !newState.isCollapsed[groupId];
    console.log(this.state.isCollapsed[groupId]);
    this.setState(newState);
  }
  render() {
    return (
      <Flex>
        {!!this.props.supervisorGroups?.length ? (
          <Flex column>
            <Subtitle2Stronger>{supervisorGroups.name}</Subtitle2Stronger>
            {this.props.supervisorGroups.map((group) => {
              const loggedInAgents = group.agents.filter((a) => a.isLoggedIn).length;
              const allAgents = group.agents.length;
              return (
                <Flex key={group.id} column>
                  <Text as="p">
                    <PeopleCheckmark24Filled style={{ marginRight: '10px', marginTop: '-5px' }} />
                    <span style={{display: 'inline-flex', verticalAlign: 'super'}}>
                      {`${supervisorGroups.group}: ${group.name}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`${loggedInAgents}/${allAgents}`}
                    </span>
                    {this.state.isCollapsed[group.id] && (
                      <ArrowDown24Filled
                        style={{ padding: '0 10px', cursor: 'pointer' }}
                        onClick={() => this.changeExpand(group.id)}
                      />
                    )}
                    {!this.state.isCollapsed[group.id] && (
                      <ArrowUp24Filled
                        style={{ padding: '0 10px', cursor: 'pointer' }}
                        onClick={() => this.changeExpand(group.id)}
                      />
                    )}
                  </Text>
                  {!this.state.isCollapsed[group.id] && (
                    <Table>
                      <TableBody>
                        {group.agents.map((agent) => {
                          return (
                            <TableRow key={'item.file.label'} style={{ border: 'none' }}>
                              <TableCell>
                                <TableCellLayout truncate>{`${agent.firstName} ${agent.lastName}`}</TableCellLayout>
                              </TableCell>
                              <TableCell>
                                <TableCellLayout truncate>{agent.activity}</TableCellLayout>
                              </TableCell>
                              <TableCell>
                                <TableCellLayout truncate>
                                  <Switch
                                    checked={agent.isLoggedIn}
                                    onChange={() => this.switcherClick(group, agent)}
                                  />
                                </TableCellLayout>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </Flex>
              );
            })}
          </Flex>
        ) : (
          <Flex space="between" gap="gap.smaller" vAlign="end">
            <Text as="h3">{supervisorGroups.emptyPlaceholder}</Text>
          </Flex>
        )}
      </Flex>
    );
  }
}

const mapStateToProps = (state: any) => ({
  supervisorGroups: supervisorGroupsSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...userDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorGroups);
