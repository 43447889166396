import AliasType from '../models/alias-type.enum';

export const aliases = [
  { key: AliasType.ANONYMOUS, value: 'Anonymous' },
  { key: AliasType.ACD, value: 'ACD' },
  { key: AliasType.ACD_LIGHT, value: 'ACD Light' },
  { key: AliasType.ATTENDANT, value: 'Attendant' },
  { key: AliasType.SWITCHBOARD, value: 'Switchboard' },
  { key: AliasType.OFFICE_FIXED, value: 'Office Fixed' },
  { key: AliasType.PRIVATE_FIXED, value: 'Private Fixed' },
  { key: AliasType.OFFICE_MOBILE, value: 'Office Mobile' },
  { key: AliasType.PRIVATE_MOBILE, value: 'Private Mobile' },
];

export function getStringOfAliasType(aliasType: AliasType): string {
  const name = aliases.find((a) => a.key === aliasType)?.value;
  return name ? name : AliasType[aliasType];
}
