import axios, { InternalAxiosRequestConfig } from 'axios';
import { refreshToken, updateSmgTokens, logoutFromSmg } from '../api/authApi';
import { isTokenExpired } from './jwt-helper';
import * as storageHelper from './storage-helper';

const registerInterceptors = () => {
  axios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
      if (config.url?.includes('/login/')) {
        console.warn('REQUEST::::::::::::' + config.url);
        return config;
      }

      console.log('REQUEST::::::::::::' + config.url);
      let accessToken = storageHelper.getSmgAccessToken();

      if (!accessToken) {
        return config;
      }
      try {
        if (isTokenExpired(accessToken)) {
          const oldRefreshToken = storageHelper.getSmgRefreshToken();
          const newSmgTokenResponse = await refreshToken(accessToken, oldRefreshToken);
          if (newSmgTokenResponse.status >= 200 && newSmgTokenResponse.status < 299) {
            storageHelper.setSmgToken(newSmgTokenResponse.data);
            accessToken = newSmgTokenResponse.data.accessToken;
            await updateSmgTokens();
          } else {
            storageHelper.removeSmgToken();
            window.location.reload();
            return config;
          }
        }
      } catch (error) {
        console.log(error);
        if (error !== 'Network Error') {
          await logoutFromSmg(storageHelper.getSmgRefreshToken());
          storageHelper.removeSmgToken();
        }

        window.location.reload();
        return config;
      }

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      config.headers['Content-Type'] = 'application/json';

      return config;
    },
    function (error) {
      console.log(`MSTeamsConnectError: ${error}`);
      //storageHelper.removeSmgToken();
      window.location.reload();

      return Promise.reject(error);
    }
  );
};
export default registerInterceptors;
