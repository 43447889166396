import { Card, Text, shorthands, tokens } from '@fluentui/react-components';
import { Flex } from '@fluentui/react-migration-v0-v9';
import * as microsoftTeams from '@microsoft/teams-js';
import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import SetCallerId from '../components/dashboard/set-caller-id';
import AcdAttendantGroups from '../components/dashboard/acd-attendant-groups';
import * as authActions from '../store/actions/authenticate-action';
import * as settingsDataActions from '../store/actions/settings-data-action';
import * as userDataActions from '../store/actions/user-data-action';
import { isAuthenticatedSelector } from '../store/selectors/authentication-selectors';
import { isLoadingSelector, msTeamsContextSelector } from '../store/selectors/common-selectors';
import SendSms from '../components/dashboard/send-sms';
import {
  hasSmsWidgetAccessSelector,
  isPermissionsLoadedSelector,
  testUsersSelector,
} from '../store/selectors/settings-data-selectors';
import { hasCallerIdLicenseSelector } from '../store/selectors/user-data-selectors';
import { hubConnectionSelector } from '../store/selectors/authentication-selectors';
import * as storageHelper from '../helpers/storage-helper';
import IPageBaseState from '../models/pageBaseState';
import VoiceMails from '../components/dashboard/voice-mails';
import SetRole from '../components/dashboard/set-role';

interface DashboardPageProps {
  msTeamsContext: microsoftTeams.app.Context;
  hasSmsWidgetAccess: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  actions: any;
  connection: signalR.HubConnection;
  testUsersSelector: string[];
  isPermissionsLoaded: boolean;
  hasCallerIdLicense: boolean;
}

interface DashboardPageState extends IPageBaseState {
  context: microsoftTeams.app.Context | null;
  testMessage: string;
}

const flex = {
  ...shorthands.gap('16px'),
  display: 'flex',
};

class DashboardPage extends Component<DashboardPageProps, DashboardPageState> {
  constructor(props: DashboardPageProps) {
    super(props);
    this.props.actions.loadCallerAliases();

    this.state = {
      context: props.msTeamsContext,
      testMessage: '',
      isRedirecting: false,
    };

    if (isMobile || isTablet) {
      setInterval(
        (isRedirecting: boolean) => {
          const accessToken = storageHelper.getSmgAccessToken();
          if (!accessToken && this.props.isAuthenticated) {
            if (!isRedirecting) {
              window.location.assign(`${window.location.origin}/login?page=dashboard`);
              this.setState({ ...this.state, isRedirecting: true });
            }
          }
        },
        1000,
        this.state.isRedirecting
      );
    }
  }

  componentDidUpdate() {
    if (this.props.isAuthenticated && !this.props.isPermissionsLoaded) {
      this.props.actions.loadPermissions();
    }
  }

  render() {
    return (
      <Flex column gap="gap.small" padding="padding.medium">
        {this.props.isAuthenticated && (
          <>
            <div style={{ display: isMobile ? 'block' : 'flex', width: '100%', minWidth: '100%' }}>
              <Card
                appearance="subtle"
                style={{
                  width: isMobile ? '100%' : '100%',
                  maxWidth: '100%',
                  marginLeft: '0px',
                  marginRight: isMobile ? '0px' : '5px',
                  backgroundColor: tokens.colorNeutralBackground2,
                }}
              >
                <AcdAttendantGroups />
              </Card>
              <Card
                appearance="subtle"
                style={{
                  width: isMobile ? '100%' : '100%',
                  maxWidth: '100%',
                  marginLeft: isMobile ? '0px' : '5px',
                  marginRight: '0px',
                  marginTop: isMobile ? '10px' : '0px',
                  backgroundColor: tokens.colorNeutralBackground2,
                }}
              >
                {this.props.hasCallerIdLicense && <SetCallerId />}
                {!this.props.hasCallerIdLicense && <SetRole />}
              </Card>
            </div>
            {!!this.props.hasSmsWidgetAccess && (
              <div>
                <Card appearance="subtle" style={{ backgroundColor: tokens.colorNeutralBackground2 }}>
                  <SendSms />
                </Card>
              </div>
            )}
            <div>
              <Card appearance="subtle" style={{ backgroundColor: tokens.colorNeutralBackground2 }}>
                <VoiceMails />
              </Card>
            </div>
            {false && (
              <div>
                <Card appearance="subtle">
                  {/* TODO::TestInfo Delete before deploy  */}
                  <Text>{this.state.testMessage}</Text>
                  <Text>{this.props.testUsersSelector?.join(' ')}</Text>
                  {/* end */}
                </Card>
              </div>
            )}
          </>
        )}

        {this.props.isAuthenticated === false && <Navigate to={`/login?page=dashboard`} />}
      </Flex>
    );
  }
}

const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  hasSmsWidgetAccess: hasSmsWidgetAccessSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
  isLoading: isLoadingSelector(state),
  connection: hubConnectionSelector(state),
  isPermissionsLoaded: isPermissionsLoadedSelector(state),
  testUsersSelector: testUsersSelector(state),
  hasCallerIdLicense: hasCallerIdLicenseSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...authActions,
      ...settingsDataActions,
      loadCallerAliases: userDataActions.loadCallerAliases,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
