import { HubConnection } from '@microsoft/signalr';
import ReduxActions from '../../constants/redux-actions';
import ErrorCode from '../../models/error-code';
import { BaseAction } from '../model/action';

function setAuthenticationError(errorType: ErrorCode): BaseAction {
  return {
    type: ReduxActions.AUTHENTICATE,
    data: {
      error: errorType,
      isAuthenticated: false,
    },
  };
}

function acSetIsAuthenticated(isAuthenticated: boolean): BaseAction {
  return {
    type: ReduxActions.SET_IS_AUTHENTICATED,
    data: isAuthenticated,
  };
}

function acSetAuthUrl(loginUrl: string): BaseAction {
  return {
    type: ReduxActions.SET_AUTH_URL,
    data: loginUrl,
  };
}

function acSetHubConnection(connection: HubConnection): BaseAction {
  return {
    type: ReduxActions.SET_HUB_CONNECTION,
    data: connection,
  };
}

export { setAuthenticationError, acSetIsAuthenticated, acSetAuthUrl, acSetHubConnection };
