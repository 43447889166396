import { FluentProvider, teamsLightTheme, teamsDarkTheme, teamsHighContrastTheme } from '@fluentui/react-components';
import * as msTeams from '@microsoft/teams-js';
import React from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PlayVoiceMessagePage from './bot-pages/playVoiceMessage';
import TeamsThemeHelper from './helpers/teams-theme-helper';
import AuthEndPage from './pages/auth-end';
import AuthStartPage from './pages/auth-start';
import ConfigurePage from './pages/configure';
import DashboardPage from './pages/dashboard';
import SettingsPage from './pages/settings';
import OrgPage from './pages/org-page';
import LoginPage from './pages/login';
import { TestPage } from './pages/test-page';
import * as authActions from './store/actions/authenticate-action';
import * as commonActions from './store/actions/common-action';
import * as settingsDataActions from './store/actions/settings-data-action';
import { isLoadingSelector, msTeamsContextSelector } from './store/selectors/common-selectors';
import BlockingLoader from './widgets/blocking-loader';
import * as storageHelper from './helpers/storage-helper';
import registerInterceptors from './helpers/axios-interceptors';

class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = { theme: teamsLightTheme };
    const updateTheme = (themeString: string) => {
      this.setState({
        theme: TeamsThemeHelper.getTheme(themeString),
      });
    }
    registerInterceptors();
    msTeams.app.initialize();
    msTeams.app.getContext().then((context) => {
      updateTheme(context.app.theme);
      this.props.actions.setTeamsData(context);
      // this.props.actions.establishNotifications();
    });
    msTeams.app.registerOnThemeChangeHandler(updateTheme.bind(this));
    const authTokenRequest = {
      successCallback: (result: any) => {
        console.log('msTeams authentication successful');
        storageHelper.setMsSsoToken(result);
        this.props.actions.setMsTeamsContext();
        this.props.actions.checkAuthentication();
        msTeams.app.notifySuccess();
      },
      failureCallback: (error: any) => {
        console.log('msTeams authentication successful', error);
        this.props.actions.checkAuthentication();
        msTeams.app.notifyFailure({
          reason: msTeams.app.FailedReason.AuthFailed,
          message: error,
        });
      },
    };
    msTeams.authentication.getAuthToken(authTokenRequest);


    window.document.addEventListener('visibilitychange', () => {
      this.props.actions.checkAuthentication();
    });
  }

  

  render() {
    const { msTeamsContext, isLoading } = this.props;
    console.log('APP UI IS STARTED');
    const url = new URL(window.location.href);
    storageHelper.saveAuthBase(url.searchParams.get('smgAuthBase'));
    storageHelper.saveClient(url.searchParams.get('client'));
    storageHelper.saveRealm(url.searchParams.get('realm'));
    storageHelper.saveContactUrl(url.searchParams.get('contactUrl'));

    return (
      <FluentProvider theme={this.state.theme}>
        {isLoading && <BlockingLoader />}
        {
          <Routes>
            <Route>
              <Route path="/" element={<TestPage />} />
              <Route path="/test" element={<TestPage />} />
              <Route path="/voiceMail" element={<PlayVoiceMessagePage />} />
              {msTeamsContext && <Route path="/configure" element={<ConfigurePage />} />}
              {msTeamsContext && <Route path="/dashboard" element={<DashboardPage />} />}
              {msTeamsContext && <Route path="/settings" element={<SettingsPage />} />}
              {msTeamsContext && <Route path="/org-page" element={<OrgPage />} />}
              {msTeamsContext && <Route path="/login" element={<LoginPage />} />}
              {msTeamsContext && <Route path="/auth-end" element={<AuthEndPage />} />}
              {msTeamsContext && <Route path="/auth-start" element={<AuthStartPage />} />}
            </Route>
          </Routes>
        }
      </FluentProvider>
    );
  }
}



const mapStateToProps = (state: any) => ({
  msTeamsContext: msTeamsContextSelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...commonActions,
      ...authActions,
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
interface IAppProps {
  msTeamsContext: msTeams.Context;
  isLoading: boolean;
  actions: any;
}

interface IAppState {
  theme: any;
}
