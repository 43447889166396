import ReduxActions from '../../constants/redux-actions';
import CallerAlias from '../../models/caller-alias';
import AcdAttendantGroup from '../../models/acd-attendant-group';
import Contact from '../../models/contact';
import AcdAttendantGroupAgents from '../../models/acd-attendant-group-agents';
import Agent from '../../models/agent';
import PresenceRole from '../../models/presence-role';

function acSetCallerAliases(aliases: CallerAlias[]) {
  return {
    type: ReduxActions.LOAD_ALIASES,
    data: {
      availableAliases: aliases,
      currentAlias: aliases.find((alias) => alias.active),
    },
  };
}

function acSetAvailableUserRoles(availableRoles: PresenceRole[]) {
  return {
    type: ReduxActions.SET_AVAILABLE_USER_ROLES,
    data: {
      availableRoles: availableRoles,
    },
  };
}

function acSetCurrentUserRole(role: string) {
  return {
    type: ReduxActions.SET_CURRENT_USER_ROLE,
    data: {
      currentRoleId: role,
    },
  };
}

function acSetAcdAttendantGroups(groups: AcdAttendantGroup[]) {
  return {
    type: ReduxActions.LOAD_ACD_ATTENDANT_GROUP,
    data: { acdAttendantGroups: groups },
  };
}

function acSetSupervisorGroups(groups: AcdAttendantGroupAgents[]) {
  return {
    type: ReduxActions.LOAD_ACD_ATTENDANT_GROUP_AGENT,
    data: { acdAttendantGroupAgents: groups },
  };
}

function acRefreshAcdAttendantGroups(changedGroup: AcdAttendantGroup) {
  return {
    type: ReduxActions.REFRESH_ACD_ATTENDANT_GROUP,
    data: { changedAcdAttendantGroup: changedGroup },
  };
}

function acRefreshAgent(changedGroup: AcdAttendantGroupAgents, agent: Agent) {
  return {
    type: ReduxActions.REFRESH_AGENT_GROUP,
    data: {
      changedGroup: changedGroup,
      agent: agent,
    },
  };
}

function acSetContactList(contacts: Contact[]) {
  return {
    type: ReduxActions.LOAD_CONTACT_LIST,
    data: { contactList: contacts },
  };
}

function acSetSendSmsError(sendSmsError: string) {
  return {
    type: ReduxActions.SET_SEND_SMS_ERROR,
    data: { sendSmsError: sendSmsError },
  };
}

export {
  acSetCallerAliases, //
  acSetAcdAttendantGroups,
  acSetSupervisorGroups,
  acRefreshAcdAttendantGroups,
  acRefreshAgent,
  acSetContactList,
  acSetSendSmsError,
  acSetAvailableUserRoles,
  acSetCurrentUserRole,
};
