declare global {
  interface Window {
    _env_: any;
  }
}

export const endpoints = {
  backEndBase: window._env_.REACT_APP_BACKEND_BASE + '/api', //'https://teams.api.ymi.eu.ngrok.io/api',
  backEndNotificationBase: window._env_.REACT_APP_BACKEND_BASE + '/notifications',
};

export const msTeamsSetting = {
  clientId: window._env_.REACT_APP_CLIENT_ID,
};

export const appVersion = window._env_.REACT_APP_VERSION;

